import { Reducer } from 'redux';

type TOGGLE_OFFICE = { type: 'TOGGLE_OFFICE'; payload: string };

export type OfficesStateAction = TOGGLE_OFFICE;

export type OfficesState = {
  untoggled: string[];
};

const initialState: OfficesState = {
  untoggled: JSON.parse(window.localStorage.getItem('untoggled-offices') || '[]'),
};

const officesReducer: Reducer<OfficesState, OfficesStateAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_OFFICE':
      if (state.untoggled.includes(action.payload)) {
        return { ...state, untoggled: state.untoggled.filter((s) => s !== action.payload) };
      }
      return { ...state, untoggled: [...state.untoggled, action.payload] };
    default:
      return state;
  }
};

export { createOffice, updateOffice, deleteOffice } from './thunks';
export default officesReducer;
