import { Reducer } from 'redux';

type FETCH_AUTH_REQUEST = { type: 'FETCH_AUTH_REQUEST' };
type FETCH_AUTH_SUCCESS = { type: 'FETCH_AUTH_SUCCESS' };
type FETCH_AUTH_FAILED = { type: 'FETCH_AUTH_FAILED' };

export type AuthStateAction = FETCH_AUTH_REQUEST | FETCH_AUTH_SUCCESS | FETCH_AUTH_FAILED;

export type AuthState = {
  isLoggedIn: boolean;
  loading: boolean;
};

const initialState: AuthState = {
  isLoggedIn: false,
  loading: true,
};

const authReducer: Reducer<AuthState, AuthStateAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_AUTH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_AUTH_SUCCESS':
      return {
        loading: false,
        isLoggedIn: true,
      };
    case 'FETCH_AUTH_FAILED':
      return {
        loading: false,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

export { fetchLoginState } from './thunks';
export default authReducer;
