import axios from 'axios';
import { ThunkAction } from 'redux-thunk';
import { GuestSuggestion, PlanningPopupStateAction } from '.';
import { RootState } from '../../global';

type PlanningPopupThunk<R = void> = ThunkAction<
  R,
  RootState,
  unknown,
  PlanningPopupStateAction | PlanningPopupStateAction
>;

let isFetching: string[] = [];

export const fetchGuestSuggestions = (): PlanningPopupThunk<Promise<void>> => async (dispatch) => {
  const url = `/api/Gasten/suggesties`;

  if (isFetching.includes(url)) return;

  isFetching.push(url);

  try {
    const suggestions = await axios.get(url);
    const formattedData: GuestSuggestion[] = suggestions.data.map((item: any) => {
      return {
        uuid: item.uuid,
        name: item.naam,
        email: item.emailadres,
        numberOfInvites: item.aantalKeerUitgenodigd,
      };
    });
    dispatch<PlanningPopupStateAction>({
      type: 'SET_GUEST_SUGGESTIONS',
      payload: { guestSuggestions: formattedData },
    });

    isFetching = isFetching.filter((u) => u !== url);
  } catch (e: any) {
    // Do nothing... If we can't fetch suggestions, we simply don't show them.
    console.error('Niet gelukt om suggesties op te halen.');
  }

  return;
};
