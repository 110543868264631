import React from 'react';
import styled from 'styled-components';

type Rest = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type Props = Rest & {
  label: string;
};

const Styled = styled.label`
  cursor: pointer;

  input[type='radio'] {
    /* display: none; */
    visibility: none;
    opacity: 0;
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    position: absolute;
  }

  span {
    display: flex;
    align-items: center;
    position: relative;
  }

  span:before {
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 0.75rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${(props) => props.theme.colors.inputBorder};
  }

  span:after {
    content: '';
    opacity: 0;
    background-size: contain;
    background-position: center;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
    position: absolute;
    border: 2px solid transparent;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 2rem;
  }

  input:checked ~ span::before {
    border-color: ${(props) => props.theme.colors.primary};
  }

  input:checked ~ span::after {
    opacity: 1;
  }

  input:focus ~ span {
    outline: 2px dashed black;
  }

  input:disabled ~ span {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Radio: React.FC<Props> = ({ label, ...rest }) => {
  return (
    <Styled>
      <input {...rest} type="radio" />
      <span>{label}</span>
    </Styled>
  );
};

export { Radio };
