import { Reducer } from 'redux';
import { Appearance } from '../../components/AppearanceSelector/AppearanceSelector';

type SET_APPEARANCE_PREFERENCE = { type: 'SET_APPEARANCE_PREFERENCE'; payload: Appearance };
type SET_LAST_READ_UPDATE = { type: 'SET_LAST_READ_UPDATE'; payload: string };

export type PreferencesStateAction = SET_APPEARANCE_PREFERENCE | SET_LAST_READ_UPDATE;

export type PreferencesState = {
  appearance: Appearance;
  lastReadUpdate: string;
};

const initialState: PreferencesState = {
  appearance: (window.localStorage.getItem('app-appearance') || 'system') as Appearance,
  lastReadUpdate: window.localStorage.getItem('last-read-update') || '',
};

const preferencesReducer: Reducer<PreferencesState, PreferencesStateAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_APPEARANCE_PREFERENCE':
      window.localStorage.setItem('app-appearance', action.payload.toString());

      return { ...state, appearance: action.payload };
    case 'SET_LAST_READ_UPDATE':
      window.localStorage.setItem('last-read-update', action.payload);

      return { ...state, lastReadUpdate: action.payload };
    default:
      return state;
  }
};

export default preferencesReducer;
