import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorker from './serviceWorkerRegistration';
import moment from 'moment';
import axios from 'axios';
import 'moment/locale/nl';
import App from './containers/App';
import store from './state/store';
import { Provider } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyle } from './ui';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import UserProvider from './utils/userProvider';
import AuthProvider from './utils/authProvider';
import 'moment/locale/nl';
import { AppInsightsProvider } from './utils/appInsights';
import Intro from './containers/Intro';
import Welcome from './containers/Welcome';
import CustomThemeProvider from './components/CustomThemeProvider';
import PwaInstallProvider from './components/PwaInstallProvider/PwaInstallProvider';

const { apiUrl } = (window as any)['appConfig'];

axios.defaults.baseURL = apiUrl;
moment.locale('nl');

ReactDOM.render(
  // <React.StrictMode>
  <AppInsightsProvider>
    <Provider store={store}>
      <CustomThemeProvider>
        <PwaInstallProvider>
          <Router>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              transition={Slide}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <GlobalStyle />
            <AuthProvider>
              <Switch>
                <Route exact path="/login">
                  <Intro />
                </Route>
                <Route exact path="/welcome">
                  <Welcome />
                </Route>
                <Route path="*">
                  <UserProvider>
                    <App />
                  </UserProvider>
                </Route>
              </Switch>
            </AuthProvider>
          </Router>
        </PwaInstallProvider>
      </CustomThemeProvider>
    </Provider>
  </AppInsightsProvider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
