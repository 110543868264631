import { useEffect, useState } from 'react';
import IosNativeDrawer from '../../ui/IosNativeDrawer/IosNativeDrawer';
import IosNativeDrawerHeader from '../IosNativeComponents/IosNativeDrawerHeader';
import IosNativeDrawerContent from '../IosNativeComponents/IosNativeDrawerContent';
import IosNativeDrawerSeparator from '../IosNativeComponents/IosNativeDrawerSeparator';
import IosNativeDrawerStep from '../IosNativeComponents/IosNativeDrawerStep';
import { useTheme } from 'styled-components';

type Props = {
  children: React.ReactNode;
  allowShow: boolean;
  onUpdatePreference: (value: string) => void;
};

export default function IosInstallProvider({ children, allowShow, onUpdatePreference }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 500);
  }, []);

  const closeAndAskShowAgain = () => {
    onUpdatePreference('false');
    setIsVisible(false);
  };

  if (!allowShow) return <>{children}</>;

  return (
    <>
      <IosNativeDrawer isOpen={isVisible} onCancel={() => setIsVisible(false)}>
        <IosNativeDrawerHeader
          title="Zet op beginscherm"
          buttonIcon={
            <svg xmlns="http://www.w3.org/2000/svg" width="10.733" height="10.737" viewBox="0 0 10.733 10.737">
              <path
                id="Symbol"
                d="M2.279-.549a.951.951,0,0,0,.453.256,1.075,1.075,0,0,0,.522,0A.943.943,0,0,0,3.7-.539L7.358-4.205,11.02-.544a.936.936,0,0,0,.446.253,1.05,1.05,0,0,0,.515,0,.96.96,0,0,0,.451-.258A.923.923,0,0,0,12.692-1a1.111,1.111,0,0,0,0-.519.885.885,0,0,0-.255-.445L8.786-5.633l3.654-3.654a.9.9,0,0,0,.255-.447,1.111,1.111,0,0,0,0-.519.909.909,0,0,0-.26-.451.969.969,0,0,0-.451-.259,1.031,1.031,0,0,0-.515,0,.955.955,0,0,0-.446.251L7.358-7.055,3.7-10.717a.952.952,0,0,0-.445-.248,1.066,1.066,0,0,0-.522,0,.951.951,0,0,0-.453.256.931.931,0,0,0-.258.456,1.077,1.077,0,0,0,0,.522.952.952,0,0,0,.248.442L5.938-5.633,2.272-1.966a.936.936,0,0,0-.248.441,1.077,1.077,0,0,0,0,.522A.93.93,0,0,0,2.279-.549Z"
                transform="translate(-1.991 10.997)"
                fill={theme.iosStyles.iconColor}
              />
            </svg>
          }
          onButtonPress={closeAndAskShowAgain}
        />
        <IosNativeDrawerSeparator />
        <IosNativeDrawerContent>
          Deze website heeft app-functionaliteit. Voeg het toe aan je startscherm om het op volledig scherm en offline
          te gebruiken. Als je de Safari browser gebruikt, volg je de volgende stappen:
        </IosNativeDrawerContent>
        <IosNativeDrawerSeparator />
        <IosNativeDrawerStep
          text={'Druk op de "Deel" knop onderin de menubalk.'}
          icon={
            <svg
              id="Buttons_-_Symbol"
              data-name="Buttons - Symbol"
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="44"
              viewBox="0 0 44 44"
            >
              <rect id="Frame" width="44" height="44" fill="none" opacity="0" />
              <path
                id="Symbol"
                d="M-6.032,3.577H6.29a3.449,3.449,0,0,0,2.53-.838A3.369,3.369,0,0,0,9.663.247V-10.474a3.369,3.369,0,0,0-.843-2.492A3.449,3.449,0,0,0,6.29-13.8h-3v1.729H6.257a1.7,1.7,0,0,1,1.235.43,1.694,1.694,0,0,1,.44,1.268V.15a1.694,1.694,0,0,1-.44,1.268,1.7,1.7,0,0,1-1.235.43H-6.01a1.672,1.672,0,0,1-1.235-.43A1.719,1.719,0,0,1-7.675.15V-10.377a1.719,1.719,0,0,1,.43-1.268,1.672,1.672,0,0,1,1.235-.43h2.976V-13.8h-3a3.463,3.463,0,0,0-2.53.833,3.366,3.366,0,0,0-.843,2.5V.247a3.366,3.366,0,0,0,.843,2.5A3.463,3.463,0,0,0-6.032,3.577ZM.124-4.834A.837.837,0,0,0,.73-5.081a.8.8,0,0,0,.252-.591V-16.7L.918-18.315l.72.763,1.633,1.74a.736.736,0,0,0,.58.258.779.779,0,0,0,.559-.215.724.724,0,0,0,.226-.548.78.78,0,0,0-.258-.559l-3.631-3.5A1.059,1.059,0,0,0,.44-20.6a.771.771,0,0,0-.317-.064.745.745,0,0,0-.306.064,1.193,1.193,0,0,0-.317.226l-3.631,3.5a.8.8,0,0,0-.247.559.742.742,0,0,0,.215.548.756.756,0,0,0,.559.215.747.747,0,0,0,.591-.258l1.622-1.74.73-.763L-.725-16.7V-5.672a.8.8,0,0,0,.252.591A.824.824,0,0,0,.124-4.834Z"
                transform="translate(22 30)"
                fill="#0a84ff"
              />
            </svg>
          }
        />
        <IosNativeDrawerStep
          text={'Druk op "Zet op beginscherm"'}
          icon={
            <svg
              id="Buttons_-_Symbol"
              data-name="Buttons - Symbol"
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="44"
              viewBox="0 0 44 44"
            >
              <rect id="Frame" width="44" height="44" fill="none" opacity="0" />
              <path
                id="Symbol"
                d="M-6.386,2.148H6.644a3.463,3.463,0,0,0,2.53-.833,3.366,3.366,0,0,0,.843-2.5V-14.3a3.366,3.366,0,0,0-.843-2.5,3.463,3.463,0,0,0-2.53-.833H-6.386a3.463,3.463,0,0,0-2.53.833,3.366,3.366,0,0,0-.843,2.5V-1.182a3.366,3.366,0,0,0,.843,2.5A3.463,3.463,0,0,0-6.386,2.148ZM-6.365.419A1.685,1.685,0,0,1-7.6-.005a1.7,1.7,0,0,1-.43-1.262V-14.212a1.7,1.7,0,0,1,.43-1.262A1.685,1.685,0,0,1-6.365-15.9H6.623a1.688,1.688,0,0,1,1.23.424,1.687,1.687,0,0,1,.435,1.262V-1.268A1.687,1.687,0,0,1,7.853-.005a1.688,1.688,0,0,1-1.23.424Zm1.525-8.175a.839.839,0,0,0,.258.634.923.923,0,0,0,.666.247H-.757v3.169a.9.9,0,0,0,.247.661.848.848,0,0,0,.634.252.893.893,0,0,0,.661-.252.893.893,0,0,0,.252-.661V-6.875H4.206a.9.9,0,0,0,.661-.247.848.848,0,0,0,.252-.634.893.893,0,0,0-.252-.661.893.893,0,0,0-.661-.252H1.037v-3.158A.907.907,0,0,0,.784-12.5a.893.893,0,0,0-.661-.252.839.839,0,0,0-.634.258.923.923,0,0,0-.247.666v3.158H-3.916a.907.907,0,0,0-.671.252A.893.893,0,0,0-4.839-7.756Z"
                transform="translate(22 30)"
                fill={theme.iosStyles.contentColor}
              />
            </svg>
          }
        />
      </IosNativeDrawer>
      {children}
    </>
  );
}
