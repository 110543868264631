import React, { Children } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import PopperJS from '@popperjs/core';

const Inner = styled.div<{ isOpen: boolean; placement: PopperJS.Placement }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.label};
  color: ${(props) => props.theme.colors.onLabel};
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.3px 0.3px -4px rgba(0, 0, 0, 0.081), 0 1.1px 2px -4px rgba(0, 0, 0, 0.119),
    0 5px 10px -4px rgba(0, 0, 0, 0.2);

  transform: scale(0.8);
  transform-origin: center;
  opacity: 0;
  transition: 0.2s cubic-bezier(0.45, 0, 0.21, 1);

  ${(props) =>
    props.isOpen &&
    `
    transform: scale(1);
    opacity: 1;
  `}
`;

type Props = {
  content: React.ReactNode;
  children: React.ReactElement<any>;
  placement?: PopperJS.Placement;
};

export const Tooltip: React.FC<Props> = ({ children, content, placement = 'bottom' }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const referenceElement = React.useRef<HTMLDivElement | null>(null);
  const popperElement = React.useRef<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement.current, popperElement.current, {
    placement: placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [4, 4],
        },
      },
    ],
  });

  const renderTarget = () => {
    const target = Children.only(children);

    return React.cloneElement(target, {
      ref: referenceElement,
      onMouseEnter: () => window.innerWidth >= 1024 && setIsOpen(true),
    });
  };

  return (
    <div style={{ position: 'relative' }} onMouseLeave={() => setIsOpen(false)}>
      {renderTarget()}

      <div ref={popperElement} style={{ ...styles.popper, zIndex: 99, pointerEvents: 'none' }} {...attributes.popper}>
        <Inner placement={placement} isOpen={isOpen}>
          {content}
        </Inner>
      </div>
    </div>
  );
};
