import styled from 'styled-components';

const Reel = styled.div`
  display: flex;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  touch-action: pan-x;

  & > * {
    flex: 0 0 auto;
  }

  & > img {
    height: 100%;
    flex-basis: auto;
    width: auto;
  }

  & > * + * {
    margin-left: 0.5rem;
  }
`;

export { Reel };
