import axios from 'axios';
import { ThunkAction } from 'redux-thunk';
import { AuthStateAction } from '.';
import { RootState } from '../../global';

type AuthThunk<R = void> = ThunkAction<R, RootState, unknown, AuthStateAction>;

export const fetchLoginState = (): AuthThunk => async (dispatch) => {
  dispatch({ type: 'FETCH_AUTH_REQUEST' });

  try {
    const state = await axios.get('/login-state');
    if (state.status === 200) {
      dispatch({ type: 'FETCH_AUTH_SUCCESS' });
    }
  } catch (e: any) {
    dispatch({ type: 'FETCH_AUTH_FAILED' });
  }
};
