import { useSelector } from 'react-redux';
import { RootState } from '../../global';
import TimeLineScrollBar from './TimeLineScrollBar';
import useWindowSize from '../../utils/useWindowSize';

type Props = {
  children: React.ReactNode;
  showTimeline?: boolean;
};

export default function CustomScrollBar({ children, showTimeline = false }: Props) {
  const { isMobileDevice } = useWindowSize();

  const {
    layout: { scrollbarData },
  } = useSelector((state: RootState) => state);

  if (scrollbarData && scrollbarData.length > 0 && !isMobileDevice) {
    return <TimeLineScrollBar dates={scrollbarData}>{children}</TimeLineScrollBar>;
  }

  return <>{children}</>;
}
