import { ChevronBack, ChevronForward } from '@styled-icons/ionicons-outline';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Heading, IconButton } from '../../ui';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  h1 {
    width: fit-content;
    font-weight: 500;
    text-transform: capitalize;
  }
  div {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    display: flex;
  }
`;

type Props = {
  nextYear: () => void;
  prevYear: () => void;
  currentYear: number|string;
  disablePrev: boolean
  disableNext: boolean
};

const YearNavigator = ({ nextYear, prevYear, currentYear, disablePrev, disableNext } : Props) => {
  let date: moment.Moment | string
  if (typeof currentYear == "string"){
    date = currentYear
  }
  else  date = moment(`${currentYear}`, 'YYYY');
  return (
    <Wrapper>
      <Heading as="h1">{typeof date == "string" ? currentYear : date.format(`YYYY`)}</Heading>
      <IconButton.Minimal hidden={disablePrev} onClick={() => prevYear()}>
        <ChevronBack />
        <span className="visually-hidden">Ga een jaar terug</span>
      </IconButton.Minimal>
      {disableNext && <div></div>}
      {disablePrev && <div></div>}
      <IconButton.Minimal hidden={disableNext} onClick={() => nextYear()}>
        <ChevronForward />
        <span className="visually-hidden">Ga een jaar vooruit</span>
      </IconButton.Minimal>
    </Wrapper>
  );
};

export default YearNavigator;
