import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { fetchLocations, LocationsStateAction } from '../locations';
import { RootState } from '../../global';
import { OfficesStateAction } from '.';
import { toast } from 'react-toastify';

type Thunk<R = void> = ThunkAction<R, RootState, unknown, OfficesStateAction | LocationsStateAction>;

type CreateOfficeProps = { name: string; seats: number; locationUuid: string };
type UpdateOfficeProps = { uuid: string; name: string; seats: number; locationUuid: string; isActive: boolean };
type DeleteOfficeProps = { uuid: string };

export const createOffice =
  ({ locationUuid, name, seats }: CreateOfficeProps): Thunk =>
  async (dispatch, getState) => {
    const response = await axios
      .post(`/api/Locaties/${locationUuid}/ruimtes`, {
        naam: name,
        plekken: seats,
        isActief: true,
      })
      .catch((e) => {
        toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het maken van de ruimte.');
      });

    if (response) {
      toast.success('Ruimte aangemaakt.');
      dispatch(fetchLocations());
    }
  };

export const updateOffice =
  (office: UpdateOfficeProps): Thunk =>
  async (dispatch, getState) => {
    const response = await axios
      .patch(`/api/Ruimtes/${office.uuid}`, {
        naam: office.name,
        plekken: office.seats,
        locatieUuid: office.locationUuid,
        isActief: office.isActive,
      })
      .catch((e) => {
        toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het updaten van de Ruimte.');
      });

    if (response) {
      toast.success('Ruimte geupdated.');
      dispatch(fetchLocations());
    }
  };

export const deleteOffice =
  (office: DeleteOfficeProps): Thunk =>
  async (dispatch, getState) => {
    const response = await axios.delete(`/api/Ruimtes/${office.uuid}`).catch((e) => {
      toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het verwijderen van de ruimte.');
    });

    if (response) {
      toast.success('Ruimte verwijderd.');
      dispatch(fetchLocations());
    }
  };
