import { Title } from '@styled-icons/material-outlined';
import { clamp } from 'lodash';
import { title } from 'process';
import { useMemo, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import styled from 'styled-components';
import { projection, findNearestNumberInArray, rubberBandIfOutOfBounds } from '../../utils/animation';

type NativeDrawerProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onCancel?: () => void;
};

const portalRoot = document.getElementById('portal');

const spring1 = {
  tension: 350,
  friction: 34,
};

const spring2 = {
  tension: 175,
  friction: 25,
};

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background-color: ${(props) => props.theme.iosStyles.backdropBackground};
  opacity: 0;
  transition: opacity 0.2s ease-in;
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  overflow-y: hidden;
  pointer-events: none;
`;

const Inner = styled.div`
  width: 100%;

  border-radius: 10px 10px 0 0;
  background-color: ${(props) => props.theme.iosStyles.cardBackground};
  backdrop-filter: blur(50px);

  position: fixed;
  bottom: 0;

  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);

    width: 36px;
    height: 5px;
    border-radius: 3px;

    background-color: ${(props) => props.theme.iosStyles.handleBarColor};
  }

  * {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  }
`;

export default function IosNativeDrawer({ children, isOpen, onCancel }: NativeDrawerProps) {
  const stops = useMemo(() => [0, window.innerHeight], []);
  const drawerRef = useRef<HTMLDivElement>(null);
  const [{ y }, setY] = useSpring<{ y: number }>(() => ({
    y: stops[1],
  }));

  const [{ scrollY }, setScrollY] = useSpring<{ scrollY: number }>(() => ({
    scrollY: 0,
    onFrame: (props: any) => {
      if (drawerRef.current) drawerRef.current.scrollTop = props.scrollY;
    },
  }));

  useEffect(() => {
    setY({
      y: isOpen ? stops[0] : stops[1],
      immediate: false,
      config: spring2,
    });
  }, [isOpen, setY, stops]);

  const threshold = 10;

  const bind = useDrag(
    ({ vxvy: [, velocityY], delta: [, deltaY], movement: [movementX, movementY], last, memo, event }) => {
      if (!drawerRef.current) return;

      if (!memo) {
        const isIntentionalGesture = Math.abs(movementY) > threshold && Math.abs(movementY) > Math.abs(movementX);

        if (!isIntentionalGesture) return;
        memo = y.getValue() - movementY;
      }

      const isScrollable = drawerRef.current.scrollHeight > drawerRef.current.clientHeight;
      const maxScrollTop = drawerRef.current.scrollHeight - drawerRef.current.clientHeight;

      if (last) {
        const projectedEndpoint = y.getValue() + projection(velocityY);
        const projectedScrollEndpoint = scrollY.getValue() + projection(velocityY) * -1;

        if (projectedScrollEndpoint < -2000 || scrollY.getValue() === 0) {
          const point = findNearestNumberInArray(projectedEndpoint, stops);

          setScrollY({
            scrollY: 0,
            immediate: false,
            config: spring1,
          });

          if (point > 0 && onCancel) {
            onCancel();
          } else {
            setY({
              y: point,
              immediate: false,
              config: spring1,
            });
          }
        } else {
          setScrollY({
            scrollY: clamp(0, maxScrollTop, projectedScrollEndpoint),
            immediate: false,
            config: spring2,
          });
        }

        return;
      }

      if (isScrollable && (deltaY < 0 || drawerRef.current.scrollTop > 0)) {
        const scrollTop = clamp(0, maxScrollTop, deltaY * -1 + scrollY.getValue());
        setScrollY({
          scrollY: scrollTop,
          immediate: true,
        });
        return;
      }

      const newY = rubberBandIfOutOfBounds(stops[0], stops[1], movementY + memo, 0.08);

      setY({
        y: newY,
        immediate: true,
      });

      return memo;
    },
    {},
  );

  if (!portalRoot) return null;

  return ReactDOM.createPortal(
    <Wrapper style={{ pointerEvents: isOpen ? 'initial' : 'none' }} tabIndex={isOpen ? 0 : -1}>
      <Backdrop onClick={onCancel} style={{ opacity: isOpen ? 1 : 0 }} />
      <Inner
        {...bind()}
        as={animated.div}
        ref={drawerRef}
        style={{
          transform: y.interpolate((y) => `translate3D(0, ${y}px, 0)`),
        }}
      >
        {children}
      </Inner>
    </Wrapper>,
    portalRoot,
  );
}
