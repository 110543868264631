import styled from 'styled-components';
import { IconButton } from '../Button/Button';

const Fab = styled(IconButton.Primary)`
  width: 3rem;
  height: 3rem;
  position: fixed;
  z-index: 99;
  right: 2rem;
  bottom: 2rem;
  box-shadow: 0 1.3px 2.2px rgba(0, 0, 0, 0.04), 0 4.5px 7.4px rgba(0, 0, 0, 0.06), 0 20px 33px rgba(0, 0, 0, 0.1);
`;

export { Fab };
