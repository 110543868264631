import React from 'react';
import { DefaultTheme, useTheme } from 'styled-components';

type Props = {
  radius: number;
  stroke: number;
  progress: number;
};

function getStokeColor(theme: DefaultTheme, progress: number) {
  if (progress === 100) {
    return theme.colors.error;
  }
  if (progress > 80) {
    return theme.colors.warning;
  }

  return theme.colors.success;
}

export const ProgressRing: React.FC<Props> = ({ radius, stroke, progress }) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  const theme = useTheme();

  return (
    <svg height={radius * 2} width={radius * 2} style={{ transform: 'rotate(-90deg)' }}>
      <circle
        stroke={theme.colors.washed}
        fill="transparent"
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        data-testid="progress-ring"
        stroke={getStokeColor(theme, progress)}
        strokeLinecap="round"
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};
