import styled, { css } from 'styled-components';
import { Label, Reel as _Reel } from '../ui';
import { Check } from '@styled-icons/material-outlined';

export const Reel = styled(_Reel)`
  align-items: center;
  margin: 0 -2rem;
  width: calc(100% + 4rem);
  padding-left: 2rem;
  padding-bottom: 1rem;

  &:after {
    content: '';
    flex: 0 0 2rem;
  }
`;

const Pill = styled(Label)<{ selected: boolean }>`
  padding: 0.5rem 1rem;
  border: 2px solid ${(props) => props.theme.colors.border};

  color: ${(props) => props.theme.colors.label};
  font-weight: 500;
  border-radius: 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 2.25rem;

  svg {
    display: none;
    margin-right: 0.5rem;
    margin-left: -0.5rem;
    width: 1.25rem;
    color: inherit;
  }

  ${(props) =>
    props.selected &&
    css`
      background: ${(props) => props.theme.colors.label};
      color: ${(props) => props.theme.colors.onLabel};
      border-color: ${(props) => props.theme.colors.label};
      svg {
        display: block;
      }
    `}
`;

const Badge = styled.span`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

type Props = {
  chips: {
    id: string;
    label: string;
    showBadge?: boolean;
  }[];
  selected: string[];
  invertSelected?: boolean;
  showIcon?: boolean;
  onSelect: (id: string) => void;
  renderReel?: boolean;
};

const ChipsSelector = ({
  showIcon = false,
  chips,
  selected,
  onSelect,
  invertSelected = false,
  renderReel = true,
}: Props) => {
  const chipsMap = chips.map((chip) => (
    <Pill
      key={chip.id}
      onClick={() => onSelect(chip.id)}
      selected={invertSelected ? !selected.includes(chip.id) : selected.includes(chip.id)}
    >
      {showIcon && <Check />}
      {chip.showBadge ? <Badge>{chip.label}</Badge> : chip.label}
    </Pill>
  ));
  if (!renderReel) {
    return <>{chipsMap}</>;
  }

  return <Reel>{chipsMap}</Reel>;
};

export default ChipsSelector;
