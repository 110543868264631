/**
 * These are not "real" thunks because the tenants are not set in the global state,
 * these function do not dispatch other actions.
 */

import axios from 'axios';
import { Tenant, PricingPlan, InvoicePeriod } from '../../global';

export const getTenant = () => {
  return axios.get<Tenant>('/api/Tenants/me').catch((e) => Promise.reject(e.response));
};

export type CreateTenantProps = {
  name: string;
  pricingPlan: PricingPlan;
  details: {
    address: {
      street: string;
      postalCode: string;
      city: string;
    };
    chamberOfCommerceNumber: string;
    vatNumber?: string;
    invoicingEmail: string;
    contactPerson?: string;
    purchaseOrderNumber?: string;
    invoicePeriod: InvoicePeriod;
  };
};

export const createTenant = (props: CreateTenantProps) => {
  return axios({ method: 'POST', url: '/api/Tenants', data: props }).catch((e) => Promise.reject(e.response));
};
