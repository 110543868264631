import React from 'react';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import PopperJS from '@popperjs/core';
import useClickAway from '../../utils/useClickAway';

const Popper = styled.div`
  z-index: 9;
`;

function getTransformOrigin(placement: PopperJS.Placement) {
  if (['bottom-end', 'bottom-start', 'bottom'].includes(placement)) {
    return '80% 0';
  }

  if (['top-end', 'top-start', 'top'].includes(placement)) {
    return '80% 100%';
  }
}

const Inner = styled.div<{ isOpen: boolean; placement: PopperJS.Placement }>`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  min-width: 10rem;
  border-radius: 0.75rem;
  box-shadow: 0 0.3px 0.3px -4px rgba(0, 0, 0, 0.081), 0 1.1px 2px -4px rgba(0, 0, 0, 0.119),
    0 5px 10px -4px rgba(0, 0, 0, 0.2);

  transform: scale(0.6);
  transform-origin: ${(props) => getTransformOrigin(props.placement)};
  opacity: 0;
  transition: 0.2s cubic-bezier(0.45, 0, 0.21, 1);
  border-bottom: 4px solid transparent;

  ${(props) =>
    props.isOpen &&
    `
    transform: scale(1);
    opacity: 1;
  `}
`;

type Props = {
  content: () => React.ReactNode;
  placement?: PopperJS.Placement;
  strategy?: PopperJS.PositioningStrategy;
  openOnHover?: boolean;
  closeOnMouseLeave?: boolean;
  offset?: [number, number];
};

export const Popover: React.FC<Props> = ({
  children,
  content,
  openOnHover = false,
  closeOnMouseLeave = false,
  placement = 'bottom-end',
  strategy = 'absolute',
  offset = [0, 0],
}) => {
  const referenceElement = React.useRef<HTMLElement>(null);
  const popperElement = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const { styles, attributes } = usePopper(referenceElement.current, popperElement.current, {
    strategy,
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
  });

  useClickAway(popperElement, (event) => {
    if (!event || !referenceElement.current || referenceElement.current.contains((event as any).target)) {
      return;
    }
    setIsOpen(false);
  });

  const getTargetProps = () => {
    if (openOnHover) {
      return {
        onMouseEnter: () => setIsOpen(true),
      };
    }

    return {
      onClick: () => setIsOpen((open) => !open),
    };
  };

  const renderTarget = () => {
    if (!children) return null;

    return React.cloneElement(children as any, {
      ref: referenceElement,
      ...getTargetProps(),
    });
  };

  return (
    <div onMouseLeave={() => closeOnMouseLeave && setIsOpen(false)}>
      {renderTarget()}
      <Popper
        ref={popperElement}
        style={{ ...styles.popper, pointerEvents: isOpen ? 'initial' : 'none' }}
        {...attributes.popper}
      >
        <Inner placement={placement} isOpen={isOpen}>
          {content()}
        </Inner>
      </Popper>
    </div>
  );
};
