import React from 'react';
import styled from 'styled-components';
import initials from 'initials';
import getColorFromString from '../../utils/getColorFromString';

const Container = styled.div<{ size: number | string; highlighted: boolean; additional: boolean }>`
  img {
    width: 100%;
    height: 100%;
    margin: 2px;
    border-radius: 1rem;
  }

  div {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${(props) => (props.highlighted ? `2px solid ${props.theme.colors.primary}` : '0')};
    color: ${(props) => (props.additional ? props.theme.colors.primary : /*props.theme.colors.onLabel*/ 'white')};
    filter: ${(props) => (props.additional ? 'none' : props.theme.modifiers.colorModifier)};
  }
`;

type Props = {
  name: string;
  size?: number | string;
  highlighted?: boolean;
  image?: string;
  additional?: boolean;
};

const Avatar: React.FC<Props> = ({ name, size = '3rem', highlighted = false, image, additional = false, ...rest }) => {
  return (
    <Container data-testid={'avatar'} size={size} highlighted={highlighted} additional={additional} {...rest}>
      {additional ? (
        <div>
          <span>{name}</span>
        </div>
      ) : (
        <div style={{ backgroundColor: `${getColorFromString(name)}` }}>
          {image ? <img src={image} alt={name} /> : initials(name.replace(' (Gast)', ''))}
        </div>
      )}
    </Container>
  );
};

export { Avatar };
