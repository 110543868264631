import { Sparkles } from '@styled-icons/ionicons-outline/Sparkles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../global';
import { GuestSuggestion } from '../../state/planningPopup';
import { fetchGuestSuggestions } from '../../state/planningPopup/thunks';
import { SimpleLink } from '../../ui/SimpleLink';

const GuestSuggestionContainer = styled.div`
  display: flex;
  flex-direction: row;

  padding: 1.5rem;
  margin-block: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  border-radius: 0.75rem;

  .icon {
    width: 3rem;
  }

  .list {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }

  svg {
    width: 1.5em;
    height: 1.5em;
    shape-rendering: geometricPrecision;
    color: ${(props) => props.theme.colors.primary};
  }

  span {
    margin-right: 0.5em;
    line-height: 1.5;
  }
`;

const GuestSuggestions: React.FC = () => {
  const [links, setLinks] = useState<JSX.Element[]>([]);
  const dispatch = useDispatch();

  const { planningPopup } = useSelector((state: RootState) => state);

  useEffect(() => {
    const handleQuickAdd = (item: GuestSuggestion) => {
      const emptyIndex = planningPopup.guests.findIndex((item) => item.email === '' && item.name === '');

      if (emptyIndex === null || emptyIndex < 0) {
        dispatch({
          type: 'SET_GUESTS',
          payload: { guests: [...planningPopup.guests, { name: item.name, email: item.email }] },
        });
      } else {
        const copy = planningPopup.guests;
        copy[emptyIndex] = { name: item.name, email: item.email };

        dispatch({
          type: 'SET_GUESTS',
          payload: { guests: copy },
        });
      }
    };

    const getLinks = () => {
      let suggestions = planningPopup.guestSuggestions;
      const currentGuests = planningPopup.guests;

      suggestions = suggestions.sort((a, b) => b.numberOfInvites - a.numberOfInvites);

      let filteredSuggestions = suggestions
        .filter((suggestedGuest) => {
          return !currentGuests.some((enteredGuest) => enteredGuest.email === suggestedGuest.email);
        })
        .slice(0, 6);

      const links = filteredSuggestions.map((item, index) => {
        return (
          <span key={index}>
            <SimpleLink tabIndex={0} onClick={() => handleQuickAdd(item)}>
              {item.name}
            </SimpleLink>
            {index < filteredSuggestions.length - 1 ? ', ' : ''}
          </span>
        );
      });

      setLinks(links);
    };

    getLinks();
  }, [dispatch, planningPopup]);

  useEffect(() => {
    dispatch(fetchGuestSuggestions());
  }, [dispatch]);

  if (links.length === 0) {
    return null;
  }

  return (
    <GuestSuggestionContainer>
      <div className="icon">
        <Sparkles />
      </div>
      <div className="list">
        <span>Snel gasten toevoegen...</span> {links}
      </div>
    </GuestSuggestionContainer>
  );
};

export { GuestSuggestions };
