import React from 'react';
import styled from 'styled-components';

type Rest = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type Props = Rest & {
  label: string;
};

const Styled = styled.label`
  cursor: pointer;

  input[type='checkbox'] {
    display: none;
  }

  span {
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 400;
    color: ${(props) => props.theme.colors.label};
    font-size: 1rem;
    line-height: 1.4;
    user-select: none;
  }

  span:before {
    content: '';
    width: 36px;
    height: 24px;
    margin-right: 0.75rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.colors.faded};
  }

  span:after {
    content: '';
    background-size: contain;
    background-position: center;
    width: 16px;
    height: 16px;
    left: 4px;
    top: 4px;
    position: absolute;
    border: 2px solid transparent;
    background-color: ${(props) => props.theme.colors.cardBackground};
    border-radius: 2rem;
    transition: 0.15s ease;
  }

  input:checked ~ span::before {
    background: ${(props) => props.theme.colors.primary};
  }

  input:checked ~ span::after {
    transform: translate3d(12px, 0, 0);
  }
`;

const Switch: React.FC<Props> = ({ label, ...rest }) => {
  return (
    <Styled>
      <input {...rest} type="checkbox" />
      <span>{label}</span>
    </Styled>
  );
};

export { Switch };
