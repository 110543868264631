import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PlanningUser, RootState } from '../global';
import { Avatar, Label } from '../ui';
import useWindowSize from '../utils/useWindowSize';

type Props = {
  morning: PlanningUser[];
  midday: PlanningUser[];
  fullday: PlanningUser[];
  active: boolean;
};

const Attendees = styled.div<{ active: boolean; isMobileDevice: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  overflow-y: auto;
  max-height: 65vh;
  height: ${(props) => (props.active ? '100%' : '0')};

  .container {
    display: flex;
    flex-direction: column;

    & + .container {
      margin-top: 1.5rem;
    }

    & > ${Label} {
      margin-bottom: 0.5rem;
    }
  }

  ul {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: ${(props) => (props.isMobileDevice ? '1fr' : '1fr 1fr')};
  }

  li {
    display: flex;
    align-items: center;

    ${Label} {
      margin-left: 0.5rem;
    }

    &.highlighted {
      ${Label} {
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

const AttendeesList: React.FC<Props> = ({ active, morning, midday, fullday }) => {
  const currentUser = useSelector((state: RootState) => state.user.user);
  const { isMobileDevice } = useWindowSize();

  const renderList = (users: PlanningUser[], title: string) => {
    if (users.length === 0) return null;

    return (
      <div className="container">
        <Label type="faded" size="small">
          {title}
        </Label>
        <ul>
          {users.map((user, index) => (
            <li key={index} className={user.uuid === currentUser?.uuid ? 'highlighted' : ''}>
              <Avatar name={user.name} highlighted={user.uuid === currentUser?.uuid} />
              <Label type="fat">{user.name}</Label>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <Attendees active={active} isMobileDevice={isMobileDevice}>
      {renderList(morning, 'Ochtend')}
      {renderList(midday, 'Middag')}
      {renderList(fullday, 'Hele dag')}
    </Attendees>
  );
};

export default AttendeesList;
