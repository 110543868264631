import { useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { Heading } from '../Heading/Heading';
// import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { rubberBandIfOutOfBounds, findNearestNumberInArray, projection, clamp } from '../../utils/animation';
import ReactDOM from 'react-dom';
import React from 'react';

export type Props = {
  isOpen: boolean;
  onCancel?: () => void;
  className?: string;
  title?: string;
  renderHeader?: () => React.ReactNode;
};

const HandleBar = styled.div`
  background-color: rgba(128, 128, 128, 0.3);
  border-radius: 2px;
  height: 4px;
  width: 36px;
  position: sticky;
  top: 0.75rem;
  margin: 0 auto;
  z-index: 3;
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  overflow-y: hidden;
  pointer-events: none;
`;

const Underlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const Inner = styled.div`
  height: fit-content;
  max-height: 90%;
  bottom: 0;
  position: absolute;
  width: 100%;
  background: ${(props) => props.theme.colors.cardBackground};
  box-shadow: 0 100px 0 white, 0 3.4px 2.7px rgba(0, 0, 0, 0.012), 0 8.7px 6.9px rgba(0, 0, 0, 0.018),
    0 17.7px 14.2px rgba(0, 0, 0, 0.022), 0 36.5px 29.2px rgba(0, 0, 0, 0.028), 0 100px 80px rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  transform: scale(1) translateY(100%);
  touch-action: none;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    top: calc(100% - 1px);
    left: 0;
    background: ${(props) => props.theme.colors.cardBackground};
    pointer-events: none;
    border-radius: 0;
  }
`;

const ChildrenWrapper = styled.div`
  padding: 1rem 1rem 2rem;
`;

const DrawerHeader = styled.div`
  position: sticky;
  top: 0;
  margin-top: -4px; /* Make up for the height of the handlebar */
  background: ${(props) => props.theme.colors.cardBackground};
  padding: 1.5rem 1rem 0.5rem;
  z-index: 2;
`;

const Title = styled(Heading).attrs(() => ({ to: 'h3' }))`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.3em;
`;

const portalRoot = document.getElementById('portal');

const spring1 = {
  tension: 350,
  friction: 34,
};

const spring2 = {
  tension: 175,
  friction: 25,
};

const Drawer: React.FC<Props> = ({ children, className, isOpen, onCancel, title, renderHeader }) => {
  const stops = useMemo(() => [0, window.innerHeight], []);
  const drawerRef = useRef<HTMLDivElement>(null);
  const [{ y }, setY] = useSpring<{ y: number }>(() => ({
    y: stops[1],
  }));

  const [{ scrollY }, setScrollY] = useSpring<{ scrollY: number }>(() => ({
    scrollY: 0,
    onFrame: (props: any) => {
      if (drawerRef.current) drawerRef.current.scrollTop = props.scrollY;
    },
  }));

  useEffect(() => {
    setY({
      y: isOpen ? stops[0] : stops[1],
      immediate: false,
      config: spring2,
    });
  }, [isOpen, setY, stops]);

  const threshold = 10;

  const bind = useDrag(
    ({ vxvy: [, velocityY], delta: [, deltaY], movement: [movementX, movementY], last, memo, event }) => {
      if (!drawerRef.current) return;

      if (!memo) {
        const isIntentionalGesture = Math.abs(movementY) > threshold && Math.abs(movementY) > Math.abs(movementX);

        if (!isIntentionalGesture) return;
        memo = y.getValue() - movementY;
      }

      const isScrollable = drawerRef.current.scrollHeight > drawerRef.current.clientHeight;
      const maxScrollTop = drawerRef.current.scrollHeight - drawerRef.current.clientHeight;

      if (last) {
        const projectedEndpoint = y.getValue() + projection(velocityY);
        const projectedScrollEndpoint = scrollY.getValue() + projection(velocityY) * -1;

        if (projectedScrollEndpoint < -2000 || scrollY.getValue() === 0) {
          const point = findNearestNumberInArray(projectedEndpoint, stops);

          setScrollY({
            scrollY: 0,
            immediate: false,
            config: spring1,
          });

          if (point > 0 && onCancel) {
            onCancel();
          } else {
            setY({
              y: point,
              immediate: false,
              config: spring1,
            });
          }
        } else {
          setScrollY({
            scrollY: clamp(0, maxScrollTop, projectedScrollEndpoint),
            immediate: false,
            config: spring2,
          });
        }

        return;
      }

      if (isScrollable && (deltaY < 0 || drawerRef.current.scrollTop > 0)) {
        const scrollTop = clamp(0, maxScrollTop, deltaY * -1 + scrollY.getValue());
        setScrollY({
          scrollY: scrollTop,
          immediate: true,
        });
        return;
      }

      const newY = rubberBandIfOutOfBounds(stops[0], stops[1], movementY + memo, 0.08);

      setY({
        y: newY,
        immediate: true,
      });

      return memo;
    },
    {},
  );

  if (!portalRoot) return null;

  return ReactDOM.createPortal(
    <Wrapper style={{ pointerEvents: isOpen ? 'initial' : 'none' }} tabIndex={isOpen ? 0 : -1}>
      <Underlay
        onClick={() => {
          if (onCancel) onCancel();
        }}
        as={animated.div}
        style={{
          opacity: y.interpolate({ range: stops, output: [1, 0] }),
        }}
      />
      <Inner
        {...bind()}
        as={animated.div}
        ref={drawerRef}
        style={{
          transform: y.interpolate((y) => `translate3D(0, ${y}px, 0)`),
        }}
        className={className}
      >
        <HandleBar />
        {(title || renderHeader) && (
          <DrawerHeader>
            {title && <Title>{title}</Title>}
            {renderHeader && renderHeader()}
          </DrawerHeader>
        )}
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </Inner>
    </Wrapper>,
    portalRoot,
  );
};

export { Drawer };
