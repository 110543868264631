import React from 'react';
import styled from 'styled-components';
import { Button, Heading } from '../ui';

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.background};
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 100%;
  width: 32em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  margin-bottom: 4rem;

  h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  p {
    font-size: 0.875rem;
    color: ${(props) => props.theme.colors.muted};
    line-height: 1.5;
  }

  button {
    min-width: 12rem;
    margin-top: 2rem;
  }
`;

const Error: React.FC<{
  title: string;
  message: string | React.ReactNode;
  action?: () => void;
  actionLabel?: string;
}> = ({ title, message, action, actionLabel }) => {
  return (
    <Wrapper>
      <Container>
        <Heading as="h1">{title}</Heading>
        {typeof message === 'string' ? <p>{message}</p> : { message }}
        {actionLabel && action && <Button.Primary onClick={() => action()}>{actionLabel}</Button.Primary>}
      </Container>
    </Wrapper>
  );
};

export default Error;
