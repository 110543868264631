import { Moment } from 'moment';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Container = styled.div`
  display: grid;
  height: 100%;
  grid-template: auto / 1fr 44px;
  overflow: hidden;
  position: relative;
`;

const Content = styled.div`
  height: 100%;
  -ms-overflow-style: none;
  overflow: auto;
  padding: 0 1rem;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollBar = styled.div`
  padding: 1rem;
`;

const ScrollTrack = styled.div<{ thumbOnly: boolean }>`
  cursor: pointer;
  position: absolute;
  top: 10px;
  bottom: 10px;
  width: 10px;

  ${(props) => props.thumbOnly && `pointer-events: none;`}
`;

const ScrollThumb = styled.div`
  transform: translate(-50%, -50%);
  cursor: grab;
  pointer-events: all;

  border-radius: 12px;
  border: 3px solid ${(props) => props.theme.colors.primary};
  position: absolute;
  left: 50%;
  width: 14px;
  height: 14px;

  z-index: 10;
`;

const MonthTab = styled.div`
  pointer-events: all;
  cursor: pointer;
  position: absolute;
  right: calc(50% - 2px);
  width: 16px;
  height: 4px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.timeLineColor};
  z-index: 1;
  &:before {
    content: attr(title);
    color: ${(props) => props.theme.colors.timeLineColor};
    position: absolute;
    right: calc(100% + 4px);
    top: 50%;
    font-size: 0.8rem;
    transform: translateY(-50%);
    width: 200%;
    text-align: right;
  }
`;

const WeekTab = styled.div`
  pointer-events: all;
  cursor: pointer;
  position: absolute;
  opacity: 0.3;
  right: 50%;
  transform: translate(2px, -50%);
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.timeLineColor};
  z-index: 2;
  &:hover {
    width: 16px;
    opacity: 1;
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: attr(title);
    font-size: 0.8rem;
    opacity: 0;
    position: absolute;
    right: calc(100% + 4px);
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    text-align: right;
    color: ${(props) => props.theme.colors.timeLineColor};
    background-color: ${(props) => props.theme.colors.background};
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(6px, -50%);
    width: 30px;
    height: 1.9vh;
  }
`;

type Props = {
  dates?: Moment[];
  children: React.ReactNode;
  className?: string;
  startDate?: Moment;
};

const TimeLineScrollBar = ({ children, className, dates, startDate = moment(), ...props }: Props) => {
  // Logic
  const [scrollOffsetPercentage, setScrollOffsetPercentage] = useState<number>(0);
  const [dragging, setDragging] = useState<boolean>(false);

  // Refs
  const scrollTrackRef = useRef<HTMLDivElement>(null);
  const scrollThumbRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  // Setters
  const setCurrentScrollOffsetPercentage = () => {
    const { scrollTop, scrollHeight, clientHeight } = contentRef.current!;
    const scrollOffset = scrollHeight - clientHeight + window.innerHeight;

    const scrollOffsetPercentage = (scrollTop / scrollOffset) * 100;
    setScrollOffsetPercentage(scrollOffsetPercentage);
  };

  const getWeekNumber = (date: Date) => {
    const oneJan = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(((date.getTime() - oneJan.getTime()) / 86400000 + oneJan.getDay() + 1) / 7);
    return weekNumber;
  };

  // Handlers
  useEffect(setCurrentScrollOffsetPercentage, [contentRef.current?.scrollTop]);
  useEffect(setCurrentScrollOffsetPercentage, [contentRef.current?.scrollTop]);

  const handleThumbPosition = () => {
    if (!scrollTrackRef.current || !scrollThumbRef.current) return;
    scrollThumbRef.current.style.top = `${scrollOffsetPercentage}%`;
  };

  const handleDragStart = (e: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
    setDragging(true);
  };

  const handleDragEnd = (e: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
    setDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleDragEnd);

    return () => {
      document.removeEventListener('mouseup', handleDragEnd);
    };
  });

  const handleThumbDrag = (e: React.DragEvent<HTMLDivElement>) => {
    if (!scrollTrackRef.current || !contentRef.current || !dragging) return;

    const { top, height } = scrollTrackRef.current.getBoundingClientRect();
    const relativeCursorPosition = e.clientY - top;

    const targetOffsetPercentage = (relativeCursorPosition / height) * 100;

    const screenHeightCorrection = window.innerHeight / contentRef.current.scrollHeight;

    // Set new scroll percentage and make sure it's within 0 and 100
    setScrollOffsetPercentage(Math.min(Math.max(targetOffsetPercentage, 0), 100 - screenHeightCorrection * 100));

    contentRef.current.scrollTo({
      top: (targetOffsetPercentage / 100) * contentRef.current.scrollHeight,
    });

    handleThumbPosition();
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setCurrentScrollOffsetPercentage();
    handleThumbPosition();
  };

  const handleTrackClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!scrollTrackRef.current || !contentRef.current || dragging) return;

    const target = e.target as HTMLDivElement;
    const relativeClickPosition = e.clientY - target.getBoundingClientRect().top;
    const targetOffsetPercentage = (relativeClickPosition / target.offsetHeight) * 100;
    setScrollOffsetPercentage(targetOffsetPercentage);

    contentRef.current.scrollTo({
      top: (targetOffsetPercentage / 100) * contentRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  const weeks = useMemo(() => {
    const startDateObj = startDate.startOf('week');
    const startYear = parseInt(startDateObj.format('YYYY'));
    const startMonth = parseInt(startDateObj.format('MM')) - 1;

    const filteredWeeks: { [key: string]: number } = {};
    Array.from({ length: 52 }, (_, i) => {
      const weekDate = new Date(startYear, startMonth, i * 7 + 1);

      if (weekDate < startDateObj.toDate()) return null;

      const weekNumber = getWeekNumber(weekDate);

      filteredWeeks[`${weekDate.getFullYear()}_${weekNumber}`] = weekNumber;
      return weekNumber;
    }).filter((week) => week !== null);

    return filteredWeeks;
  }, [startDate]);

  const months = useMemo(() => {
    const startDateObj = startDate.startOf('month');
    const startYear = parseInt(startDateObj.format('YYYY'));
    const startMonth = parseInt(startDateObj.format('MM')) - 1;

    const filteredMonths: { [key: string]: string } = {};
    Array.from({ length: 12 }, (_, i) => {
      const monthDate = new Date(startYear, startMonth + i, 1);

      if (monthDate < startDateObj.toDate()) return null;

      const monthNumber = monthDate.getMonth();

      filteredMonths[`${monthDate.getFullYear()}_${monthNumber + 1}`] = monthDate.toLocaleString('nl-NL', {
        month: 'short',
      });
      return monthNumber;
    }).filter((month) => month !== null);

    return filteredMonths;
  }, [startDate]);

  const monthTabs = useMemo(() => {
    let monthIndex = 0;
    return Object.entries(months ?? {}).map(([key, month]) => {
      const heading = document.querySelector(`[data-month="${key}"]`) as HTMLElement;
      if (heading === null) {
        console.log(heading, key, month);
        return null;
      }

      const offsetPercentage = (heading.offsetTop / (contentRef?.current?.scrollHeight ?? 1)) * 100;

      monthIndex++;
      return (
        <MonthTab key={`tab_${monthIndex}`} title={`${month}`} style={{ top: `calc(${offsetPercentage}% - 2px)` }} />
      );
    });
  }, [months]);

  const weekTabs = useMemo(() => {
    return Object.entries(weeks ?? {}).map(([key, week]) => {
      const heading = document.querySelector(`[data-week="${key}"]`) as HTMLElement;
      if (heading === null) return null;

      const offsetPercentage = (heading.offsetTop / (contentRef?.current?.scrollHeight ?? 1)) * 100;

      return (
        <WeekTab
          key={`tabW_${week}`}
          title={`Week ${week}`}
          onClick={() => {
            heading.scrollIntoView({ behavior: 'smooth' });
          }}
          style={{ top: `calc(${offsetPercentage}%)` }}
        />
      );
    });
  }, [weeks]);

  return (
    <Container>
      <Content onScroll={handleScroll} ref={contentRef} {...props}>
        {children}
      </Content>
      {contentRef.current?.scrollHeight === contentRef.current?.clientHeight ? null : (
        <ScrollBar onMouseMove={handleThumbDrag} onMouseUp={handleDragEnd}>
          <ScrollTrack thumbOnly={false} ref={scrollTrackRef} onClick={handleTrackClick} />
          <ScrollTrack thumbOnly={true}>
            <ScrollThumb
              onMouseDown={handleDragStart}
              ref={scrollThumbRef}
              style={{
                cursor: dragging ? 'grabbing' : 'grab',
              }}
            />
            {monthTabs}
            {weekTabs}
          </ScrollTrack>
        </ScrollBar>
      )}
    </Container>
  );
};

export default TimeLineScrollBar;
