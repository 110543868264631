import styled from 'styled-components';
import { darkTheme, lightTheme } from '../../ui/themes';
import { FormField } from '../../ui';

export enum Appearance {
  System = 'system',
  Dark = 'dark',
  Light = 'light',
}

type Props = {
  onUpdate: (appearance: Appearance) => void;
  value: Appearance;
};

const AppearanceSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 1rem 0;
  > div {
    flex: 1;
  }
`;

const AppearanceLabel = styled.div`
  display: flex;
  aspect-ratio: 4 / 3;
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: 1rem;
  overflow: hidden;
  position: relative;

  margin-bottom: 1rem;
  cursor: pointer;

  > div {
    flex: 1;
    position: relative;
    &:before {
      content: 'Aa';
      position: absolute;
      top: 2rem;
      left: MIN(3rem, 40%);
      width: 100%;
      height: 100%;
      border-radius: 1.5rem;
      padding: 1rem;
      box-shadow: 0 0.8px 2.1px rgba(0, 0, 0, 0.02), 0 2.7px 6.9px rgba(0, 0, 0, 0.03), 0 12px 31px rgba(0, 0, 0, 0.05);
      font-size: 1.2rem;
      z-index: 0;

      @media screen and (max-width: 680px) {
        left: MIN(2rem, 15%);
        width: 200%;
        top: 1rem;
      }
    }
    > .fakeOption {
      @media screen and (max-width: 680px) {
        visibility: hidden;
      }
      position: absolute;
      bottom: 2.5rem;
      font-size: 0.7rem;
      left: calc(MIN(3rem, 40%) + 1rem);
      display: block;
      user-select: none;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: calc(100% + 0.5rem);
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        border: 2px solid;
        background: radial-gradient(
          circle,
          ${lightTheme.colors.primary} 0%,
          ${lightTheme.colors.primary} 50%,
          rgba(0, 0, 0, 0) calc(50% + 1px),
          rgba(0, 0, 0, 0) 100%
        );
      }
      &:after {
        content: 'Ochtend';
        position: absolute;
        top: calc(100% + 0.5rem);
        left: 1.25rem;
        height: 1.25rem;
        display: flex;
        padding-left: 0.5rem;
        align-items: center;
      }
    }
  }

  > .light {
    background-color: ${lightTheme.colors.background};
    &:before {
      background: ${lightTheme.colors.cardBackground};
      color: ${lightTheme.colors.label};
    }
    > .fakeOption {
      color: ${lightTheme.colors.faded};
      &:before {
        border-color: ${lightTheme.colors.muted};
      }
      &:after {
        color: ${lightTheme.colors.label};
      }
    }
  }

  > .dark {
    background-color: ${darkTheme.colors.background};
    &:before {
      background: ${darkTheme.colors.cardBackground};
      color: ${darkTheme.colors.label};
    }
    > .fakeOption {
      color: ${darkTheme.colors.faded};
      &:before {
        border-color: ${darkTheme.colors.muted};
      }
      &:after {
        color: ${darkTheme.colors.label};
      }
    }
  }
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked + label {
    ${AppearanceLabel} {
      &:before {
        content: '';
        z-index: 4;
        position: absolute;
        inset: -2px;
        border-radius: 1rem;
        border: 8px solid ${(props) => props.theme.colors.primary};
      }
    }
  }

  + label .label {
    /* font-size: 0.5em; */
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 680px) {
      font-size: 0.75em;
    }
  }
`;

export default function AppearanceSelector({ onUpdate, value }: Props) {
  return (
    <FormField label="Weergave">
      <div>
        <AppearanceSelectorWrapper>
          <div>
            <RadioInput
              type="radio"
              id="appearance-light"
              name="appearance"
              value={Appearance.Light}
              checked={value === Appearance.Light}
              onChange={() => onUpdate(Appearance.Light)}
            />
            <label htmlFor="appearance-light">
              <AppearanceLabel>
                <div className="light">
                  <div className="fakeOption">Dagdeel</div>
                </div>
              </AppearanceLabel>
              <div className="label">Licht</div>
            </label>
          </div>

          <div>
            <RadioInput
              type="radio"
              id="appearance-dark"
              name="appearance"
              value={Appearance.Dark}
              checked={value === Appearance.Dark}
              onChange={() => onUpdate(Appearance.Dark)}
            />
            <label htmlFor="appearance-dark">
              <AppearanceLabel>
                <div className="dark">
                  <div className="fakeOption">Dagdeel</div>
                </div>
              </AppearanceLabel>
              <div className="label">Donker</div>
            </label>
          </div>
          <div>
            <RadioInput
              type="radio"
              id="appearance-system"
              name="appearance"
              value={Appearance.System}
              checked={value === Appearance.System}
              onChange={() => onUpdate(Appearance.System)}
            />
            <label htmlFor="appearance-system">
              <AppearanceLabel>
                <div className="light">
                  <div className="fakeOption">Dagdeel</div>
                </div>
                <div className="dark">
                  <div className="fakeOption">Dagdeel</div>
                </div>
              </AppearanceLabel>
              <div className="label">Systeem</div>
            </label>
          </div>
        </AppearanceSelectorWrapper>
      </div>
    </FormField>
  );
}
