import { Reel } from '../../components/ChipsSelector';
import { Label } from '../../ui';
import OfficesFilter from './OfficesFilter';
import UserFilter from './UserFilter';

const PlanningFilters = () => {
  return (
    <Reel>
      <UserFilter />
      <div style={{ marginLeft: '2rem' }}>
        <Label type={'faded'}>Ruimtes:</Label>
      </div>
      <OfficesFilter />
    </Reel>
  );
};

export default PlanningFilters;
