import React from 'react';
import styled from 'styled-components';
import { Location, Office } from '../global';
import { ValueLabel } from '../ui';
import ValueLabelWithRing from './ValueLabelWithRing';
import { DayPart } from '../enums';

type Props = {
  office: Office;
  location: Location;
  slotsFilled: number;
  slots: number;
  dayPart?: DayPart;
  hasMultipleLocations: boolean;
};

const Wrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const dayPartTitle = (dayPart: DayPart) => {
  switch (dayPart) {
    case DayPart.Morning:
      return 'Ochtend (van 08:00 tot 13:00)';
    case DayPart.Midday:
      return 'Middag (van 13:00 tot 18:00)';
    case DayPart.FullDay:
      return 'Hele dag';
    default:
      return '';
  }
};

const ReservationOverview: React.FC<Props> = ({
  office,
  location,
  slotsFilled,
  slots,
  dayPart = DayPart.FullDay,
  hasMultipleLocations,
}) => {
  return (
    <Wrapper>
      <ValueLabelWithRing
        ringLabel={`${slotsFilled / 2} / ${slots / 2}`.replace('.', ',')}
        label={hasMultipleLocations ? `Ruimte + locatie` : `Ruimte`}
        value={hasMultipleLocations ? `${office.name} (${location.name})` : office.name}
        progress={(slotsFilled / slots) * 100 || 0}
      />
      <ValueLabel label="Dagdeel" value={dayPartTitle(dayPart)} />
    </Wrapper>
  );
};

export default ReservationOverview;
