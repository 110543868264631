import moment from 'moment';

export const releaseNotes = [
  {
    version: '2.0.0',
    date: moment('2023-03-01'),
    title: 'Deskie 2.0',
    description: '',
    notes: [
      'Deskie wordt doorontwikkeld en verwacht vele nieuwe functies.',
      'Visuele feedback toegevoegd wanneer een gebruiker een reservering probeert toe te voegen op een dag(-deel) waar dit niet mogelijk is.',
      'Controle tussen verschillende kantoren én locaties toegevoegd om visuele feedback te kunnen geven.',
    ],
    bugfixes: [
      'Nederlandse weergave van datums in invulvelden.',
      'Reserveerformulier niet langer uitgeschakeld bij veranderen datum.',
    ],
  },
  {
    version: '2.1.0',
    date: moment('2023-03-14'),
    title: 'Welcome to our office!',
    description: '',
    notes: [
      'Mogelijkheid om gasten uit te nodigen voor een reservering. Nodig gasten uit van buiten de organisatie door hun e-mailadres in te vullen. Gasten kun je uitnodigen binnen het reserveeformulier, klik op de knop "Gasten toevoegen" om te beginnen.',
      'Slimme suggesties voor gasten. Wanneer je gasten uitnodigt, geeft Deskie je suggesties om een gast nog eens uit te nodigen.',
    ],
    bugfixes: [
      'Ook als je al een reservering hebt voor de volgende werkdag, is het mogelijk om met het plusje rechtsbovenin een reservering toe te voegen.',
      'Hoogte van pop-ups consistenter gemaakt.',
    ],
  },
  {
    version: '2.2.0',
    date: moment('2023-05-01'),
    title: 'Lights out!',
    description: '',
    notes: [
      "Donkere modus toegevoegd. Je kunt de donkere modus aan- en uitzetten door op 'Mijn instellingen' te klikken en vervolgens rechtsboven op het potlood te drukken.",
      'Changelog toegevoegd. Je kunt nu teruglezen wat de nieuwste updates van Deskie zijn en ze direct uitproberen!',
    ],
    bugfixes: ['Verbeteringen aan de toegangkelijkheid van de website.'],
  },
  {
    version: '2.3.0',
    date: moment('2023-05-26'),
    title: 'Time flies!',
    description: '',
    notes: [
      'Op de planningpagina vind je nu aan de rechterkant een tijdlijn. Zo kun je makkelijk navigeren naar een moment in de toekomst.',
      'Deskie vraagt nu of je de website als app wil installeren.',
      'Feestdagen worden nu weergeven in het overzicht.',
    ],
    bugfixes: ['Kalender update, jouw kalender geeft nu een beter overzicht van je aanwezigheid.'],
  },
];
