import React from 'react';
import styled from 'styled-components';
import { Location, Office } from '../../global';
import { Heading, Popover, ElevatedCard, OvalButton } from '../../ui';
import LocationRow from './LocationRow';
import EditRemoveMenu from './../../components/EditRemoveMenu';
import { MoreHoriz } from '@styled-icons/material-outlined';
import EmptyState from '../../components/EmptyState';
import { useDispatch } from 'react-redux';
import { updateOffice } from '../../state/offices';

const Card = styled(ElevatedCard)`
  display: grid;
  grid-auto-flow: row;
  padding: 2rem;
  position: relative;
  /* overflow: hidden; */

  h4 {
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

type Props = {
  location: Location;
  editLocation: (location: Location) => void;
  editOffice: (office: Office) => void;
  removeLocation: (location: Location) => void;
  removeOffice: (office: Office) => void;
  openPopupWithTab: (tab: 'office' | 'location') => void;
};

const LocationCard: React.FC<Props> = (props) => {
  const { location, editLocation, editOffice, removeLocation, removeOffice, openPopupWithTab } = props;
  const dispatch = useDispatch();

  const setIsActive = (office: Office, active: boolean) => {
    dispatch(updateOffice({ ...office, isActive: active }));
  };

  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1.5rem' }}>
        <Heading as="h4">{location.name}</Heading>
        <Popover
          openOnHover={false}
          content={() => <EditRemoveMenu edit={() => editLocation(location)} remove={() => removeLocation(location)} />}
        >
          <OvalButton>
            <MoreHoriz />
            <span className="visually-hidden">Meer opties</span>
          </OvalButton>
        </Popover>
      </div>

      {location.offices.length > 0 ? (
        location.offices.map((office, index) => (
          <LocationRow
            key={index}
            title={office.name}
            seats={office.seats}
            isActive={office.isActive}
            setIsActive={(active) => setIsActive(office, active)}
            edit={() => editOffice(office)}
            remove={() => removeOffice(office)}
          />
        ))
      ) : (
        <EmptyState
          text="Deze locatie heeft nog geen ruimtes."
          actionLabel="Voeg een ruimte toe"
          action={() => openPopupWithTab('office')}
        />
      )}
    </Card>
  );
};

export default LocationCard;
