import { Reducer } from 'redux';

type SET_LAYOUT_TITLE = { type: 'SET_LAYOUT_TITLE'; payload: string | undefined };
type SET_LAYOUT_BACK_BUTTON = { type: 'SET_LAYOUT_BACK_BUTTON'; payload: (() => void) | undefined };
type SET_LAYOUT_ACTION_BUTTON = { type: 'SET_LAYOUT_ACTION_BUTTON'; payload: React.ReactNode | undefined };
type SET_SCROLLBAR_DATA = { type: 'SET_SCROLLBAR_DATA'; payload: moment.Moment[] | null | undefined };

export type LayoutStateAction =
  | SET_LAYOUT_TITLE
  | SET_LAYOUT_ACTION_BUTTON
  | SET_LAYOUT_BACK_BUTTON
  | SET_SCROLLBAR_DATA;

export type LayoutState = {
  title?: string | React.ReactNode;
  backButton?: () => void;
  actionButton?: React.ReactNode;
  scrollbarData?: null | moment.Moment[]
};

const initialState: LayoutState = {
  title: undefined,
  actionButton: undefined,
  backButton: undefined,
  scrollbarData: undefined,
};

const layoutReducer: Reducer<LayoutState, LayoutStateAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LAYOUT_TITLE':
      return {
        ...state,
        title: action.payload,
      };
    case 'SET_LAYOUT_BACK_BUTTON':
      return {
        ...state,
        backButton: action.payload,
      };
    case 'SET_LAYOUT_ACTION_BUTTON':
      return {
        ...state,
        actionButton: action.payload,
      };
    case 'SET_SCROLLBAR_DATA':
      return {
        ...state,
        scrollbarData: action.payload,
      };
    default:
      return state;
  }
};

export { setTitle, setActionButton, setBackButton, setScrollbarData } from './actions';
export default layoutReducer;
