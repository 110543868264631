import styled from 'styled-components';

const Seperator = styled.span<{ direction?: 'horizontal' | 'vertical'; margin?: string }>`
  margin: ${(props) =>
    props.direction === 'vertical'
      ? props.margin
        ? `0 ${props.margin}`
        : '0 2rem'
      : props.margin
      ? `${props.margin} 0`
      : '2rem 0'};
  width: 100%;
  display: flex;
  height: 1px;
  background: ${(props) => props.theme.colors.border};

  ${(props) =>
    props.direction === 'vertical' &&
    `
    width: 1px;
    align-self: stretch;
    height: initial;
  `}
`;

export { Seperator };
