import React from 'react';
import EditRemoveMenu from '../../components/EditRemoveMenu';
import styled from 'styled-components';
import { Popover, OvalButton, Label, Switch } from '../../ui';
import { Person, MoreHoriz } from '@styled-icons/material-outlined';

type Props = {
  title: string;
  seats: number;
  edit: () => void;
  remove: () => void;
  isActive: boolean;
  setIsActive: (active: boolean) => void;
};

const Styled = styled.div`
  margin: 0.5rem 0;
  display: flex;
  height: 2rem;
  align-items: center;

  .seats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* min-width: 2.5rem; */
    margin-left: 1rem;

    svg {
      margin-right: 0.25rem;
    }
  }

  label {
    margin-left: auto;
  }
`;

const LocationRow = ({ title, seats, edit, remove, isActive, setIsActive }: Props) => {
  return (
    <Styled>
      <Label type="muted">{title}</Label>
      <div className="seats">
        <Person style={{ height: '1.125rem' }} />
        <Label type="muted" size="small">
          {seats}
        </Label>
      </div>
      <Switch label="" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
      <Popover openOnHover={false} content={() => <EditRemoveMenu edit={() => edit()} remove={() => remove()} />}>
        <OvalButton>
          <MoreHoriz />
          <span className="visually-hidden">Meer opties</span>
        </OvalButton>
      </Popover>
    </Styled>
  );
};

export default LocationRow;
