import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState, User } from '../../global';
import { fetchAllUsers } from '../../state/users';
import { Avatar } from '../../ui/Avatar/Avatar';
import Filter, { FilterSearchable } from '../../ui/Filter/Filter';
import { Label } from '../../ui/Label/Label';

const UserEntry = styled.div`
  display: flex;
  align-items: center;
  ${Label} {
    margin-left: 0.5rem;
  }

  &:hover {
    cursor: pointer;
    position: relative;
    z-index: 0;
    &:before {
      content: '';
      inset: 0;
      position: absolute;
      background-color: ${(props) => props.theme.colors.primary};
      opacity: 0.2;
      border-radius: 1rem;
      z-index: -1;
    }
  }
`;

const UserFilter = () => {
  const dispatch = useDispatch();
  const { users, success } = useSelector((state: RootState) => state.users);
  const { user: loggedInUser } = useSelector((state: RootState) => state.user);
  const { filters } = useSelector((state: RootState) => state.planningFilters);

  useEffect(() => {
    if (success) return;
    dispatch(fetchAllUsers());
    return () => {};
  }, [dispatch, success]);

  const userIsCurrent = (user: User, loggedInUser: User | null) => {
    return !(user.uuid === loggedInUser?.uuid);
  };

  const setAppliedFilter = (id: string | number | null) => {
    if (id === null) {
      dispatch({ type: 'UNSET_FILTER_KEY', payload: { key: 'user' } });
    } else {
      dispatch({ type: 'UPDATE_FILTERS', payload: { filters: { ...filters, user: id } } });
    }
  };

  const filterUsers: FilterSearchable[] | undefined = useMemo(() => {
    const returnUsers = users?.filter((user) => userIsCurrent(user, loggedInUser));

    if (returnUsers === undefined) return [];

    return returnUsers?.map((user) => {
      return {
        id: user.uuid,
        value: user.name,
        children: (
          <UserEntry>
            <Avatar name={user.name} />
            <Label type="fat">{user.name}</Label>
          </UserEntry>
        ),
      } as FilterSearchable;
    });
  }, [users, loggedInUser]);

  return (
    <Filter
      handleAppliedFilter={setAppliedFilter}
      selected={filters.hasOwnProperty('user') ? filters.user ?? undefined : undefined}
      items={filterUsers}
      filterName="Personen"
      unit="Personen"
    />
  );
};

export default UserFilter;
