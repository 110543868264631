import React from 'react';
import { Location } from '../../global';
import ChipsSelector from '../../components/ChipsSelector';

type Props = {
  setSelectedLocation: (locationUuid: string) => void;
  selectedLocation: Location | undefined;
  locations: Location[];
};
const LocationsFilter: React.FC<Props> = ({ selectedLocation, setSelectedLocation, locations }) => {
  return (
    <ChipsSelector
      onSelect={setSelectedLocation}
      selected={selectedLocation ? [selectedLocation.uuid] : []}
      showIcon={true}
      chips={locations.map((l) => ({ label: l.name, id: l.uuid }))}
    />
  );
};

export default LocationsFilter;
