import { ChevronBack, ChevronForward } from '@styled-icons/ionicons-outline';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Heading, IconButton } from '../../ui';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  h1 {
    width: 9rem;
    font-weight: 500;
    text-transform: capitalize;
  }
`;

type Props = {
  nextMonth: () => void;
  prevMonth: () => void;
  currentYear: number;
  currentMonth: number;
};

const MonthNavigator: React.FC<Props> = ({ nextMonth, prevMonth, currentMonth, currentYear }) => {
  const date = moment(`1/${currentMonth}/${currentYear}`, 'D/M/YYYY');
  return (
    <Wrapper>
      <Heading as="h1">{date.format(`MMMM YYYY`)}</Heading>
      <IconButton.Minimal onClick={() => prevMonth()}>
        <ChevronBack />
        <span className="visually-hidden">Ga een maand terug</span>
      </IconButton.Minimal>
      <IconButton.Minimal onClick={() => nextMonth()}>
        <ChevronForward />
        <span className="visually-hidden">Ga een maand vooruit</span>
      </IconButton.Minimal>
    </Wrapper>
  );
};

export default MonthNavigator;
