import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

// Types
import { RootState } from '../global';

// Pages
import Calendar from './Calendar';
import Settings from './Settings';
import Locations from './Manager/Locations';
import Reservations from './Reservations';
import Plannings from './Plannings';
import CompanySettings from './CompanySettings';
import UserSettings from './UserSettings';
import Onboarding from './Onboarding';

// Components
import DatePopup from '../components/DatePopup';

// Actions
import { fetchLocations } from '../state/locations';
import { fetchCompany } from '../state/companies';
import Layout from './Layout';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const untoggled = useSelector((state: RootState) => state.offices.untoggled);
  const locations = useSelector((state: RootState) => state.locations);

  React.useEffect(() => {
    localStorage.setItem('untoggled-offices', JSON.stringify(untoggled));
  }, [untoggled]);

  React.useEffect(() => {
    dispatch(fetchCompany());
    dispatch(fetchLocations());
  }, [dispatch]);

  if ((user.loading && user.user === null) || (locations.loading && locations.locations.length === 0)) {
    return null;
  }

  if (user.user?.preferredLocation?.uuid === undefined && locations.locations.length > 1) {
    return <Onboarding />;
  }

  return (
    <>
      <DatePopup />
      <Layout>
        <Switch>
          <Route exact path="/">
            <Redirect to="/plannings" />
          </Route>
          <Route exact path="/calendar">
            <Redirect to={`/calendar/${moment().format('YYYY/M')}`} />
          </Route>
          <Route exact path="/plannings">
            <Plannings />
          </Route>
          <Route exact path="/reservations">
            <Reservations />
          </Route>
          <Route path="/calendar/:year/:month">
            <Calendar />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <ProtectedRoute path="/manage/locations">
            <Locations />
          </ProtectedRoute>
          <ProtectedRoute path="/manage/companysettings">
            <CompanySettings />
          </ProtectedRoute>
          <ProtectedRoute path="/manage/usersettings">
            <UserSettings />
          </ProtectedRoute>
          <ProtectedRoute path="/manage">
            <Redirect to="/manage/companysettings" />
          </ProtectedRoute>
          <Route path="*">
            <div>404</div>
          </Route>
        </Switch>
      </Layout>
    </>
  );
};

export default App;
