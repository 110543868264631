import React from 'react';
import styled from 'styled-components';
import { Label } from '../ui';
import { Info } from '@styled-icons/material-outlined';

const Styled = styled.div`
  display: flex;

  svg {
    color: ${(props) => props.theme.colors.faded};
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
    margin-top: 0.125rem;
  }
`;

type Props = {
  cost: number;
  userCredits: number;
};

const ReservationCreditInfo: React.FC<Props> = ({ cost, userCredits }) => {
  const creditOrCredits = (amount: number) => (amount === 1 ? 'credit' : 'credits');
  return (
    <Styled>
      <Info />
      <Label type="faded" size="small">
        Deze reservering kost {cost} {creditOrCredits(cost)}. Jij hebt nog {userCredits} {creditOrCredits(userCredits)}{' '}
        beschikbaar.
      </Label>
    </Styled>
  );
};

export default ReservationCreditInfo;
