import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  display: flex;
  height: 3.5rem;
  padding: 0 1rem;
  font: inherit;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 0.5rem;
  box-shadow: none;
  background: ${(props) => props.theme.colors.inputBackground};
  -webkit-appearance: none;
  color: inherit;
  cursor: pointer;

  &[type='color'] {
    width: 4rem;
    padding: 2px;
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.colors.primary};
    outline: 0;
  }
`;

const Select = styled.select`
  width: 100%;
  display: flex;
  height: 3.5rem;
  padding: 0 1rem;
  font: inherit;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 0.5rem;
  box-shadow: none;
  background: ${(props) => props.theme.colors.inputBackground};
  -webkit-appearance: none;
  color: inherit;
  cursor: pointer;

  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.colors.primary};
    outline: 0;
  }
`;

export { Input, Select };
