import { Trash } from '@styled-icons/ionicons-outline';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { IconButton } from '../../ui/Button/Button';
import { FormField } from '../../ui/Form/Form';
import { Input } from '../../ui/Input/Input';
import { Guest } from '../../global';

const Wrap = styled.div`
  &.overflow *:not(button, svg, path) {
    border-color: ${(props) => props.theme.colors.error};
    color: ${(props) => props.theme.colors.error};
    &::placeholder {
      color: ${(props) => props.theme.colors.error};
    }
  }
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const DoubleFormField = styled.div`
  width: 100%;
  input {
    &:first-of-type {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:last-of-type {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-top-width: 0;
    }
  }
`;

const AlignFieldButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  button {
    margin-left: 1rem;
    aspect-ratio: 1 / 1;
  }
`;

type Props = {
  inputGuest: Guest;
  allowDelete?: boolean;
  allowGuestReservation?: boolean; // Indicates if there are more guests than spots.
  handleUpdateGuest: (a: Guest) => void;
  handleDeleteGuest: () => void;
  emailIsDuplicate?: boolean;
};

const GuestInput: React.FC<Props> = ({
  inputGuest,
  allowDelete = true,
  allowGuestReservation = true,
  handleUpdateGuest,
  handleDeleteGuest,
  emailIsDuplicate = false,
}) => {
  const [guest, setGuest] = useState<Guest>(inputGuest);
  const [emailIsInvalid, setEmailIsInvalid] = useState<boolean>(false);

  useEffect(() => {
    setGuest(inputGuest);
  }, [inputGuest]);

  const deleteGuestHandler = () => {
    if (guest.name.length > 0 || guest.email.length > 0) {
      if (window.confirm('Weet je zeker dat je deze gast wil verwijderen?')) {
        handleDeleteGuest();
      }
      return;
    }
    handleDeleteGuest();
  };

  const addCapitalizationToFullName = (name: string) => {
    const nameArray = name.toLowerCase().split(' ');
    // List of name prefixes that should not be capitalized. Source: https://nl.wikipedia.org/wiki/Tussenvoegsel
    const ignorePrefixes = [
      'aan',
      'af',
      'bij',
      'de',
      'den',
      'der',
      "d'",
      'het',
      "'t",
      'in',
      'onder',
      'op',
      'over',
      "'s",
      "'t",
      'te',
      'ten',
      'ter',
      'tot',
      'uit',
      'uijt',
      'van',
      'ver',
      'voor',
    ];
    const capitalizedArray = nameArray.map((namePart) => {
      if (ignorePrefixes.includes(namePart.toLowerCase())) {
        return namePart;
      }
      return namePart.charAt(0).toUpperCase() + namePart.slice(1);
    });

    return capitalizedArray.join(' ');
  };

  return (
    <Wrap
      onBlur={() => {
        handleUpdateGuest({ ...guest, name: addCapitalizationToFullName(guest.name) });
      }}
      className={allowGuestReservation && !emailIsDuplicate && !emailIsInvalid ? '' : 'overflow'}
    >
      <FormField
        label={
          <>
            Naam + email
            {!allowGuestReservation ? (
              <>
                {' '}
                | <Strong>Let op:</Strong> Deze locatie is vol op dit tijdstip.
              </>
            ) : (
              ''
            )}
            {emailIsDuplicate ? (
              <>
                {' '}
                | <Strong>Let op:</Strong> Dit emailadres is al opgegeven
              </>
            ) : (
              ''
            )}
            {emailIsInvalid ? (
              <>
                {' '}
                | <Strong>Let op:</Strong> Dit emailadres is ongeldig
              </>
            ) : (
              ''
            )}
          </>
        }
        style={{ width: '100%', marginBlock: '1rem' }}
      >
        <AlignFieldButton>
          <DoubleFormField>
            <Input
              type={'text'}
              value={guest?.name}
              placeholder={'Naam gast'}
              onChange={(e) => {
                setGuest({ ...guest, name: e.target.value });
              }}
            />
            <Input
              type={'email'}
              value={guest?.email}
              placeholder={'Email gast'}
              onBlur={() => setEmailIsInvalid(guest.email.length > 0 && !guest.email.match(/.+@.+\..+/))}
              onChange={(e) => {
                setGuest({ ...guest, email: e.target.value });
              }}
            />
          </DoubleFormField>
          <IconButton.Minimal
            disabled={!allowDelete}
            onClick={
              allowDelete ? deleteGuestHandler : () => toast.error('Je kan het laaste gastveld niet verwijderen.')
            }
          >
            <Trash />
            <span className="visually-hidden">Verwijder gast</span>
          </IconButton.Minimal>
        </AlignFieldButton>
      </FormField>
    </Wrap>
  );
};

export default GuestInput;
