import React from 'react';
import styled from 'styled-components';

type Rest = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type Props = Rest & {
  label: string;
};

const Styled = styled.label`
  cursor: pointer;

  input[type='checkbox'] {
    display: none;
  }

  span {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.label};
  }

  span:before {
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${(props) => props.theme.colors.border};
  }

  span:after {
    content: '';
    opacity: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none' /%3E%3Cpath fill='%23ffffff' d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/%3E%3C/svg%3E");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    position: absolute;
    border: 2px solid transparent;
  }

  input:checked ~ span::before {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
  }

  input:checked ~ span::after {
    opacity: 1;
  }
`;

const Checkbox: React.FC<Props> = ({ label, ...rest }) => {
  return (
    <Styled>
      <input {...rest} type="checkbox" />
      <span>{label}</span>
    </Styled>
  );
};

export { Checkbox };
