import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add, LocationOn } from '@styled-icons/material-outlined';
import { Location, Office, RootState } from '../../global';
import { createLocation, updateLocation, deleteLocation } from '../../state/locations';
import { updateOffice, deleteOffice, createOffice } from '../../state/offices';
import { IconButton, Drawer, Modal, Container } from '../../ui';
import ChipsSelector from './../../components/ChipsSelector';
import EmptyState from './../../components/EmptyState';
import OfficeForm from './OfficeForm';
import LocationCard from './LocationCard';
import LocationForm from './LocationForm';
import styled from 'styled-components';
import useWindowSize from '../../utils/useWindowSize';
import { setTitle, setActionButton } from '../../state/layout';

const TabsWrapper = styled.div`
  display: grid;
`;

const Tab = styled.div<{ active: boolean; tabId: string }>`
  transition: 0.25s cubic-bezier(0.86, 0, 0.07, 1);
  opacity: ${(props) => (props.active ? 1 : 0)};
  transform: ${(props) =>
    props.active ? 'translate3d(0, 0, 0)' : `translate3d(${props.tabId === 'office' ? '20' : '-20'}px, 0, 0)`};
  grid-column: 1;
  grid-row: 1;
  align-self: stretch;
  pointer-events: ${(props) => (props.active ? 'initial' : 'none')};
  display: flex;
  flex-direction: column;
`;

const Locations: React.FC = () => {
  const dispatch = useDispatch();
  const locations = useSelector((state: RootState) => state.locations.locations);

  const { isMobileDevice } = useWindowSize();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [locationToEdit, setLocationToEdit] = useState<Location | undefined>(undefined);
  const [officeToEdit, setOfficeToEdit] = useState<Office | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string>('office');

  React.useEffect(() => {
    dispatch(setTitle('Locaties en Ruimtes'));
    dispatch(
      setActionButton(
        <IconButton.Minimal onClick={() => setIsOpen(true)}>
          <Add />
          <span className="visually-hidden">Voeg een locatie of ruimte toe</span>
        </IconButton.Minimal>,
      ),
    );
  }, [dispatch, setIsOpen]);

  const onCloseDrawer = () => {
    setIsOpen(false);
    setTimeout(() => {
      setLocationToEdit(undefined);
      setOfficeToEdit(undefined);
    }, 200);
  };

  const openPopupWithTab = (tab: 'office' | 'location') => {
    setActiveTab(tab);
    setIsOpen(true);
  };

  // Locations
  const editLocation = (location: Location) => {
    setLocationToEdit(location);
    setIsOpen(true);
  };

  const removeLocation = (location: Location) => {
    dispatch(deleteLocation({ uuid: location.uuid }));
  };

  const handleCreateLocation = (location: Pick<Location, 'name'>) => {
    dispatch(createLocation(location));
    setIsOpen(false);
  };

  const handleUpdateLocation = (location: Pick<Location, 'name' | 'uuid'>) => {
    dispatch(updateLocation(location));
    setLocationToEdit(undefined);
    setIsOpen(false);
  };

  // Offices
  const editOffice = (office: Office) => {
    setOfficeToEdit(office);
    setIsOpen(true);
  };

  const removeOffice = (office: Office) => {
    dispatch(deleteOffice({ uuid: office.uuid }));
  };

  const handleCreateOffice = (selectedLocation: string, office: Pick<Office, 'name' | 'seats'>) => {
    dispatch(
      createOffice({
        name: office.name,
        seats: office.seats,
        locationUuid: selectedLocation,
      }),
    );
    setIsOpen(false);
  };

  const handleUpdateOffice = (locationUuid: string, office: Pick<Office, 'name' | 'seats' | 'uuid' | 'isActive'>) => {
    dispatch(
      updateOffice({
        name: office.name,
        uuid: office.uuid,
        seats: office.seats,
        locationUuid: locationUuid,
        isActive: office.isActive,
      }),
    );
    setOfficeToEdit(undefined);
    setIsOpen(false);
  };

  const title = () => {
    if (!locationToEdit && !officeToEdit) {
      return 'Nieuw';
    } else if (officeToEdit && !locationToEdit) {
      return officeToEdit.name ?? '';
    } else {
      return locationToEdit?.name ?? '';
    }
  };

  const tabs = [
    { label: 'Ruimte', id: 'office' },
    { label: 'Locatie', id: 'location' },
  ];

  const Element = isMobileDevice ? Drawer : Modal;

  return (
    <>
      <Element isOpen={isOpen} onCancel={() => onCloseDrawer()} title={title()}>
        {locationToEdit && <LocationForm updateLocation={handleUpdateLocation} location={locationToEdit} />}

        {officeToEdit && <OfficeForm updateOffice={handleUpdateOffice} office={officeToEdit} />}

        {!locationToEdit && !officeToEdit && (
          <>
            <div style={{ marginBottom: '1rem' }}>
              <ChipsSelector selected={[activeTab]} chips={tabs} onSelect={setActiveTab} />
            </div>

            <TabsWrapper>
              <Tab active={activeTab === 'location'} tabId="office">
                <LocationForm createLocation={handleCreateLocation} />
              </Tab>

              <Tab active={activeTab === 'office'} tabId="location">
                <OfficeForm createOffice={handleCreateOffice} />
              </Tab>
            </TabsWrapper>
          </>
        )}
      </Element>

      <Container>
        {locations.length > 0 ? (
          locations.map((loc, index) => (
            <LocationCard
              key={index}
              location={loc}
              editLocation={editLocation}
              editOffice={editOffice}
              removeLocation={removeLocation}
              removeOffice={removeOffice}
              openPopupWithTab={openPopupWithTab}
            />
          ))
        ) : (
          <EmptyState
            text="Nog geen locaties toegevoegd"
            actionLabel="Voeg een locatie toe"
            action={() => openPopupWithTab('location')}
            icon={<LocationOn />}
          />
        )}
      </Container>
    </>
  );
};

export default Locations;
