import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState, User } from '../global';
import { Avatar as _Avatar, FormField, Input, Container, Heading, Label } from '../ui';
import { fetchAllUsers } from '../state/users';
import styled from 'styled-components';
import { ElevatedCard } from '../ui';

import { LocationOn, Search } from '@styled-icons/material-outlined';
import { sortBy } from 'lodash';
import ChipsSelector from '../components/ChipsSelector';
import { setTitle, setActionButton } from '../state/layout';

const Avatar = styled(_Avatar)`
  grid-row: span 2;
`;

const UserCards = styled.div`
  display: grid;
  gap: 1.5rem;
  margin-top: 1.5rem;
`;

const Card = styled(ElevatedCard)`
  display: grid;
  grid-template-columns: 3rem minmax(0, 1fr);
  gap: 1rem;
  padding: 2rem;
  overflow: hidden;

  ul {
    padding: 0;
    grid-column: 2;
    grid-row: 2;
    display: grid;
    gap: 0.5rem;
    margin: 0;
  }

  ${Heading} {
    grid-column: span 2;
  }
`;

const UserCardLabel = styled(Label).attrs(() => ({
  as: 'li',
  type: 'muted',
  size: 'small',
}))`
  display: flex;
  align-items: center;

  svg {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.5rem;
    color: ${(props) => props.theme.colors.label};
  }
`;

const Credits = styled(Label)`
  font-weight: 600;
  width: 1.25rem;
  text-align: center;
  margin-right: 0.5rem;
  color: ${(props) => props.theme.colors.label};
`;

const UserCard: React.FC<{ user: User; showCredits: boolean }> = ({ user, showCredits }) => {
  return (
    <Card>
      <Heading>{user.name}</Heading>
      <Avatar name={user.name} />
      <ul>
        <UserCardLabel as="li">
          <LocationOn /> {user.preferredLocation?.name || 'Nog geen voorkeurslocatie'}
        </UserCardLabel>
        {showCredits && (
          <UserCardLabel>
            <Credits>{user.credits}</Credits> credits
          </UserCardLabel>
        )}
      </ul>
    </Card>
  );
};

const Icon = styled(Search)`
  position: absolute;
  left: 1rem;
  top: 1rem;
  color: ${(props) => props.theme.colors.muted};

  & + input {
    padding-left: 3rem;
  }
`;

const UserSettings: React.FC = () => {
  const dispatch = useDispatch();
  const locations = useSelector((state: RootState) => state.locations.locations);
  const { company } = useSelector((state: RootState) => state.companies);
  const { users } = useSelector((state: RootState) => state.users);
  const [selectedLocations, setSelectedLocations] = React.useState<string[]>([]);

  const [searchValue, setSearchValue] = React.useState<string>('');

  const filteredUsers = sortBy(
    (users || [])
      .filter((u) =>
        selectedLocations.length > 0 ? selectedLocations.includes(u.preferredLocation?.name || '') : true,
      )
      .filter((u) =>
        searchValue ? u.name.toUpperCase().replace(' ', '').includes(searchValue.toUpperCase().replace(' ', '')) : true,
      ),
    'name',
  );

  const handleSelectLocation = (locationName: string) => {
    if (selectedLocations.includes(locationName)) {
      setSelectedLocations(selectedLocations.filter((l) => l !== locationName));
    } else {
      setSelectedLocations([...selectedLocations, locationName]);
    }
  };

  React.useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(setTitle('Gebruikers'));
    dispatch(setActionButton(undefined));
  }, [dispatch]);

  return (
    <Container>
      <ChipsSelector
        onSelect={handleSelectLocation}
        selected={selectedLocations}
        chips={locations.map((l) => ({ label: l.name, id: l.name }))}
      />
      <FormField style={{ marginTop: '1.5rem', position: 'relative' }}>
        <Icon size="1.5rem" />
        <Input value={searchValue} placeholder="Zoeken..." onChange={(e) => setSearchValue(e.target.value)} />
      </FormField>
      <UserCards>
        {filteredUsers.map((user) => (
          <UserCard key={user.uuid} user={user} showCredits={company?.usesCredits || false} />
        ))}
      </UserCards>
    </Container>
  );
};

export default UserSettings;
