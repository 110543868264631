import styled from 'styled-components';

const Badge = styled.span`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.onLabel};
  border-radius: 0.25rem;
  text-align: center;
  font-weight: 700;
  padding: 0.25rem;
  font-size: 0.875rem;
  margin: 0 0.5rem;
`;

export { Badge };
