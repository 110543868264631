import { ThunkAction } from 'redux-thunk';
import axios, { AxiosRequestConfig } from 'axios';
import { Guest, Planning, PlanningUser, RootState } from '../../global';
import { PlanningStateAction } from '.';
import { toast } from 'react-toastify';
import { Moment } from 'moment';
import { fetchUser } from '../users';
import { planningsCache } from './cache';
import { fetchReservations } from '../reservations';
import { PlanningPopupStateAction } from '../planningPopup';
import { DayPart } from '../../enums';

type PlanningsResponse = {
  uuid: string;
  date: string;
  dayPart: DayPart;
  signedInUsers: {
    uuid: string;
    name: string;
  }[];
  gasten: {
    uuid: string;
    naam: string;
    emailadres: string;
    ingechecktOp: Date;
    gastbegeleider: {
      uuid: string;
      naam: string;
    };
  }[];
  locatie: {
    locatieUuid: string;
    locatieNaam: string;
    ruimteUuid: string;
    ruimteNaam: string;
  };
  isCompleted: boolean;
};

type PlanningThunk<R = void> = ThunkAction<R, RootState, unknown, PlanningStateAction | PlanningPopupStateAction>;

type FetchPlanningBlockProps = {
  officeUuid: string;
  month: number;
  year: number;
};

let isFetching: string[] = [];

export const fetchPlanningBlock =
  (props: FetchPlanningBlockProps, useCache: boolean = true): PlanningThunk =>
  async (dispatch) => {
    const { officeUuid, month, year } = props;

    const url = `/api/Ruimtes/${officeUuid}/reserveringen?month=${month}&year=${year}`;

    if (url in planningsCache && useCache) return;

    if (isFetching.includes(url)) return;

    isFetching.push(url);

    dispatch<PlanningStateAction>({
      type: 'FETCH_PLANNING_BLOCK_REQUEST',
      payload: { officeUuid, month, year },
    });

    const response = await axios
      .get<PlanningsResponse[]>(url)
      .catch((e) => {
        toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het ophalen van de kantoren.');
        dispatch<PlanningStateAction>({
          type: 'FETCH_PLANNING_BLOCK_ERROR',
          payload: { officeUuid, month, year },
        });
      })
      .finally(() => {
        isFetching = isFetching.filter((u) => u !== url);
      });

    if (response) {
      const plannings: Planning[] = response.data.map((planning) => {
        const guestsAsSignedInUsers = planning.gasten.map((guest) => ({
          uuid: guest.uuid,
          name: `${guest.naam} (Gast)`,
        })) as PlanningUser[];

        return {
          date: planning.date,
          dayPart: planning.dayPart,
          signedInUsers: [...planning.signedInUsers, ...guestsAsSignedInUsers],
          guests: planning.gasten as unknown as Guest[],
          isCompleted: planning.isCompleted,
          locationUuid: planning.locatie.locatieUuid,
          locationName: planning.locatie.locatieNaam,
          officeUuid: planning.locatie.ruimteUuid,
          officeName: planning.locatie.ruimteNaam,
          uuid: planning.uuid,
        };
      });
      planningsCache[url] = { key: url, timestamp: new Date(), data: plannings };
      dispatch<PlanningStateAction>({
        type: 'FETCH_PLANNING_BLOCK_SUCCESS',
        payload: { officeUuid, month, year, plannings },
      });
    }
  };

type MakeReservationProps = {
  date: Moment;
  officeUuid: string;
  dayPart: DayPart;
  guests?: Guest[];
};

export const makeReservation = ({
  date,
  officeUuid,
  dayPart,
  guests = [],
}: MakeReservationProps): PlanningThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const selectedUser = getState().user.user;

    if (selectedUser === null) return;

    const requestObject: AxiosRequestConfig = {
      method: 'POST',
      url: `/api/Ruimtes/${officeUuid}/reserveringen`,
      data: {
        dayPart,
        date: date.format(),
      },
    };

    if (guests.length > 0) {
      // Filter out empty guests
      const filteredGuests = guests.filter((guest) => guest.name !== '' && guest.email !== '');
      if (filteredGuests.length > 0) {
        requestObject.data.gasten = filteredGuests.map((guest) => {
          return { naam: guest.name, emailadres: guest.email };
        });
      }
    }

    const response = await axios(requestObject).catch((e) => {
      if (e.response?.data.includes('User does not have sufficient credits')) {
        toast.error('Je hebt niet genoeg credits om de reservering te voltooien.');
      } else {
        toast.error('Er is iets fout gegaan tijdens het maken van je reservering.');
      }

      return Promise.reject(e);
    });

    if (response) {
      toast.success('Reservering aangemaakt.');
      dispatch({ type: 'CLOSE_PLANNING_POPUP' });
      dispatch({ type: 'SET_GUESTS', payload: { guests: [] } });
      dispatch(fetchPlanningBlock({ month: date.month() + 1, year: date.year(), officeUuid }, false));
      dispatch(fetchUser());
      dispatch(fetchReservations({ userUuid: selectedUser.uuid }, false));
      return Promise.resolve();
    }
  };
};

type DeleteReservationProps = {
  date: Moment;
  officeUuid: string;
  dayPart: DayPart;
};

export const deleteReservation = ({
  date,
  officeUuid,
  dayPart,
}: DeleteReservationProps): PlanningThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const selectedUser = getState().user.user;

    if (selectedUser === null) return;

    const response = await axios({
      method: 'DELETE',
      url: `/api/Ruimtes/${officeUuid}/reserveringen`,
      data: {
        dayPart,
        date: date.format(),
      },
    }).catch((e) => {
      toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het maken van je reservering.');
      return Promise.reject();
    });

    if (response) {
      toast.success('Reservering verwijderd.');
      dispatch({ type: 'CLOSE_PLANNING_POPUP' });
      dispatch(fetchPlanningBlock({ month: date.month() + 1, year: date.year(), officeUuid }, false));
      dispatch(fetchReservations({ userUuid: selectedUser.uuid }, false));
      dispatch(fetchUser());
      return Promise.resolve();
    }
  };
};
