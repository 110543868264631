import React from 'react';
import styled from 'styled-components';
import { ValueLabel, Label } from '../ui';
import { ProgressRing } from '../ui';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 4.5rem;
  align-items: center;
`;

const Ring = styled.div`
  display: flex;
  align-items: center;
  justify-self: flex-end;

  ${Label} {
    white-space: nowrap;
  }
`;

type Props = {
  value: string;
  label: string;
  progress: number;
  ringLabel: string;
};

const ValueLabelWithRing: React.FC<Props> = ({ value, label, progress, ringLabel }) => {
  return (
    <Wrapper>
      <ValueLabel label={label} value={value} />
      <Ring>
        <Label type="muted" size="small">
          {ringLabel}
        </Label>
        <ProgressRing radius={18} stroke={4} progress={progress} />
      </Ring>
    </Wrapper>
  );
};

export default ValueLabelWithRing;
