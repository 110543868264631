import { groupBy } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Month from './Month';
import Offices from './Offices';
import { Planning, RootState } from '../../global';
import { fetchPlanningBlock } from '../../state/plannings';
import { PlanningPopupStateAction } from '../../state/planningPopup';
import { Container, IconButton, Heading } from '../../ui';
import { Add } from '@styled-icons/material-outlined';
import LocationsFilter from './LocationsFilter';
import MonthNavigator from './MonthNavigator';
import { setActionButton, setBackButton, setTitle } from '../../state/layout';
import getNextWorkableDay from '../../utils/getNextWorkableDay';
import styled from 'styled-components';

const TabsWrapper = styled.div`
  display: grid;
`;

const Tab = styled.div<{ active: boolean; tabId: string }>`
  transition: 0.25s cubic-bezier(0.86, 0, 0.07, 1);
  opacity: ${(props) => (props.active ? 1 : 0)};
  transform: ${(props) =>
    props.active ? 'translate3d(0, 0, 0)' : `translate3d(${props.tabId === 'details' ? '20' : '-20'}px, 0, 0)`};
  grid-column: 1;
  grid-row: 1;
  align-self: stretch;
  pointer-events: ${(props) => (props.active ? 'initial' : 'none')};
  display: flex;
  flex-direction: column;
`;

const Calendar: React.FC = () => {
  const today = moment().startOf('day');
  const dispatch = useDispatch();
  const planning = useSelector((state: RootState) => state.planning);
  const locations = useSelector((state: RootState) => state.locations.locations);
  const [selectedLocationUuid, setSelectedLocation] = React.useState<string | undefined>(
    locations.length > 0 ? locations[0].uuid : undefined,
  );
  const selectedLocation = locations.find((l) => l.uuid === selectedLocationUuid);

  const history = useHistory();
  const params = useParams<{ month: string; year: string }>();

  const month = parseInt(params.month);
  const year = parseInt(params.year);

  const keys = (selectedLocation && selectedLocation.offices.map((o) => `${o.uuid}_${year}_${month}`)) || [];
  const plannings = keys.reduce<Planning[]>((a, b) => (b in planning ? [...a, ...planning[b].plannings] : a), []);
  const planningDates = groupBy(plannings, 'date');

  const [selectedDate, setSelectedDate] = React.useState<Moment>(() => today);
  const [officeViewActive, setOfficeViewActive] = React.useState<boolean>(false);

  function nextMonth() {
    if (month === 12) {
      history.push(`/calendar/${year + 1}/${1}`);
    } else {
      history.push(`/calendar/${year}/${month + 1}`);
    }
  }

  function prevMonth() {
    if (month === 1) {
      history.push(`/calendar/${Math.max(year - 1, 1970)}/${12}`);
    } else {
      history.push(`/calendar/${year}/${month - 1}`);
    }
  }

  React.useEffect(() => {
    if (officeViewActive) {
      dispatch(setBackButton(() => setOfficeViewActive(false)));
      dispatch(setTitle(`Agenda ${selectedLocation?.name}`));
      // dispatch(setTitle(selectedDate.format('dddd DD MMMM yyyy')));
    } else {
      dispatch(setBackButton(undefined));
      dispatch(setTitle('Agenda'));
    }

    return () => {
      dispatch(setBackButton(undefined));
    };
  }, [officeViewActive, setOfficeViewActive, dispatch, selectedLocation]);

  React.useEffect(() => {
    selectedLocation &&
      selectedLocation.offices
        .filter((o) => o.isActive)
        .forEach((office) => dispatch(fetchPlanningBlock({ month, year, officeUuid: office.uuid })));
  }, [dispatch, selectedLocation, month, year]);

  React.useEffect(() => {
    dispatch(setTitle('Agenda'));
    dispatch(
      setActionButton(
        <IconButton.Minimal
          onClick={() =>
            selectedLocation &&
            dispatch<PlanningPopupStateAction>({
              type: 'OPEN_PLANNING_POPUP',
              payload: { date: getNextWorkableDay(), officeUuid: selectedLocation.offices[0].uuid },
            })
          }
        >
          <Add />
          <span className="visually-hidden">Reservering toevoegen</span>
        </IconButton.Minimal>,
      ),
    );
  }, [dispatch, selectedLocation]);

  const selectDate = (date: Moment) => {
    setSelectedDate(date);
    setOfficeViewActive(true);
  };

  const selectOffice = (officeUuid: string) => {
    dispatch<PlanningPopupStateAction>({
      type: 'OPEN_PLANNING_POPUP',
      payload: { date: selectedDate, officeUuid },
    });
  };

  return (
    <TabsWrapper>
      <Tab tabId="overview" active={!officeViewActive}>
        <Container>
          {locations.length > 1 && (
            <LocationsFilter
              locations={locations}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
            />
          )}
          <MonthNavigator nextMonth={nextMonth} prevMonth={prevMonth} currentMonth={month} currentYear={year} />
        </Container>
        <Month
          month={month}
          year={year}
          selectDate={selectDate}
          indexedPlannings={planningDates}
          offices={selectedLocation ? selectedLocation.offices.filter((o) => o.isActive) : []}
        />
      </Tab>
      <Tab tabId="details" active={officeViewActive}>
        <Container>
          <Heading type="md" style={{ marginTop: '1rem' }}>
            {selectedDate.format('dddd DD MMMM yyyy')}
          </Heading>
          <Offices
            indexedPlannings={planningDates}
            offices={selectedLocation?.offices || []}
            selectOffice={selectOffice}
            selectedDate={selectedDate}
          />
        </Container>
      </Tab>
    </TabsWrapper>
  );
};

export default Calendar;
