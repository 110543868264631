import { Reducer } from 'redux';
import moment, { Moment } from 'moment';
import { Guest } from '../../global';

export interface GuestSuggestion {
  uuid: string;
  name: string;
  email: string;
  numberOfInvites: number;
}

type OPEN_PLANNING_POPUP = {
  type: 'OPEN_PLANNING_POPUP';
  payload: { date: Moment; officeUuid: string };
};
type CLOSE_PLANNING_POPUP = { type: 'CLOSE_PLANNING_POPUP' };
type SET_SELECTED_DATE = { type: 'SET_SELECTED_DATE'; payload: { date: Moment } };
type SET_SELECTED_OFFICE = { type: 'SET_SELECTED_OFFICE'; payload: { officeUuid: string } };
type SET_GUESTS = { type: 'SET_GUESTS'; payload: { guests: Guest[] } };
type SET_GUEST_SUGGESTIONS = { type: 'SET_GUEST_SUGGESTIONS'; payload: { guestSuggestions: GuestSuggestion[] } };

export type PlanningPopupStateAction =
  | OPEN_PLANNING_POPUP
  | CLOSE_PLANNING_POPUP
  | SET_SELECTED_DATE
  | SET_SELECTED_OFFICE
  | SET_GUESTS
  | SET_GUEST_SUGGESTIONS;

export type PlanningPopupState = {
  date: Moment;
  officeUuid: string | null;
  active: boolean;
  guests: Guest[];
  guestSuggestions: GuestSuggestion[];
};

const initialState: PlanningPopupState = {
  date: moment(),
  officeUuid: null,
  active: false,
  guests: [],
  guestSuggestions: [],
};

const planningPopupReducer: Reducer<PlanningPopupState, PlanningPopupStateAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_DATE':
      return {
        ...state,
        date: action.payload.date,
      };
    case 'SET_SELECTED_OFFICE':
      return {
        ...state,
        officeUuid: action.payload.officeUuid,
      };
    case 'OPEN_PLANNING_POPUP':
      return {
        ...state,
        active: true,
        date: action.payload.date,
        officeUuid: action.payload.officeUuid,
      };
    case 'CLOSE_PLANNING_POPUP':
      return {
        ...state,
        active: false,
      };
    case 'SET_GUESTS':
      return {
        ...state,
        guests: action.payload.guests,
      };
    case 'SET_GUEST_SUGGESTIONS':
      return {
        ...state,
        guestSuggestions: action.payload.guestSuggestions,
      };
    default:
      return state;
  }
};

export default planningPopupReducer;
