import moment, { Moment } from 'moment';

type Row = { days: Moment[] };

export function generateRowsForMonthView(month: number, year: number, generateWeekends: boolean = true): Row[] {
  const firstDayOfMonth = moment()
    .month(month - 1)
    .year(year)
    .date(1);

  let rows: Row[] = [];
  const startDay = firstDayOfMonth.clone().startOf('month').startOf('week');
  const endDay = firstDayOfMonth.clone().endOf('month').endOf('week');

  let date = startDay.clone();

  while (date.isBefore(endDay, 'day')) {
    let days: Moment[] = [];

    Array(7)
      .fill(0)
      .forEach(() => {
        days.push(date.clone());
        date.add(1, 'day');
      });

    // Remove Saturday and Sundays if needed
    if (!generateWeekends) {
      days = days.filter((day) => !isWeekend(day));
    }

    // Remove a week if none of the days are in the current month
    let anyInCurrentMonth = false;
    days.forEach((x) => {
      if (anyInCurrentMonth) return;

      if (x.month() === month - 1) {
        anyInCurrentMonth = true;
      }
    });

    // Add the week if any of the days are in the current month
    if (anyInCurrentMonth) {
      rows.push({ days });
    }
  }

  return rows;
}

export function generateDaysInMonth(month: number, year: number): Moment[] {
  const firstDayOfMonth = moment()
    .month(month - 1)
    .year(year)
    .date(1);

  const days = new Array(firstDayOfMonth.daysInMonth())
    .fill(null)
    .map((x, i) => firstDayOfMonth.clone().startOf('month').add(i, 'days'));

  return days;
}

export function isWeekend(date: Moment) {
  var day = date.day();
  return day === 6 || day === 0;
}

export function datesGroupByComponent(dates: Moment[], token: string) {
  return dates.reduce<{ [key: string]: Moment[] }>(function (val, obj) {
    let comp = obj.format(token);
    (val[comp] = val[comp] || []).push(obj);
    return val;
  }, {});
}
