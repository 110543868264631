import React from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { Button, FormField, Input, Form, Switch } from '../../ui';

export type EditFormData = {
  name: string;
  creditsPerUser: string;
  usesCredits: boolean;
};

const EditForm: React.FC<EditFormData & { onSubmit: SubmitHandler<EditFormData> }> = ({
  name,
  creditsPerUser,
  usesCredits,
  onSubmit,
}) => {
  const { handleSubmit, register, control, watch } = useForm<EditFormData>({
    defaultValues: {
      name: name,
      creditsPerUser: creditsPerUser === '365' ? '2' : creditsPerUser,
      usesCredits: usesCredits,
    },
  });

  const usesCreditsToggled = watch('usesCredits');

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormField label="Bedrijfsnaam">
        <Input type="text" name="name" ref={register()} />
      </FormField>
      <FormField>
        <Controller
          control={control}
          name="usesCredits"
          render={({ onChange, onBlur, value, name }) => (
            <FormField label="Gebruik credits">
              <Switch label="" onBlur={onBlur} onChange={(e) => onChange(e.target.checked)} checked={value} />
            </FormField>
          )}
        />
      </FormField>
      {usesCreditsToggled && (
        <FormField label="Standaard aantal credits per medewerker">
          <Input type="number" name="creditsPerUser" min="1" ref={register()} />
        </FormField>
      )}
      <Button.Primary>Opslaan</Button.Primary>
    </Form>
  );
};

export default EditForm;
