import React, { useEffect, useState } from 'react';
import { Modal, Drawer, Heading } from '../ui';
import { Email, Phone } from '@styled-icons/material-outlined';
import styled from 'styled-components';
import useWindowSize from '../utils/useWindowSize';
import { Tab, TabsWrapper } from './DatePopup';
import ChipsSelector from './ChipsSelector';
import ReleaseNote from './ReleaseNote/ReleaseNote';
import ScrollView from '../ui/ScrollView/ScrollView';
import { useDispatch, useSelector } from 'react-redux';
import { releaseNotes } from '../releaseNotes';
import { RootState } from '../global';

type HelpProps = {
  isOpen: boolean;
  onCancel: () => void;
};

const Content = styled.div`
  line-height: 1.5;
  font-size: 0.875rem;
  width: 100%;

  h3 {
    margin-bottom: 0.25rem;
  }

  ul {
    margin-top: 0.75rem;
    width: 100%;
  }

  li {
    display: flex;
    align-items: center;
    width: 100%;

    a {
      text-decoration: none;
      padding: 0.25rem 0.375rem 0.375rem 0.375rem;
      border-radius: 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;

      svg {
        color: ${(props) => props.theme.colors.label};
      }

      &:hover {
        background: ${(props) => props.theme.colors.background};
      }

      span {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: underline;
      }

      .note {
        text-decoration: none;
        color: ${(props) => props.theme.colors.muted};
        margin-left: 0.5rem;
        font-size: 0.75rem;
      }
    }

    svg {
      width: 1rem;
      color: ${(props) => props.theme.colors.muted};
      margin-right: 0.75rem;
    }
  }
`;

function Help({ isOpen, onCancel }: HelpProps) {
  const dispatch = useDispatch();
  const { isMobileDevice } = useWindowSize();
  const [activeTab, setActiveTab] = React.useState<string>('help');
  const [showReleaseNoteBadge, setShowReleaseNoteBadge] = useState(false);
  const { lastReadUpdate } = useSelector((state: RootState) => state.preferences);

  const Element = isMobileDevice ? Drawer : Modal;
  const tabs = [
    { label: 'Help', id: 'help' },
    { label: "What's new", id: 'release_notes', showBadge: showReleaseNoteBadge },
  ];

  useEffect(() => {
    const lastReleaseNote = releaseNotes.sort((a, b) => {
      if (a.date.isBefore(b.date)) {
        return 1;
      }
      if (a.date.isAfter(b.date)) {
        return -1;
      }
      return 0;
    });

    if (activeTab === 'release_notes') {
      dispatch({ type: 'SET_LAST_READ_UPDATE', payload: lastReleaseNote[0].version });
    }

    setShowReleaseNoteBadge(lastReleaseNote[0].version !== lastReadUpdate);
  }, [activeTab, dispatch, lastReadUpdate]);

  return (
    <Element
      title="Over Deskie"
      isOpen={isOpen}
      onCancel={onCancel}
      isCancellable={true}
      renderHeader={() => (
        <div style={{ marginTop: '1rem' }}>
          <ChipsSelector selected={[activeTab]} chips={tabs} onSelect={setActiveTab} />
        </div>
      )}
    >
      <Content>
        <TabsWrapper>
          <Tab active={activeTab === 'help'} tabId="help">
            <Heading as="h2">Service Deskie</Heading>
            <p>Wij zijn bereikbaar op de volgende kanalen voor hulp en/of feedback:</p>
            <ul>
              <li>
                <a href="mailto:info@deskie.nl" target="_blank" rel="noopener noreferrer">
                  <Email />
                  <span>info@deskie.nl</span>
                </a>
              </li>
              <li>
                <a href="tel:0387601066" target="_blank" rel="noopener noreferrer">
                  <Phone />
                  <span>038 760 1066</span>
                  <span className="note">(09:00 - 17:00)</span>
                </a>
              </li>
            </ul>
          </Tab>
          <Tab active={activeTab === 'release_notes'} tabId="release_notes">
            <ScrollView maxHeight="MIN(70vh, 600px)">
              {releaseNotes
                .sort((a, b) => {
                  if (a.date.isBefore(b.date)) {
                    return 1;
                  }
                  if (a.date.isAfter(b.date)) {
                    return -1;
                  }
                  return 0;
                })
                .map((releaseNote, index) => (
                  <ReleaseNote
                    version={releaseNote.version}
                    date={releaseNote.date}
                    title={releaseNote.title}
                    description={releaseNote.description}
                    bugfixes={releaseNote.bugfixes}
                    notes={releaseNote.notes}
                    key={index}
                  />
                ))}
            </ScrollView>
          </Tab>
        </TabsWrapper>
      </Content>
    </Element>
  );
}

export default Help;
