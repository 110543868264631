import axios from 'axios';
import { toast } from 'react-toastify';
import { ThunkAction } from 'redux-thunk';
import { CompanyStateAction } from '.';
import { Company, RootState } from '../../global';

type CompaniesResponse = {
  name: string;
  uuid: string;
}[];

type CompanyResponse = {
  name: string;
  uuid: string;
  creditsPerUser: number;
};

type CompanyThunk<R = void> = ThunkAction<R, RootState, unknown, CompanyStateAction>;

export const fetchCompany = (): CompanyThunk => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_COMPANY_REQUEST' });

  try {
    const response = await axios.get<CompaniesResponse>('/api/Companies');

    if (response && response.data.length > 0) {
      const res = await axios.get<CompanyResponse>(`/api/Companies/${response.data[0].uuid}`);

      const company: Company = {
        name: res.data.name,
        uuid: res.data.uuid,
        creditsPerUser: res.data.creditsPerUser,
        usesCredits: res.data.creditsPerUser !== 365,
      };

      dispatch({ type: 'FETCH_COMPANY_SUCCESS', payload: company });
    }
  } catch (e: any) {
    toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het ophalen van een organisatie.');
    dispatch({ type: 'FETCH_COMPANY_FAILED' });
  }
};

type UpdateCompanyProps = {
  name: string;
  creditsPerUser: number;
};

export const updateCompany =
  ({ name, creditsPerUser }: UpdateCompanyProps): CompanyThunk =>
  async (dispatch, getState) => {
    const company = getState().companies.company;

    if (!company) return;

    const response = await axios({
      url: `/api/Companies/${company.uuid}`,
      method: 'PATCH',
      data: { name, creditsPerUser },
    }).catch((e) => {
      toast.error(e.response?.data || 'Er is iets fout gegaan met het updaten van de bedrijfsinstellingen');
    });

    if (response) {
      toast.success('Bedrijfsinstellingen geupdated.');
      dispatch(fetchCompany());
    }
  };
