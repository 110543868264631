import { combineReducers } from 'redux';
import usersReducer from './users';
import authReducer from './authentication';
import locationsReducer from './locations';
import companiesReducer from './companies';
import planningPopupReducer from './planningPopup';
import officesReducer from './offices';
import allUsersReducer from './users/allUsersReducer';
import reservationsReducer from './reservations';
import planningReducer from './plannings';
import layoutReducer from './layout';
import planningFiltersReducer from './planningFilters';
import preferencesReducer from './preferences';

const rootReducer = combineReducers({
  auth: authReducer,
  user: usersReducer,
  locations: locationsReducer,
  offices: officesReducer,
  planningPopup: planningPopupReducer,
  planningFilters: planningFiltersReducer,
  users: allUsersReducer,
  reservations: reservationsReducer,
  planning: planningReducer,
  companies: companiesReducer,
  layout: layoutReducer,
  preferences: preferencesReducer,
});

export default rootReducer;
