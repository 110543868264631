import React, { ReactElement, useRef } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsErrorBoundary, AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import Error from '../components/Error';
import { useHistory } from 'react-router-dom';

const { instrumentationKey } = (window as any)['appConfig'];

function AppInsightsProvider({ children }: { children: ReactElement }) {
  const isDevelopment = process.env.NODE_ENV === 'development';

  const history = useHistory();

  const reactPlugin = useRef(new ReactPlugin());

  const appInsights = useRef(
    isDevelopment
      ? null
      : new ApplicationInsights({
          config: {
            instrumentationKey: instrumentationKey,
            maxBatchInterval: 0,
            disableFetchTracking: false,
            extensions: [reactPlugin.current],
            extensionConfig: {
              [reactPlugin.current.identifier]: { history: history },
            },
          },
        }),
  );

  React.useEffect(() => {
    appInsights.current?.loadAppInsights();
  }, []);

  if (isDevelopment) {
    return <>{children}</>;
  }

  return (
    <AppInsightsContext.Provider value={reactPlugin.current}>
      <AppInsightsErrorBoundary onError={Error} appInsights={reactPlugin.current}>
        {children}
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

export { AppInsightsProvider };
