import React from 'react';
import { Label } from '..';
import styled from 'styled-components';

type Props = {
  label: string;
  value: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${Label}:first-child {
    margin-bottom: 0.5rem;
  }
`;

const ValueLabel: React.FC<Props> = ({ label, value }) => {
  return (
    <Container>
      <Label type="faded" size="small" as="span">
        {label}
      </Label>

      <Label type="fat" as="span">
        {value}
      </Label>
    </Container>
  );
};

export { ValueLabel };
