import styled, { useTheme } from 'styled-components';

type ChipContainerProps = {
  color?: string;
  textColor?: string;
};

const ChipContainer = styled.div<ChipContainerProps>`
  height: 1.5rem;
  padding-inline: 0.75rem;
  align-items: center;
  margin: 0.25rem;
  background-color: ${(props) => props.color ?? props.theme.colors.primary};
  display: inline-flex;
  border-radius: 100px;
  color: ${(props) => props.textColor ?? 'white'};
  font-size: 0.9rem;
`;

type Props = {
  title: string;
  color?: string;
  textColor?: string;
  icon?: JSX.Element;
};

export default function Chip({ title, color = undefined, textColor = undefined, icon = undefined }: Props) {
  const theme = useTheme();
  return (
    <ChipContainer color={color ?? theme.colors.primary} textColor={textColor ?? 'white'}>
      {icon ?? null}
      <span>{title}</span>
    </ChipContainer>
  );
}
