import React from 'react';
import styled from 'styled-components';

type Rest = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type Props = Rest & {
  label: string;
};

const Styled = styled.label`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 999rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;

  input[type='radio'] {
    display: none;
  }

  span {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.onLabel};
    position: relative;
  }

  span:before {
    content: '';
    width: 18px;
    height: 18px;
    margin-right: 0.75rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${(props) => props.theme.colors.border};
  }

  span:after {
    content: '';
    opacity: 0;
    background-size: contain;
    background-position: center;
    width: 10px;
    height: 10px;
    left: 4px;
    top: 4px;
    position: absolute;
    border: 2px solid transparent;
    background-color: ${(props) => props.theme.colors.onLabel};
    border-radius: 50%;
  }

  input:checked ~ span::after {
    opacity: 1;
  }
`;

const Pill: React.FC<Props> = ({ label, ...rest }) => {
  return (
    <Styled>
      <input {...rest} type="radio" />
      <span>{label}</span>
    </Styled>
  );
};

export { Pill };
