import moment, { Moment } from 'moment';

function getNextWorkableDay(date?: Moment) {
  const m = date || moment();
  const d = m.day();

  // saturday
  if (d === 6) {
    return m.add(2, 'days');
  }

  if (d === 5) {
    return m.add(3, 'days');
  }

  return m.add(1, 'day');
}

export default getNextWorkableDay;
