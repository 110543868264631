import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../global';
import { Heading, FormField, Select, Form } from '../ui';
import { updateUser, UserStateAction } from '../state/users';
import { ThunkDispatch } from 'redux-thunk';
import { Styled, Container, LoginButton } from './Intro';
import { toast } from 'react-toastify';

const Onboarding: React.FC = () => {
  const { locations } = useSelector((state: RootState) => state.locations);

  const dispatch: ThunkDispatch<RootState, undefined, UserStateAction> = useDispatch();

  const [selectedLocationUuid, setSelectedLocation] = React.useState<string>('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedLocationUuid) {
      dispatch(updateUser({ preferredLocationUuid: selectedLocationUuid })).then(() => {
        toast.success('Voorkeurslocatie ingesteld.');
      });
    }
  };

  return (
    <Styled>
      <svg className="logo" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000">
        <path
          fill="#6C5DD3"
          d="M400.6,80.3c82.1,0.1,157.8,22.4,228.5,63.8c70.6,41.4,141.8,82,212.7,122.9c60.4,34.9,97,86.4,107.9,155.5
	c13.2,84.2-25.9,168.6-98.6,213c-17.4,10.6-35.3,20.5-52.7,31.2c-8.1,4.9-15.1,4.9-23.4,0.1c-101.4-58.3-202.9-116.4-304.3-174.6
	c-123-70.5-246-141-369.1-211.6c-18.8-10.8-37.7-21.5-56.5-32.4c-15.7-9.1-15.6-21.9,0-31c48.4-28.1,96.4-56.9,145.4-84.2
	c53.3-29.7,110.8-46.2,171.7-51.4C375,80.8,387.8,80.6,400.6,80.3z"
        />
        <path
          fill="#B2AAE8"
          d="M980.9,499.1c0,77.1,0,154.3,0,231.4c0,4.7-0.8,9.3-1.3,14c-5.9,31.7-21,58.9-41,83.6
	c-33.6,41.4-76.2,71.3-123,95.7c-14.8,7.7-28.3-0.9-28.4-17.8c-0.1-60.1-0.1-120.3,0-180.4c0-12,3.4-16.3,14.9-21.1
	c58.3-24.8,106.5-61.8,140.3-116.1c15.3-24.6,26.1-51.1,32.5-79.5C975.8,505.2,974.9,499.9,980.9,499.1z"
        />
        <path
          fill="#877BDB"
          d="M19.2,405.3c0-35.3,0-70.6,0-106c0-20.7,7.4-25.2,25.6-14.8c79.3,45.8,158.4,91.8,237.6,137.7
	c4.3,2.5,8.1,5.3,7.1,11c-1.3,6.9-7.2,7.6-12.6,8.5c-41.4,6.9-82,16.4-121.6,30.6c-38.4,13.8-74.8,31.2-106.5,57.5
	c-5.9,4.9-12.4,6.6-19.7,3.4c-7.7-3.5-9.9-10-9.9-18C19.3,478.5,19.2,441.9,19.2,405.3L19.2,405.3z"
        />
      </svg>
      <Heading as="h1">Deskie</Heading>
      <Container>
        <span className="title">Welkom!</span>
        <span className="body">
          Vul jouw voorkeurslocatie in om Deskie optimaal te kunnen gebruiken. Dit is later aan te passen in jouw
          instellingen.
        </span>
        <Form onSubmit={handleSubmit}>
          <FormField label="Jouw voorkeurslocatie" light={true}>
            <Select onChange={(e) => setSelectedLocation(e.target.value)} value={selectedLocationUuid}>
              <option value="">Selecteer een locatie</option>
              {locations.map((location) => (
                <option key={location.uuid} value={location.uuid}>
                  {location.name}
                </option>
              ))}
            </Select>
          </FormField>
          <LoginButton disabled={selectedLocationUuid === ''}>Opslaan</LoginButton>
        </Form>
      </Container>
    </Styled>
  );
};

export default Onboarding;
