import styled from 'styled-components';

type Props = {
  title: string;
  buttonIcon?: string | Element | React.ReactNode;
  onButtonPress?: () => void;
};

const IosDrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 16px;
`;

const IosDrawerHeaderTitle = styled.div`
  font-weight: 500;
  font-size: ${(props) => props.theme.iosStyles.titleSize};
  color: ${(props) => props.theme.iosStyles.titleColor};
`;

const IosDrawerHeaderButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.theme.iosStyles.iconBackground};
  color: ${(props) => props.theme.iosStyles.iconBackground};

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 15px;
  pointer-events: all;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export default function IosNativeDrawerHeader({ title, buttonIcon, onButtonPress }: Props) {
  return (
    <IosDrawerHeader>
      <IosDrawerHeaderTitle>{title}</IosDrawerHeaderTitle>
      {buttonIcon && <IosDrawerHeaderButton onClick={onButtonPress}>{buttonIcon}</IosDrawerHeaderButton>}
    </IosDrawerHeader>
  );
}
