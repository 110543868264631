import React from 'react';
import styled from 'styled-components';
import { Document, Trash } from '@styled-icons/ionicons-outline';
import { IconButton } from '../Button/Button';

export const FileGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
  grid-auto-rows: minmax(10rem, 1fr);
  gap: 1rem;
`;

const Actions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: none;
  opacity: 0;
`;

const ActionButton = styled(IconButton.Minimal)`
  color: rgba(255, 255, 255, 0.9);

  &:hover {
    background: transparent;
    color: rgba(255, 255, 255, 1);
  }
`;

const Preview = styled.div`
  position: relative;

  &:hover {
    ${Actions} {
      opacity: 1;
      pointer-events: initial;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: ${(props) => props.theme.colors.border};
  display: block;
  margin: 0;
`;

const File = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.border};

  span {
    font-size: 0.875rem;
    text-align: center;
    max-width: 80%;
    line-height: 1.4;
    color: ${(props) => props.theme.colors.label};
    margin-top: 0.25rem;
    word-break: break-word;
  }
`;

type Props = {
  src: string;
  name: string;
  type: string;
  onRemove?: (src: string) => void;
};

export const FilePreview: React.FC<Props> = ({ src, name, type, onRemove }) => {
  const isImage = type.split('/')[0] === 'image';

  return (
    <Preview>
      <Actions>
        <ActionButton type="button" onClick={() => onRemove && onRemove(src)}>
          <Trash />
        </ActionButton>
      </Actions>
      {isImage ? (
        <Image src={src} alt={name} />
      ) : (
        <File>
          <Document size="2rem" />
          <span>{name.length > 40 ? `${name.substring(0, 40)}...` : name}</span>
        </File>
      )}
    </Preview>
  );
};
