import { LayoutStateAction } from './';

export const setTitle = (title?: string): LayoutStateAction => ({
  payload: title,
  type: 'SET_LAYOUT_TITLE',
});

export const setActionButton = (actionButton?: React.ReactNode): LayoutStateAction => ({
  payload: actionButton,
  type: 'SET_LAYOUT_ACTION_BUTTON',
});

export const setBackButton = (backButton?: () => void): LayoutStateAction => ({
  payload: backButton,
  type: 'SET_LAYOUT_BACK_BUTTON',
});

export const setScrollbarData = (data: moment.Moment[] | null | undefined): LayoutStateAction => ({
  payload: data,
  type: 'SET_SCROLLBAR_DATA',
});
