import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { Location, Office, RootState } from '../../global';
import { LocationsStateAction } from '.';
import { toast } from 'react-toastify';

type LocatiesResponse = {
  naam: string;
  uuid: string;
  isActief: boolean;
  ruimtes: {
    uuid: string;
    naam: string;
    plekken: number;
    isActief: boolean;
  }[];
};

type LocationsThunk<R = void> = ThunkAction<R, RootState, unknown, LocationsStateAction>;

type UpdateLocationProps = { name: string; uuid: string };
type CreateLocationProps = { name: string };
type DeleteLocationProps = { uuid: string };
type CreateOfficeProps = { name: string; locationUuid: string; seats: number };

export const fetchLocations = (): LocationsThunk => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_LOCATIONS_REQUEST' });

  const response = await axios.get<LocatiesResponse[]>('/api/Locaties').catch((e) => {
    toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het ophalen van de locaties.');
    dispatch({ type: 'FETCH_LOCATIONS_FAILED' });
  });

  if (response) {
    const locations: Location[] = response.data.map((locatie) => ({
      name: locatie.naam,
      uuid: locatie.uuid,
      isActive: locatie.isActief,
      offices: locatie.ruimtes.map<Office>((ruimte) => ({
        isActive: ruimte.isActief,
        locationUuid: locatie.uuid,
        name: ruimte.naam,
        seats: ruimte.plekken,
        uuid: ruimte.uuid,
      })),
    }));
    dispatch({ type: 'FETCH_LOCATIONS_SUCCESS', payload: locations });
  }
};

export const createLocation =
  (location: CreateLocationProps): LocationsThunk =>
  async (dispatch, getState) => {
    const response = await axios.post(`/api/Locaties`, { naam: location.name, isActief: true }).catch((e) => {
      toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het maken van de locatie.');
    });

    if (response) {
      toast.success('Locatie aangemaakt.');
      dispatch(fetchLocations());
    }
  };

export const updateLocation =
  (location: UpdateLocationProps): LocationsThunk =>
  async (dispatch, getState) => {
    const response = await axios
      .patch(`/api/Locaties/${location.uuid}`, { naam: location.name, isActief: true })
      .catch((e) => {
        toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het updaten van de locatie.');
      });

    if (response) {
      toast.success('Locatie geupdated.');
      dispatch(fetchLocations());
    }
  };

export const deleteLocation =
  (location: DeleteLocationProps): LocationsThunk =>
  async (dispatch, getState) => {
    const response = await axios.delete(`/api/Locaties/${location.uuid}`).catch((e) => {
      toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het updaten van de locatie.');
    });

    if (response) {
      toast.success('Locatie geupdated.');
      dispatch(fetchLocations());
    }
  };

export const createOffice =
  (office: CreateOfficeProps): LocationsThunk =>
  async (dispatch, getState) => {
    const response = await axios
      .post(`/api/Locaties/${office.locationUuid}/ruimtes`, { name: office.name, seats: office.seats })
      .catch((e) => {
        toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het maken van het kantoor.');
      });

    if (response) {
      toast.success('Kantoor opgeslagen.');
      dispatch(fetchLocations());
    }
  };
