import styled from 'styled-components';

export const Card = styled.div`
  background: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 1.5rem;
  padding: 1rem;
`;

export const ElevatedCard = styled(Card)`
  /* box-shadow: 0 4px 2.9px rgba(0, 0, 0, 0.012), 0 13.4px 9.6px rgba(0, 0, 0, 0.018), 0 60px 43px rgba(0, 0, 0, 0.03); */
  box-shadow: 0 0.8px 2.1px rgba(0, 0, 0, 0.02), 0 2.7px 6.9px rgba(0, 0, 0, 0.03), 0 12px 31px rgba(0, 0, 0, 0.05);
  background: ${(props) => props.theme.colors.cardBackground};
  border: 0;
`;
