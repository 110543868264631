import { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../global';
import { fetchLoginState } from '../state/authentication';
import Intro from '../containers/Intro';

function AuthProvider({ children }: PropsWithChildren<{}>) {
  const dispatch = useDispatch();
  const { isLoggedIn, loading } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(fetchLoginState());
  }, [dispatch]);

  if (!isLoggedIn && !loading) {
    return <Intro />;
  }

  if (isLoggedIn) {
    return <>{children}</>;
  }

  return null;
}

export default AuthProvider;
