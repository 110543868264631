import styled from 'styled-components';

export const SimpleLink = styled.a`
  color: ${(props) => props.theme.colors.onCardBackgroundEmphasized};
  text-decoration: underline;
  cursor: pointer;
  transition: 0.2s ease-out;
  &:hover {
    color: ${(props) => props.theme.colors.primaryDarker};
  }
`;
