import React, { Key } from 'react';
import styled, { css } from 'styled-components';
import { ElevatedCard, Heading, Label } from '../../ui';
import { ProgressRing } from '../../ui';
import { PlanningUser } from '../../global';
import DefaultAvatarGroup from '../AvatarGroup';
import { Today } from '@styled-icons/material-outlined';

const Card = styled(ElevatedCard)<{ highlighted: boolean; isFull?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  text-align: left;

  .holidayName {
    background: ${(props) => props.theme.colors.primary + '33'};
    color: ${(props) => props.theme.colors.primary};
    font-size: 0.7em;
    font-weight: lighter;
    padding: 0.2em 0.5em;
    border-radius: 0.5em;
  }

  ${(props) =>
    props.isFull &&
    !props.highlighted &&
    css`
      z-index: 0;
      &:after {
        content: '';
        position: absolute;
        inset: 0;
        background: ${(props) => props.theme.colors.faded + '20'};
        border: 1px solid ${(props) => props.theme.colors.muted};
        border-radius: 1.5rem;
        z-index: -1;
      }
    `};

  ${(props) =>
    props.highlighted &&
    css`
      z-index: 0;
      &:after {
        content: '';
        position: absolute;
        inset: 0;
        background: ${(props) => props.theme.colors.primary + '20'};
        z-index: -1;
        border: 1px solid ${(props) => props.theme.colors.primary};
        border-radius: 1.5rem;
      }
    `};

  h4 {
    margin-bottom: 0.5rem;
    max-width: calc(100% - 5rem);
    display: flex;
    flex-wrap: wrap;
    gap: 0 0.5rem;
    align-items: center;
  }
`;

const AvatarGroup = styled(DefaultAvatarGroup)`
  margin-top: 1rem;
  max-width: 100%;
`;

const CardPlanning = styled(Label).attrs((props) => ({ type: 'muted' }))`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  font-weight: 300;
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors.label};

  svg {
    width: 1em;
    margin-right: 0.5rem;
  }
`;

const CardRing = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 2rem;
  top: 2rem;
  margin-top: -0.25rem;
  margin-right: -0.375rem;
`;

const ReservationListItem = styled.li`
  list-style: none;
  > button {
    width: 100%;
    height: 100%;
  }
`;

export type ReservationCardProps = {
  title: string;
  subtitle?: string;
  planningTitle?: string;
  users: PlanningUser[];
  slots: number;
  slotsFilled: number;
  onClick: () => void;
  className?: string;
  highlighted?: boolean;
  holidays?: moment.Holiday[];
  asListItem?: boolean;
};

const ReservationCard: React.FC<ReservationCardProps> = (props) => {
  const {
    title,
    subtitle,
    users,
    slots,
    slotsFilled,
    onClick,
    highlighted = false,
    className,
    planningTitle,
    holidays = false,
    asListItem = false,
  } = props;

  const card = (
    <Card
      as="button"
      aria-haspopup="true"
      aria-label={`Open reserveringspopup voor ${title}`}
      onClick={onClick}
      highlighted={highlighted}
      isFull={slotsFilled / slots >= 1}
      className={className}
    >
      {highlighted ? <span className="visually-hidden">Geselecteerd</span> : null}

      <Heading type="lg" as="h4">
        {title}{' '}
        {holidays
          ? holidays.map((holiday, i: Key) => (
              <span key={i} className={`holidayName`}>
                {holiday.name}
              </span>
            ))
          : null}
      </Heading>
      {planningTitle && (
        <CardPlanning>
          <Today />
          {planningTitle}
        </CardPlanning>
      )}
      {subtitle && (
        <Label type="muted" size="small">
          {subtitle}
        </Label>
      )}
      <CardRing>
        <Label type="muted" size="small">
          <span className="visually-hidden">Capaciteit:</span>
          {slotsFilled.toString().replace('.', ',')} <span className="visually-hidden">van</span>/ {slots}
          <span className="visually-hidden">plekken</span>
        </Label>
        <ProgressRing radius={18} stroke={4} progress={(slotsFilled / slots) * 100 || 0} />
      </CardRing>
      <AvatarGroup avatars={users.map((user) => ({ name: user.name }))} maxLength={4} />
    </Card>
  );

  if (asListItem) {
    return <ReservationListItem>{card}</ReservationListItem>;
  }

  return card;
};

export default ReservationCard;
