import { Add } from '@styled-icons/ionicons-outline';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Guest, Office, Planning, RootState } from '../../global';
import { Button } from '../../ui';
import useWindowSize from '../../utils/useWindowSize';
import ReservationInfo from '../ReservationInfo';
import GuestInput from './GuestInput';
import { GuestSuggestions } from './GuestSuggestions';
import { hexToRgb } from '../../utils/hexToRGB';

const Guests = styled.div<{ active: boolean; isMobileDevice: boolean }>`
  overflow-y: auto;
  max-height: 65vh;
  height: ${(props) => (props.active ? '100%' : '0')};
  position: relative;
  padding-bottom: 50px;

  &:before,
  &:after {
    // overflow indicator (for bottom)
    content: '';
    position: fixed;
    background-image: linear-gradient(
      0deg,
      rgba(${(props) => hexToRgb(props.theme.colors.cardBackground)}, 1),
      rgba(${(props) => hexToRgb(props.theme.colors.cardBackground)}, 0)
    );
    inset: calc(100% - 50px) 0 0 0;
    pointer-events: none;
  }

  &:before {
    // overflow indicator (for top)
    inset: 0 0 calc(100% - 50px) 0;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    background-image: linear-gradient(
      180deg,
      rgba(${(props) => hexToRgb(props.theme.colors.cardBackground)}, 1),
      rgba(${(props) => hexToRgb(props.theme.colors.cardBackground)}, 0)
    );
  }

  &:not([data-scroll='0']):before {
    opacity: 1;
  }
`;

type Props = {
  active: boolean;
  freeSpots?: number;
  plannings: Planning[];
  office: Office;
};

const GuestTab = ({ active, plannings, office, freeSpots = 0 }: Props) => {
  const dispatch = useDispatch();
  const { planningPopup } = useSelector((state: RootState) => state);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { isMobileDevice } = useWindowSize();

  // Deduct one free spot, for it is assigned to the organiser.
  const maxGuests = freeSpots - 1;

  const updateGuest = (index: number, guest: Guest) => {
    const modifiable = [...planningPopup.guests];
    modifiable.splice(index, 1, guest);
    dispatch({ type: 'SET_GUESTS', payload: { guests: modifiable } });
  };

  const deleteGuest = (index: number) => {
    const modifiable = [...planningPopup.guests];
    modifiable.splice(index, 1);
    dispatch({ type: 'SET_GUESTS', payload: { guests: modifiable } });
  };

  const addNewField = useCallback(() => {
    dispatch({ type: 'SET_GUESTS', payload: { guests: [...planningPopup.guests, { name: '', email: '' }] } });
  }, [dispatch, planningPopup.guests]);

  useEffect(() => {
    if (planningPopup.guests.length < 1) {
      addNewField();
    }
  }, [addNewField, planningPopup.guests]);

  // Scrolloffset
  useEffect(() => {
    const onScroll = (e: any) => {
      scrollContainerRef.current?.setAttribute('data-scroll', e.target.scrollTop ?? 0);
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', onScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', onScroll);
      }
    };
  }, [scrollContainerRef]);

  if (maxGuests <= 0) {
    return <ReservationInfo text={`Het is op deze datum niet mogelijk om gasten uit te nodigen op deze locatie.`} />;
  }

  return (
    <Guests ref={scrollContainerRef} isMobileDevice={isMobileDevice} active={active} data-scroll={`0`}>
      <ReservationInfo
        text={`Neem je gasten mee naar kantoor? Meld ze dan aan om gegarandeerd te zijn van een plek in deze ruimte. Je kunt maximaal ${maxGuests} gasten uitnodigen.`}
      />
      <GuestSuggestions />
      <div className="guests">
        {planningPopup.guests.map((item, index) => {
          return (
            <GuestInput
              key={index}
              inputGuest={item}
              allowGuestReservation={!(index >= maxGuests)}
              allowDelete={!(index === 0 && planningPopup.guests.length <= 1)}
              handleUpdateGuest={(updatedGuest: Guest) => updateGuest(index, updatedGuest)}
              handleDeleteGuest={() => deleteGuest(index)}
              emailIsDuplicate={
                planningPopup.guests.reduce(
                  (count, guest) => (guest.email === item.email && item.email !== '' ? count + 1 : count),
                  0,
                ) > 1
              }
            />
          );
        })}
      </div>
      <Button.Primary onClick={addNewField} disabled={planningPopup.guests.length >= maxGuests}>
        {' '}
        <Add /> Nog een gast toevoegen
      </Button.Primary>
    </Guests>
  );
};

export default GuestTab;
