import { Reducer } from 'redux';
import { Planning } from '../../global';

type PLANNING_BLOCK_PAYLOAD = { officeUuid: string; month: number; year: number };

// Actions
type FETCH_PLANNING_BLOCK_REQUEST = {
  type: 'FETCH_PLANNING_BLOCK_REQUEST';
  payload: PLANNING_BLOCK_PAYLOAD;
};
type FETCH_PLANNING_BLOCK_SUCCESS = {
  type: 'FETCH_PLANNING_BLOCK_SUCCESS';
  payload: PLANNING_BLOCK_PAYLOAD & { plannings: Planning[] };
};
type FETCH_PLANNING_BLOCK_ERROR = {
  type: 'FETCH_PLANNING_BLOCK_ERROR';
  payload: PLANNING_BLOCK_PAYLOAD;
};
export type PlanningStateAction =
  | FETCH_PLANNING_BLOCK_REQUEST
  | FETCH_PLANNING_BLOCK_SUCCESS
  | FETCH_PLANNING_BLOCK_ERROR;

// State
enum PlanningBlockState {
  FETCHING = 'FETCHING',
  ERROR = 'ERROR',
  UPDATING = 'UPDATING',
  SUCCESS = 'SUCCESS',
}

export type PlanningState = {
  [key: string]: {
    plannings: Planning[];
    state: PlanningBlockState;
  };
};

const initialState: PlanningState = {};

const planningReducer: Reducer<PlanningState, PlanningStateAction> = (state = initialState, action) => {
  const key = action.payload
    ? `${action.payload.officeUuid}_${action.payload.year}_${action.payload.month}`
    : undefined;

  const exists = key && key in state;

  if (!key) return state;

  switch (action.type) {
    case 'FETCH_PLANNING_BLOCK_REQUEST':
      return {
        ...state,
        [key]: {
          state: exists ? PlanningBlockState.UPDATING : PlanningBlockState.FETCHING,
          plannings: exists ? state[key].plannings : [],
        },
      };
    case 'FETCH_PLANNING_BLOCK_SUCCESS':
      return {
        ...state,
        [key]: {
          state: PlanningBlockState.SUCCESS,
          plannings: action.payload.plannings,
        },
      };
    case 'FETCH_PLANNING_BLOCK_ERROR':
      return {
        ...state,
        [key]: {
          ...state[key],
          state: PlanningBlockState.ERROR,
        },
      };
    default:
      return state;
  }
};

export { fetchPlanningBlock, makeReservation, deleteReservation } from './thunks';
export default planningReducer;
