import { IconButton } from '..';
import styled from 'styled-components';

export const OvalButton = styled(IconButton.Minimal)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.border};
  color: ${(props) => props.theme.colors.muted};

  &:hover {
    background: ${(props) => props.theme.colors.primary};

    svg {
      color: white;
    }
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;
