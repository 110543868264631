import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import TenantCreator from '../components/TenantCreator';
import { RootState } from '../global';
import { createTenant, getTenant } from '../state/tenants';
import type { CreateTenantProps } from '../state/tenants/thunks';
import { createUser, fetchUser, UserStateAction } from '../state/users';

function Welcome() {
  const history = useHistory();
  const location = useLocation();
  const dispatch: ThunkDispatch<RootState, undefined, UserStateAction> = useDispatch();
  const [newTenant, setNewTenant] = useState<boolean>(false);

  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  const redirect = useCallback(() => {
    const path = new URLSearchParams(location.search).get('pathname') || '/plannings';
    history.push(path);
  }, [history, location]);

  const handleCreateAndFetchUser = useCallback(async () => {
    await dispatch(createUser());
    await dispatch(fetchUser());
  }, [dispatch]);

  const onCreateTenant = async (props: CreateTenantProps) => {
    try {
      await createTenant(props);
      await handleCreateAndFetchUser();
      redirect();
    } catch (e: any) {
      throw Error(e);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        await getTenant();
        await handleCreateAndFetchUser();
        redirect();
      } catch (e: any) {
        // User create function response is 403 forbidden so the user probably exists already
        if (e.status === 403) {
          redirect();
        }
        if (e.status === 404) {
          setNewTenant(true);
        }
      }
    };

    init();
  }, [dispatch, handleCreateAndFetchUser, redirect]);

  if (newTenant) {
    return (
      <TenantCreator
        onCreateTenant={onCreateTenant}
        companyName={query.get('companyName') || undefined}
        contactPerson={query.get('name') || undefined}
        pricingPlan={query.get('plan') || 'Premium'}
      />
    );
  }

  return null;
}

export default Welcome;
