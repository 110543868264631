import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, FormField, Form, Input } from '../../ui';
import { Location } from '../../global';

type LocationFormInputs = {
  name: string;
};

type Props = {
  location?: Location;
  createLocation?: (location: Pick<Location, 'name'>) => void;
  updateLocation?: (location: Pick<Location, 'name' | 'uuid'>) => void;
};

const LocationForm: React.FC<Props> = ({ location, createLocation, updateLocation }) => {
  const { handleSubmit, errors, register } = useForm<LocationFormInputs>({
    defaultValues: {
      name: location?.name || '',
    },
  });

  const onSubmit = (data: LocationFormInputs) => {
    if (location && updateLocation) {
      updateLocation({ ...data, uuid: location.uuid });
    } else if (createLocation) {
      createLocation(data);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <FormField label="Naam Locatie" errorMessage={errors.name?.message}>
        <Input type="name" name="name" ref={register({ required: true, minLength: 4 })} />
      </FormField>
      <Button.Primary style={{ marginTop: 'auto' }}>Opslaan</Button.Primary>
    </Form>
  );
};

export default LocationForm;
