import React from 'react';
import styled from 'styled-components';

type Props = {
  tabs: string[];
  activeTab: string;
  handleSelect: (tab: string) => void;
};

const Container = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.border};
`;

const SelectorItem = styled.div<{ toggled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  background-color: ${(props) => (props.toggled ? props.theme.colors.primary : props.theme.colors.faded)};
  color: white;
`;

const Selector: React.FC<Props> = ({ tabs, activeTab, handleSelect }) => {
  return (
    <Container>
      {tabs.map((tab, index) => (
        <SelectorItem
          key={index}
          style={{ width: '50%' }}
          toggled={tab === activeTab}
          onClick={() => handleSelect(tab)}
        >
          {tab}
        </SelectorItem>
      ))}
    </Container>
  );
};

export { Selector };
