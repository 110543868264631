import styled from 'styled-components';
import { Heading, Label } from '../../ui';
import Chip from '../../ui/Chip/Chip';
import { Moment } from 'moment';

const ReleaseNoteContainer = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  padding-bottom: 2rem;
  line-height: 1.2;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin: 0.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 1.5rem;
    background: ${(props) => props.theme.colors.faded};
    opacity: 0.5;
  }
  &:after {
    content: '';
    position: absolute;
    top: 1.5rem;
    left: calc(1rem - 1px);
    width: 2px;
    height: calc(100% - 3rem);
    background: ${(props) => props.theme.colors.faded};
    opacity: 0.5;
  }
  .topRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .titleVersion {
    display: flex;
    align-items: center;
    h3 {
      margin-bottom: 0;
      margin-left: 0.5rem;
    }
  }
  .description {
    margin-top: 1rem;
  }

  h4 {
    font-size: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  ul {
    li {
      margin-bottom: 0.25rem;
      padding-left: 1rem;
      position: relative;
      &:before {
        content: '- ';
        position: absolute;
        top: 0;
        left: 0;
        padding-right: 0.5rem;
      }
    }
  }
`;

type Props = {
  version: string;
  date: Moment;
  title: string;
  description: string;
  notes?: string[];
  bugfixes?: string[];
};

export default function ReleaseNote({ version, date, title, description, notes, bugfixes }: Props) {
  return (
    <ReleaseNoteContainer>
      <div className="topRow">
        <div className="titleVersion">
          <Chip title={`v${version}`} />
          <Heading as="h3">{title}</Heading>
        </div>
        <Label type={'muted'}>{date.format('DD-MM-YYYY')}</Label>
      </div>
      {description && description.length > 0 && <div className="description">{description}</div>}
      {notes && notes.length > 0 && (
        <div className="notes">
          <Heading as="h4">Updates</Heading>
          <ul>
            {notes.map((note, index) => (
              <li key={index}>{note}</li>
            ))}
          </ul>
        </div>
      )}
      {bugfixes && bugfixes.length > 0 && (
        <div className="bugfixes">
          <Heading as="h4">Bugfixes</Heading>
          <ul>
            {bugfixes.map((bugfix, index) => (
              <li key={index}>{bugfix}</li>
            ))}
          </ul>
        </div>
      )}
    </ReleaseNoteContainer>
  );
}
