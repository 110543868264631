import React from 'react';
import { Avatar as AvatarType } from '../global';
import { Avatar, Label, Tooltip } from '../ui';
import styled from 'styled-components';

type Rest = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

type Props = Rest & {
  maxLength?: number;
  avatars: AvatarType[];
};

const Additional = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 1rem;
  background: ${(props) => props.theme.colors.disabled};
  color: ${(props) => props.theme.colors.disabledText};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  margin-right: 0.5rem;
`;

const AvatarGroup: React.FC<Props> = ({ avatars, style, maxLength, ...rest }) => {
  const additional = maxLength ? avatars.length - maxLength : 0;

  return (
    <div style={{ ...style, display: 'flex' }} {...rest}>
      {additional <= 0 &&
        avatars.map((avatar, index) => {
          return (
            <Tooltip placement="top" content={<span style={{ whiteSpace: 'nowrap' }}>{avatar.name}</span>} key={index}>
              <Container>
                <Avatar name={avatar.name} highlighted={avatar.highlighted} />
              </Container>
            </Tooltip>
          );
        })}

      {additional > 0 && (
        <>
          {avatars.slice(0, avatars.length - additional).map((avatar, index) => (
            <Tooltip placement="top" content={<span style={{ whiteSpace: 'nowrap' }}>{avatar.name}</span>} key={index}>
              <Container>
                <Avatar name={avatar.name} highlighted={avatar.highlighted} />
              </Container>
            </Tooltip>
          ))}
          <Additional key={avatars.length}>
            <Label>+{additional}</Label>
          </Additional>
        </>
      )}
    </div>
  );
};

export default AvatarGroup;
