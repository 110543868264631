import React, { useEffect, useState } from 'react';
import { RootState } from '../global';
import { useSelector } from 'react-redux';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import NavigationBar from './../components/NavigationBar';
import { Button, Avatar, Label } from '../ui';
import {
  EventAvailable,
  Face,
  CalendarToday,
  Settings,
  Business,
  LocationOn,
  Group,
  HelpOutline,
} from '@styled-icons/material-outlined';
import styled, { css } from 'styled-components';
import useWindowSize from '../utils/useWindowSize';
import isInStandaloneMode from '../utils/isInStandaloneMode';
import Logo from '../components/Logo';
import Help from '../components/Help';
import { releaseNotes } from '../releaseNotes';
import CustomScrollBar from '../components/CustomScrollBar/CustomScrollBar';

const Styled = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6rem 1fr;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;

  ${isInStandaloneMode() &&
  css`
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    touch-action: none;
  `}

  header {
    grid-column: 1 / span 2;
    z-index: 10;
  }

  aside {
    position: absolute;
    top: 6rem;
    left: 0;
    width: 70vw;
    height: calc(100vh - 6rem);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: min(70vw, 16em) minmax(0, 1fr);

    aside {
      top: 0;
      position: fixed;
      width: min(70vw, 16em);
      height: 100%;
      overflow-y: auto;
      grid-row: 1 / span 2;
      grid-column: 1;
    }
    header {
      height: 6rem;
      grid-column: 2;
    }

    main {
      grid-column: 2;
      flex: 1;
      height: calc(100vh - 6rem);
      overflow-y: hidden;
    }
  }
`;

const LogoWrapper = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-left: 0.75rem;
`;

const SideBar = styled.div<{ open: boolean }>`
  height: calc(100vh - 6rem);
  display: flex;
  transform: ${(p) => (p.open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s cubic-bezier(0.45, 0, 0.21, 1);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-right: 1px solid ${(props) => props.theme.colors.border};
  li {
    width: 100%;
    padding: 0.5rem 0;
  }

  @media screen and (min-width: 1024px) {
    height: 100vh;
  }
`;

const ChildWrapper = styled.div<{ open: boolean }>`
  overflow-x: hidden;
  width: 100%;
  transform: ${(p) => (p.open ? 'translateX(70vw)' : 'translateX(0)')};
  transition: transform 0.3s cubic-bezier(0.45, 0, 0.21, 1);
  height: 100%;
`;

const Footer = styled(Button.Minimal)`
  margin-bottom: 0.5rem;
  margin-top: auto;
  justify-content: flex-start;
  text-align: left;

  img {
    width: 3rem;
    height: 3rem;
    border-radius: 8px;
    background-color: gray;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 1rem;
  }
`;

const SettingsLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  width: 100%;
  height: 3.5rem;
  justify-content: flex-start;
  border-radius: 0.75rem;
  color: ${(props) => props.theme.colors.muted};
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;

  ${Label} {
    font-weight: 500;
    color: inherit;
  }

  svg {
    color: inherit;
    margin-right: 1rem;
    margin-left: -0.25rem;
    width: 1.25rem;
    height: 1.25rem;
  }

  &.active {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.onPrimary};

    &:hover {
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`;

type Tab = {
  name: string;
  pathName: string;
  icon: typeof EventAvailable;
};

const menuTabs: Tab[] = [
  {
    name: 'Planningen',
    icon: EventAvailable,
    pathName: '/plannings',
  },
  {
    name: 'Mijn Reserveringen',
    icon: Face,
    pathName: '/reservations',
  },
  {
    name: 'Agenda',
    icon: CalendarToday,
    pathName: '/calendar',
  },
  {
    name: 'Mijn Instellingen',
    icon: Settings,
    pathName: '/settings',
  },
];

const adminMenuTabs: Tab[] = [
  {
    name: 'Bedrijfsinstellingen',
    icon: Business,
    pathName: '/manage/companysettings',
  },
  {
    name: 'Locaties en Ruimtes',
    icon: LocationOn,
    pathName: '/manage/locations',
  },
  {
    name: 'Gebruikers',
    icon: Group,
    pathName: '/manage/usersettings',
  },
];

const Layout: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state);
  const { company } = useSelector((state: RootState) => state.companies);
  const { lastReadUpdate } = useSelector((state: RootState) => state.preferences);

  const [isHelpActive, setIsHelpActive] = useState<boolean>(false);

  const { isMobileDevice } = useWindowSize();

  const [drawerOpen, setDrawerOpen] = useState(() => !isMobileDevice);
  const [showReleaseNoteBadge, setShowReleaseNoteBadge] = useState(false);

  const handleMenuToggle = () => {
    if (isMobileDevice) {
      setDrawerOpen((d) => !d);
    }
  };

  useEffect(() => {
    const lastReleaseNote = releaseNotes.sort((a, b) => {
      if (a.date.isBefore(b.date)) {
        return 1;
      }
      if (a.date.isAfter(b.date)) {
        return -1;
      }
      return 0;
    })[0];

    setShowReleaseNoteBadge(lastReleaseNote.version !== lastReadUpdate);
  }, [lastReadUpdate]);

  React.useEffect(() => {
    if (isMobileDevice) {
      setDrawerOpen(false);
    }
  }, [location.pathname, isMobileDevice]);

  const handleOnBackgroundClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (drawerOpen && isMobileDevice) {
      event.preventDefault();
      setDrawerOpen(false);
    }
  };

  // Set's the page title
  React.useEffect(() => {
    const baseName = 'Deskie';
    const allTabs = [...menuTabs, ...adminMenuTabs];
    const currentTab = allTabs.find((tab) => location.pathname.startsWith(tab.pathName));
    if (currentTab) {
      document.title = `${currentTab.name} | ${baseName}`;
    } else {
      document.title = baseName;
    }
  }, [location.pathname]);

  if (!user.user) return null;

  const renderTabsList = (tabs: Tab[]) => {
    return (
      <>
        {tabs.map((tab) => {
          const Icon = tab.icon;
          return (
            <SettingsLink to={tab.pathName} activeClassName="active" key={tab.name}>
              <Icon size={24} />
              <Label>{tab.name}</Label>
            </SettingsLink>
          );
        })}
      </>
    );
  };

  // TODO: https://stackblitz.com/edit/react-ts-frji5h?file=components%2Fscrollbar%2Findex.tsx

  return (
    <>
      <Styled>
        <header>
          <NavigationBar onMenuToggle={handleMenuToggle} isOpen={isMobileDevice ? drawerOpen : false} />
        </header>
        <aside>
          <SideBar open={drawerOpen || !isMobileDevice}>
            {!isMobileDevice && (
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
            )}
            <nav>
              {renderTabsList(menuTabs)}
              {user.user.roles.includes('admin') && renderTabsList(adminMenuTabs)}
            </nav>
            <SettingsLink to="#" onClick={() => setIsHelpActive(true)} activeClassName="helpActive">
              <HelpOutline size={24} />
              <Label className={showReleaseNoteBadge ? 'badge' : ''}>Over Deskie</Label>
            </SettingsLink>
            <Footer onClick={() => history.push('/settings')}>
              <Avatar name={user.user.name} />
              <div>
                <Label>{user.user.name}</Label>
                {company?.usesCredits && (
                  <Label type="muted" size="small">
                    {user.user.credits} credit(s)
                  </Label>
                )}
              </div>
            </Footer>
          </SideBar>
        </aside>
        <main onClick={handleOnBackgroundClick}>
          <ChildWrapper open={isMobileDevice ? drawerOpen : false}>
            <CustomScrollBar>{children}</CustomScrollBar>
          </ChildWrapper>
        </main>
      </Styled>
      <Help isOpen={isHelpActive} onCancel={() => setIsHelpActive(false)} />
    </>
  );
};

export default Layout;
