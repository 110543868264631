import { Reducer } from 'redux';
import { Reservation } from '../../global';

type FETCH_RESERVATIONS_REQUEST = { type: 'FETCH_RESERVATIONS_REQUEST' };
type FETCH_RESERVATIONS_SUCCESS = { type: 'FETCH_RESERVATIONS_SUCCESS'; payload: Reservation[] };
type FETCH_RESERVATIONS_FAILED = { type: 'FETCH_RESERVATIONS_FAILED' };

export type ReservationsStateAction =
  | FETCH_RESERVATIONS_REQUEST
  | FETCH_RESERVATIONS_SUCCESS
  | FETCH_RESERVATIONS_FAILED;

export type ReservationsState = {
  state: ReservationsNewtorkState;
  reservations: Reservation[];
};

enum ReservationsNewtorkState {
  FETCHING = 'FETCHING',
  ERROR = 'ERROR',
  UPDATING = 'UPDATING',
  SUCCESS = 'SUCCESS',
  IDLE = 'IDLE',
}

const initialState: ReservationsState = {
  state: ReservationsNewtorkState.IDLE,
  reservations: [],
};

const reservationsReducer: Reducer<ReservationsState, ReservationsStateAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_RESERVATIONS_REQUEST':
      return {
        ...state,
        state:
          state.state === ReservationsNewtorkState.IDLE
            ? ReservationsNewtorkState.FETCHING
            : ReservationsNewtorkState.UPDATING,
      };
    case 'FETCH_RESERVATIONS_SUCCESS':
      return {
        ...state,
        state: ReservationsNewtorkState.SUCCESS,
        reservations: action.payload,
      };
    case 'FETCH_RESERVATIONS_FAILED':
      return { ...state, state: ReservationsNewtorkState.ERROR };
    default:
      return state;
  }
};

export { fetchReservations } from './thunks';
export default reservationsReducer;
