import styled from 'styled-components';

export const Content = styled.div`
  & > * + * {
    margin-top: 1rem;
  }

  p {
    line-height: 1.5;
    color: ${(props) => props.theme.colors.label};
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;
