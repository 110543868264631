import { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import ScrollView from '../ScrollView/ScrollView';
import { FilterSearchable } from './Filter';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { Input } from '../Input/Input';
import { Label } from '../Label/Label';

const portalRoot = document.getElementById('portal');

const FilterDropdownHeader = styled.div``;
const FilterDropdownBody = styled.div`
  margin-top: 0.75rem;
`;
const FilterResultItem = styled.div`
  margin-block: 0.75rem;
`;

const FilterDropdown = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackground};
  padding: 1rem;
  box-shadow: 0 0.8px 2.1px rgba(0, 0, 0, 0.02), 0 2.7px 6.9px rgba(0, 0, 0, 0.03), 0 12px 31px rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;

  /* width: MIN(100%, 20rem); */
  width: 100%;

  opacity: 0;
  transform: translateY(-50px);
  transition: 0.15s ease;
`;

const FilterUnderlay = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: 0.15s ease;
`;

const FilterPortal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  overflow-y: hidden;
  align-items: center;

  &.filter-popover-enter-done {
    ${FilterUnderlay} {
      opacity: 1;
    }

    ${FilterDropdown} {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 695px) {
    > .popperContainer {
      width: calc(100% - 2rem);
    }
  }
`;

export type FilterPopOverProps = {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  unit: string;
  items: FilterSearchable[];
  handleSelectOption: (id: any) => void;
  referenceElement: any;
};

export default function FilterPopOver({
  isActive,
  setIsActive,
  unit,
  items,
  handleSelectOption,
  referenceElement,
}: FilterPopOverProps) {
  const [filteredItems, setFilteredItems] = useState<FilterSearchable[] | null>(null);

  const filterItems = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.trim().length === 0) return setFilteredItems(null);

      const value = event.target.value.trim().toLowerCase().replace(/ /g, '');

      const filteredResults = items.filter((item) => {
        return item.value.toLowerCase().indexOf(value) > -1;
      });
      setFilteredItems(filteredResults);
    },
    [items],
  );

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  if (!portalRoot) return null;

  return ReactDOM.createPortal(
    <CSSTransition in={isActive} timeout={150} classNames='filter-popover' unmountOnExit>
      <FilterPortal className='FilterPortal'>
        <FilterUnderlay onClick={() => setIsActive(false)} />
        <div
          className='popperContainer'
          ref={(ref) => setPopperElement(ref)}
          style={styles.popper}
          {...attributes.popper}
        >
          <FilterDropdown>
            <FilterDropdownHeader>
              <Label type='faded' size='small' style={{ marginBottom: '.5rem' }}>
                Selecteer {unit.toLowerCase()}:
              </Label>
              <Input placeholder={`Zoek ${unit.toLowerCase()}...`} onInput={filterItems} autoFocus={true} />
            </FilterDropdownHeader>
            <FilterDropdownBody>
              <ScrollView maxHeight='MAX(10rem, 50vh)'>
                {(filteredItems ?? items).map((item: any, index: any) => (
                  <FilterResultItem key={index} onClick={() => handleSelectOption(item.id)}>
                    {item.children}
                  </FilterResultItem>
                ))}
              </ScrollView>
            </FilterDropdownBody>
          </FilterDropdown>
        </div>
      </FilterPortal>
    </CSSTransition>,
    portalRoot,
  );
}
