import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../global';
import { ValueLabel, Button, IconButton, Drawer, Modal, ElevatedCard, Container } from '../../ui';
import { Edit } from '@styled-icons/material-outlined';
import useWindowSize from '../../utils/useWindowSize';
import { ThunkDispatch } from 'redux-thunk';
import { CompanyStateAction, updateCompany } from '../../state/companies';
import CompanySettingsForm, { EditFormData } from './EditForm';
import { setTitle, setActionButton, LayoutStateAction } from '../../state/layout';

const SettingsBody = styled(ElevatedCard)`
  padding: 2rem;

  & > * + * {
    margin-top: 2rem;
  }

  ${Button.Primary} {
    width: 100%;
  }
`;

const CompanySettings: React.FC = () => {
  const dispatch: ThunkDispatch<RootState, undefined, CompanyStateAction | LayoutStateAction> = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);
  const { company } = useSelector((state: RootState) => state.companies);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { isMobileDevice } = useWindowSize();

  const onSubmit = (data: EditFormData) => {
    dispatch(
      updateCompany({ name: data.name, creditsPerUser: data.usesCredits ? parseInt(data.creditsPerUser) : 365 }),
    );
  };

  React.useEffect(() => {
    dispatch(setTitle('Bedrijfsinstellingen'));
    dispatch(
      setActionButton(
        <IconButton.Minimal onClick={() => setIsOpen(true)}>
          <Edit />
          <span className="visually-hidden">Bedrijfsinstellingen bewerken</span>
        </IconButton.Minimal>,
      ),
    );
  }, [dispatch, setIsOpen]);

  if (!user || !company) return null;

  const Element = isMobileDevice ? Drawer : Modal;

  return (
    <>
      <Element isOpen={isOpen} onCancel={() => setIsOpen(false)} title="Bedrijfsinstellingen">
        <CompanySettingsForm
          onSubmit={onSubmit}
          usesCredits={company.usesCredits}
          name={company.name}
          creditsPerUser={company.creditsPerUser?.toString() || '2'}
        />
      </Element>

      <Container>
        <SettingsBody>
          <ValueLabel label="Bedrijfsnaam" value={company.name} />
          {company.usesCredits && (
            <ValueLabel
              label="Standaard credits per medewerker"
              value={company.creditsPerUser?.toString() || 'Nog niet bepaald'}
            />
          )}
        </SettingsBody>
      </Container>
    </>
  );
};

export default CompanySettings;
