import React from 'react';
import styled from 'styled-components';
import { Label } from '../ui';
import { Info } from '@styled-icons/material-outlined';

const Styled = styled.div`
  display: flex;

  svg {
    color: ${(props) => props.theme.colors.muted};
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
    margin-top: 0.125rem;
  }
`;

type Props = {
  text?: string;
};

const ReservationInfo: React.FC<Props> = ({ text, children }) => {
  return (
    <Styled>
      <Info />
      <Label type="muted" size="small">
        {children || text}
      </Label>
    </Styled>
  );
};

export default ReservationInfo;
