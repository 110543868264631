import { useSelector } from 'react-redux';
import AndroidInstallProvider from './AndroidInstallProvider';
import IosInstallProvider from './IosInstallProvider';
import { RootState } from '../../global';

type Props = {
  children: React.ReactNode;
};

export default function PwaInstallProvider({ children }: Props) {
  const { user } = useSelector((state: RootState) => state.user);

  const checkPlatform = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    const isIos = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1;
    const isMobile = isAndroid || isIos;
    return { isAndroid, isIos, isMobile };
  };

  // Checks if the PWA is already installed
  const checkStandalone = () => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    return isStandalone;
  };

  const setShowPreference = (value = 'false') => {
    localStorage.setItem('showPwaInstall', value);
  };

  const getAllowShow = () => {
    const showPreference = localStorage.getItem('showPwaInstall');
    return !(showPreference === 'false');
  };

  const platform = checkPlatform();

  // Don't present PWA prompt on desktop, or if already installed
  if (checkStandalone() || !checkPlatform().isMobile || !getAllowShow()) {
    return <>{children}</>;
  }

  if (platform.isAndroid) {
    return (
      <AndroidInstallProvider allowShow={user !== null} onUpdatePreference={setShowPreference}>
        {children}
      </AndroidInstallProvider>
    );
  }

  if (platform.isIos) {
    return (
      <IosInstallProvider allowShow={user !== null} onUpdatePreference={setShowPreference}>
        {children}
      </IosInstallProvider>
    );
  }

  return <>{children}</>;
}
