import { CalendarToday } from '@styled-icons/material-outlined/CalendarToday';
import moment from 'moment';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FormField } from '../../ui';

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 3.5rem;
  padding: 0 0 0 1rem;
  font: inherit;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 0.5rem;
  box-shadow: none;
  background: ${(props) => props.theme.colors.inputBackground};
  -webkit-appearance: none;
  cursor: pointer;
  input {
    color: ${(props) => props.theme.colors.label};
    font: inherit;
    background: transparent;
  }

  &:has(input:focus) {
    border-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.colors.primary};
    outline: 0;
  }
`;

const ShadowInput = styled.input`
  width: 100%;
  border: none;
  &:focus {
    outline: none;
  }
`;

const ShadowDateInputToggle = styled.span`
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  padding: 10px 1rem 10px 10px;

  .toggleButton {
    height: 100%;
    display: flex;
    pointer-events: none;
  }

  input[type='date'] {
    position: absolute;
    opacity: 0;
    visibility: none;
    width: 50px;
    height: 50px;
    top: 0;
    left: 0;
    cursor: pointer;
    pointer-events: all;

    ::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }

    @-moz-document url-prefix() {
      padding: 10px;
    }
  }
`;

type DateInputFieldProps = {
  message: string | undefined;
  label: string;
  dateChangeCallBack: any;
  value: Moment;
};

export function DateInputField({ message, label, dateChangeCallBack, value }: DateInputFieldProps) {
  const [selectedDate, setSelectedDate] = useState(value);
  // const [oldDate, setOldDate] = useState(value);

  const [dateInput, setDateInput] = useState(value.format('YYYY-MM-DD'));
  const [textInput, setTextInput] = useState(value.format('DD-MM-YYYY'));

  const handleDateInputChanged = (e: any) => {
    const val: string = e.target.value;
    const parsedValue = moment(val, 'YYYY-MM-DD');

    if (!parsedValue.isValid()) {
      // Handle invalid date.
      toast.error("Ongeldige datum. Vul in als 'dd-mm-jjjj'");
      setDateInput(selectedDate.format('YYYY-MM-DD'));
      return;
    }

    if (parsedValue.isBefore(moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY'))) {
      // Handle invalid date.
      toast.error('De geselecteerde datum mag niet in het verleden liggen.');
      setDateInput(selectedDate.format('YYYY-MM-DD'));
      return;
    }

    setSelectedDate(parsedValue);
  };

  const handleTextInputChanged = (e: any, onBlur: boolean = false) => {
    const val: string = e.target.value;

    if (onBlur) {
      // Validate value.
      const parsedValue = moment(val, 'DD-MM-YYYY');

      if (!parsedValue.isValid()) {
        // Handle invalid date.
        toast.error("Ongeldige datum. Vul in als 'dd-mm-jjjj'");
        setTextInput(selectedDate.format('DD-MM-YYYY'));
        return;
      }

      if (parsedValue.isBefore(moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY'))) {
        // Handle invalid date.
        toast.error('De geselecteerde datum mag niet in het verleden liggen.');
        setTextInput(selectedDate.format('DD-MM-YYYY'));
        return;
      }

      setSelectedDate(parsedValue);
    } else {
      // Treat as free textinput to not interrupt user input.
      setTextInput(val);
    }
  };

  useEffect(() => {
    setDateInput(selectedDate.format('YYYY-MM-DD'));
    setTextInput(selectedDate.format('DD-MM-YYYY'));

    dateChangeCallBack(selectedDate.format('YYYY-MM-DD'));
  }, [selectedDate, dateChangeCallBack]);

  return (
    <FormField errorMessage={message} label={label}>
      <InputWrapper>
        <ShadowInput
          name="dateInputText"
          type="text"
          value={textInput}
          onChange={handleTextInputChanged}
          onBlur={(e) => handleTextInputChanged(e, true)}
          tabIndex={0}
        />
        <ShadowDateInputToggle>
          <div className="toggleButton" tabIndex={0}>
            <CalendarToday />
            <span className="visually-hidden">Datum selecteren</span>
          </div>
          <input
            name="dateInput"
            min={moment().format('YYYY-MM-DD')}
            type={'date'}
            value={dateInput}
            onChange={handleDateInputChanged}
            tabIndex={-1}
          />
        </ShadowDateInputToggle>
      </InputWrapper>
    </FormField>
  );
}
