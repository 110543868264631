import { Reducer } from 'redux';
import { Company } from '../../global';

type FETCH_COMPANY_REQUEST = { type: 'FETCH_COMPANY_REQUEST' };
type FETCH_COMPANY_SUCCESS = { type: 'FETCH_COMPANY_SUCCESS'; payload: Company };
type FETCH_COMPANY_FAILED = { type: 'FETCH_COMPANY_FAILED' };

export type CompanyStateAction = FETCH_COMPANY_REQUEST | FETCH_COMPANY_SUCCESS | FETCH_COMPANY_FAILED;

export type CompanyState = {
  loading: boolean;
  success: boolean;
  error: boolean;
  company: Company | null;
};

const initialState: CompanyState = {
  loading: true,
  success: false,
  error: false,
  company: null,
};

const companyReducer: Reducer<CompanyState, CompanyStateAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_COMPANY_REQUEST':
      return { ...state, loading: true, success: false, error: false };
    case 'FETCH_COMPANY_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        company: action.payload,
      };
    case 'FETCH_COMPANY_FAILED':
      return { ...state, loading: false, success: false, error: true };
    default:
      return state;
  }
};

export { fetchCompany, updateCompany } from './thunks';
export default companyReducer;
