import styled, { css } from 'styled-components';

const Base = styled.button`
  font: inherit;
  border-radius: 1rem;
  border: 1px solid transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  margin: 0;
  cursor: pointer;
  height: 3.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: 0.15s ease;
  position: relative;
  text-decoration: none;

  svg {
    width: auto;
    height: 1.5rem;
  }

  & > * + * {
    margin-left: 0.25rem;
  }
`;

const Primary = styled(Base)<{ isLoading?: boolean }>`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.onPrimary};

  &:hover {
    background: ${(props) => props.theme.colors.primaryDarker};
  }

  ${(props) =>
    props.isLoading &&
    css`
      color: transparent !important;

      &:after {
        content: '';
        display: flex;
        position: absolute;
        width: 1.375rem;
        height: 1.375rem;
        border: 0.1875rem solid rgba(255, 255, 255, 0.2);
        border-top: 0.1875rem solid rgba(255, 255, 255, 1);
        border-radius: 50%;
        animation: spin 1.5s cubic-bezier(0.46, 0.09, 0.54, 0.9) infinite;
      }
    `}

  &:disabled {
    background: ${(props) => props.theme.colors.disabled};
    color: ${(props) => props.theme.colors.disabledText};
    cursor: not-allowed;
  }
`;

const Minimal = styled(Base)`
  background: transparent;
  color: ${(props) => props.theme.colors.label};

  &:hover {
    background: ${(props) => props.theme.colors.background};
  }

  &:disabled {
    color: ${(props) => props.theme.colors.disabledText};
    cursor: not-allowed;
  }
`;

const Destructive = styled(Base)`
  background: ${(props) => props.theme.colors.error};
  color: white;

  &:hover {
    background: #b95f55;
  }

  &:disabled {
    background: ${(props) => props.theme.colors.disabled};
    color: ${(props) => props.theme.colors.disabledText};
    cursor: not-allowed;
  }
`;

const Button = {
  Primary,
  Minimal,
  Destructive,
};

const IconPrimary = styled(Primary)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
`;

const IconMinimal = styled(Minimal)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
`;

const IconDestructive = styled(Destructive)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
`;

const IconButton = {
  Primary: IconPrimary,
  Minimal: IconMinimal,
  Destructive: IconDestructive,
};

export { Button, IconButton };
