import { useState, FormEvent } from 'react';
import { Container, Heading, Input, Form, FormField, Button, ElevatedCard, Steps, Switch } from '../ui';
import Logo from './Logo';
import PricingPlan from './PricingPlan';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { PricingPlan as PricingPlanType } from '../global';
import { CreateTenantProps } from '../state/tenants/thunks';
import { Check } from '@styled-icons/material-outlined';

type TenantCreatorProps = {
  companyName?: string;
  contactPerson?: string;
  pricingPlan?: string;
  onCreateTenant: (props: CreateTenantProps) => void;
};

type TenantFormData = {
  companyName: string;
  contactPerson: string;
  street: string;
  postalCode: string;
  city: string;
  chamberOfCommerceNumber: string;
  vatNumber?: string;
  invoicingEmail: string;
  pricingPlan: PricingPlanType;
};

const StyledTenantCreator = styled.div`
  padding-bottom: 2rem;

  .container {
    width: min(64rem, 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .steps {
    margin-top: 2rem;
    margin-bottom: 4rem;
    width: min(20rem, 100%);
  }

  .pricing {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
    gap: 2rem;
  }

  .yearly {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: -2rem;
  }
  .summary {
    & > * + * {
      margin-top: 2rem;
    }
  }

  .summary-plan {
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
      font-weight: 600;
    }

    & > *:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > span {
        color: ${(props) => props.theme.colors.muted};
        font-size: 0.75rem;
        margin-top: 0.5rem;
      }
    }

    @media screen and (max-width: 560px) {
      flex-direction: column;
      align-items: flex-start;

      & > *:last-child {
        margin-top: 1.5rem;
        color: ${(props) => props.theme.colors.primary};
        align-items: flex-start;
      }
    }
  }

  .summary-banner {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 500;
    display: flex;
    margin-top: 2rem;
    align-items: center;

    svg {
      width: 1.25rem;
      margin-right: 0.5rem;
    }
  }

  .summary-footer {
    margin: 2rem 0 0;
  }

  .formField--combined {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    gap: 1.5rem;

    @media screen and (max-width: 680px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
`;

const Header = styled.header`
  padding: 2rem 1.5rem;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  display: flex;
  justify-content: center;

  .logo {
    position: absolute;
    left: 1.5rem;
  }

  @media screen and (max-width: 680px) {
    .logo {
      display: none;
    }
  }
`;

const Card = styled(ElevatedCard)`
  width: min(40rem, 100%);
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;

  button {
    min-width: 10rem;
  }

  ${Button.Primary} {
    margin-left: auto;
  }

  ${Button.Minimal} {
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    margin-right: auto;
  }

  @media screen and (max-width: 560px) {
    flex-direction: column-reverse;

    & > * + * {
      margin-bottom: 1rem;
    }

    button {
      width: 100%;
    }
  }
`;

const title = ['Kies jouw Deskie abonnement', 'Vul je betaalgegevens in', 'Overzicht'];

/* TODO Place this somewhere else: */
const plans = {
  Free: {
    title: 'Starter',
    cost: 'Gratis',
    costYearly: 'Gratis',
    users: '0 - 10 gebruikers',
    bullets: [],
  },
  Premium: {
    title: 'Professional',
    cost: '€ 2,99 per maand',
    costYearly: '€ 1,99 per maand',
    users: 'Vanaf 10 gebruikers',
    bullets: [],
  },
  Business: {
    title: 'Enterprise',
    cost: '',
    costYearly: '',
    text: 'Meer dan 500 gebruikers? Neem contact op met Deskie Support.',
    users: '500+ gebruikers',
    bullets: [],
  },
};

function TenantCreator({ pricingPlan, companyName, contactPerson, onCreateTenant }: TenantCreatorProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [yearly, setYearly] = useState<boolean>(true);
  const [step, setStep] = useState<number>(1);

  const _step = step > 3 ? 3 : step < 2 ? 1 : step;

  const { register, handleSubmit, errors, control, watch } = useForm<TenantFormData>({
    defaultValues: {
      companyName: companyName,
      contactPerson: contactPerson,
      pricingPlan: pricingPlan === 'Free' ? 'Free' : pricingPlan === 'Business' ? 'Business' : 'Premium',
    },
  });

  const fields = watch(['pricingPlan']);

  const handleNext = () => {
    setStep(_step + 1);
  };

  const handlePrevious = () => {
    setStep(_step - 1);
  };

  const onSubmit = (data: TenantFormData) => {
    if (_step === 3) {
      setIsLoading(true);
      onCreateTenant({
        name: data.companyName,
        pricingPlan: data.pricingPlan,
        details: {
          address: {
            city: data.city,
            street: data.street,
            postalCode: data.postalCode,
          },
          vatNumber: data.vatNumber,
          chamberOfCommerceNumber: data.chamberOfCommerceNumber,
          invoicePeriod: yearly ? 'Year' : 'Month',
          invoicingEmail: data.invoicingEmail,
          contactPerson: data.contactPerson,
        },
      });
    } else {
      handleNext();
    }
  };

  const handleSubmitBeforeLastStep = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
    handleNext();
  };

  return (
    <StyledTenantCreator>
      <Header>
        <Logo />
        <Heading>{title[_step - 1]}</Heading>
      </Header>
      <Container className="container">
        <Steps amount={3} current={_step} />
        <Form onSubmit={_step > 1 ? handleSubmit(onSubmit) : handleSubmitBeforeLastStep} isLoading={isLoading}>
          <div style={{ display: _step === 1 ? 'block' : 'none' }}>
            <div className="yearly">
              <Switch
                name="invoicePeriod"
                checked={yearly}
                onChange={(e) => setYearly(e.target.checked)}
                label="Ik betaal jaarlijks"
              />
            </div>
            <Controller
              control={control}
              name="pricingPlan"
              render={({ value, onChange }) => (
                <div className="pricing">
                  {Object.entries(plans).map(([key, plan], index) => (
                    <PricingPlan
                      key={index}
                      isSelected={value === key}
                      isSelectable={key !== 'Business'}
                      isHighlighted={key === 'Premium'}
                      showNote={key === 'Premium'}
                      title={plan.title}
                      bullets={key === 'Business' ? undefined : [plan.users, ...plan.bullets]}
                      text={
                        key === 'Business' ? 'Meer dan 500 gebruikers? Neem contact op met Deskie Support.' : undefined
                      }
                      cost={yearly ? plan.costYearly : plan.cost}
                      onClick={() => (key === 'Business' ? (window.location.href = '/contact') : onChange(key))}
                      isTryable={key !== 'Business'}
                    />
                  ))}
                </div>
              )}
            />
          </div>
          <Card style={{ display: _step === 2 ? 'block' : 'none' }}>
            <FormField label="Contactpersoon" errorMessage={errors.contactPerson?.message}>
              <Input name="contactPerson" ref={register({ required: true })} />
            </FormField>
            <FormField label="Bedrijfsnaam" errorMessage={errors.companyName?.message}>
              <Input name="companyName" ref={register({ required: true })} />
            </FormField>
            <FormField label="E-mailadres voor facturen" errorMessage={errors.invoicingEmail?.message}>
              <Input name="invoicingEmail" ref={register({ required: true, pattern: /^\S+@\S+$/i })} />
            </FormField>
            <FormField label="Straat + Huisnummer" errorMessage={errors.street?.message}>
              <Input name="street" ref={register({ required: true })} />
            </FormField>
            <div className="formField--combined">
              <FormField label="Postcode (1234 AB)" errorMessage={errors.postalCode?.message}>
                <Input
                  name="postalCode"
                  ref={register({ required: true, pattern: /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i })}
                />
              </FormField>
              <FormField label="Plaats" errorMessage={errors.city?.message}>
                <Input name="city" ref={register({ required: true })} />
              </FormField>
            </div>
            <FormField label="Btw-nummer" errorMessage={errors.vatNumber?.message}>
              <Input name="vatNumber" ref={register()} />
            </FormField>
            <FormField label="Kvk-nummer" errorMessage={errors.chamberOfCommerceNumber?.message}>
              <Input name="chamberOfCommerceNumber" ref={register()} />
            </FormField>
          </Card>

          <div style={{ display: _step === 3 ? 'block' : 'none' }}>
            <Card className="summary">
              <Heading>Samenvatting</Heading>
              {fields.pricingPlan !== 'Free' && (
                <div className="summary-banner">
                  <Check />
                  Eerste 30 dagen gratis uitproberen
                </div>
              )}
              <div className="summary-plan">
                <span>
                  <strong>{plans[fields.pricingPlan].title}</strong> ({plans[fields.pricingPlan].users})
                </span>
                <span>
                  {yearly ? plans[fields.pricingPlan].costYearly : plans[fields.pricingPlan].cost}
                  {fields.pricingPlan !== 'Free' && <span>Per gebruiker (excl. BTW)</span>}
                </span>
              </div>

              {fields.pricingPlan !== 'Free' && (
                <div className="summary-footer">
                  <Switch
                    name="invoicePeriod"
                    checked={yearly}
                    onChange={(e) => setYearly(e.target.checked)}
                    label="Ik betaal jaarlijks"
                  />
                </div>
              )}
            </Card>
          </div>
          <ButtonGroup style={{ width: _step > 1 ? 'min(40rem, 100%)' : '100%' }}>
            {_step > 1 && (
              <Button.Minimal type="button" onClick={handlePrevious}>
                Vorige
              </Button.Minimal>
            )}
            <Button.Primary type="submit">{_step === 3 ? 'Bevestig abonnement' : 'Volgende'}</Button.Primary>
          </ButtonGroup>
        </Form>
      </Container>
    </StyledTenantCreator>
  );
}

export default TenantCreator;
