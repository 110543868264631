import { Dictionary, uniqBy } from 'lodash';
import moment, { Moment } from 'moment';
import '@devmen/moment-feestdagen';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ReservationCard from '../../components/ReservationCard/ReservationCard';
import { Office, Planning, PlanningUser, RootState } from '../../global';
import { Heading } from '../../ui';
import { datesGroupByComponent, isWeekend } from '../../utils/calendar';

const ReservationCards = styled.ul`
  display: grid;
  gap: 3rem;
  margin-top: 1rem;

  list-style: none;

  h3 {
    margin-bottom: 1.5rem;

    span {
      margin-left: 1ch;
    }
  }
`;

const ReservationsWeek = styled.ul`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));
`;

type Props = {
  dates: Moment[];
  indexedPlannings: Dictionary<Planning[]>;
  offices: Office[];
  onSelect: (date: Moment, officeUuid: string) => void;
};

const ReservationList: React.FC<Props> = ({ dates, indexedPlannings, offices, onSelect }) => {
  const { user } = useSelector((state: RootState) => state.user);
  const { locations } = useSelector((state: RootState) => state.locations);
  const { filters } = useSelector((state: RootState) => state.planningFilters);

  if (!user) return null;

  const weeks = datesGroupByComponent(dates, 'YYYY_W');

  const renderWeek = (dates: Moment[]) => {
    let hasContent = false;
    const week = dates.map((date) => {
      if (isWeekend(date)) return null;

      return (
        <React.Fragment key={date.format()}>
          {offices.map((office) => {
            // Not good, working on a better solution.
            const key = Object.keys(indexedPlannings).find((p) => moment(p).isSame(date, 'date'));
            const dayParts = (key ? indexedPlannings[key] : []).filter((d) => d.officeUuid === office.uuid);
            const slotsFilled = dayParts.reduce<number>((a, b) => a + b.signedInUsers.length, 0);
            const users = uniqBy(
              dayParts.reduce<PlanningUser[]>((a, b) => [...a, ...b.signedInUsers], []),
              'uuid',
            );
            const hasCurrentUser = users.findIndex((userMatch) => userMatch.uuid === user.uuid) !== -1;
            const hasCurrentUserPerDayPart = dayParts.reduce<{ 0: boolean; 1: boolean }>(
              (a, b) => {
                if (b.signedInUsers.findIndex((userMatch) => userMatch.uuid === user.uuid) !== -1) {
                  return { ...a, [b.dayPart]: true };
                }
                return a;
              },
              { 0: false, 1: false },
            );

            if (filters?.hasOwnProperty('user')) {
              if (!users.some((user) => user.uuid === filters.user)) {
                return null;
              } else {
                hasContent = true;
              }
            } else {
              hasContent = true;
            }

            const planningTitle =
              hasCurrentUserPerDayPart[0] === true && hasCurrentUserPerDayPart[1] === true
                ? 'Hele dag'
                : hasCurrentUserPerDayPart[0] === true
                ? '08:00 - 13:00'
                : hasCurrentUserPerDayPart[1] === true
                ? '13:00 - 18:00'
                : undefined;

            const title = date.year() === moment().year() ? date.format('dd DD MMMM') : date.format('dd DD MMMM YYYY');

            const subtitle =
              locations.length > 1
                ? `${office.name} (${locations.find((l) => l.uuid === office.locationUuid)?.name})`
                : `${office.name}`;

            return (
              <ReservationCard
                slots={office.seats}
                slotsFilled={slotsFilled / 2}
                title={title}
                users={users}
                highlighted={hasCurrentUser}
                planningTitle={planningTitle}
                onClick={() => onSelect(date, office.uuid)}
                key={office.uuid}
                subtitle={subtitle}
                holidays={date.isDutchHoliday(null, false)}
                asListItem={true}
              />
            );
          })}
        </React.Fragment>
      );
    });

    return hasContent ? week : null;
  };

  return (
    <ReservationCards>
      {Object.entries(weeks).map(([key, dates]) => {
        const week = renderWeek(dates);
        if (!week) return null;

        return (
          <li
            key={key}
            title={`Week ${dates[0].format('W')}`}
            data-week={key}
            data-month={dates[0].format('YYYY_M')}
            style={{ minHeight: '100px' }}
          >
            <Heading as="h3" type="md">
              Week {dates[0].format('W')}
              {!dates[0].isSame(moment(), 'year') && <span>{dates[0].format('YYYY')}</span>}
            </Heading>
            <ReservationsWeek>{week}</ReservationsWeek>
          </li>
        );
      })}
    </ReservationCards>
  );
};

export default ReservationList;
