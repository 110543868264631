import { Reducer } from 'redux';
import { Location } from '../../global';

type FETCH_LOCATIONS_REQUEST = { type: 'FETCH_LOCATIONS_REQUEST' };
type FETCH_LOCATIONS_SUCCESS = { type: 'FETCH_LOCATIONS_SUCCESS'; payload: Location[] };
type FETCH_LOCATIONS_FAILED = { type: 'FETCH_LOCATIONS_FAILED' };

export type LocationsStateAction = FETCH_LOCATIONS_REQUEST | FETCH_LOCATIONS_SUCCESS | FETCH_LOCATIONS_FAILED;

export type LocationsState = {
  loading: boolean;
  success: boolean;
  error: boolean;
  locations: Location[];
};

const initialState: LocationsState = {
  loading: true,
  success: false,
  error: false,
  locations: [],
};

const locationsReducer: Reducer<LocationsState, LocationsStateAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_LOCATIONS_REQUEST':
      return { ...state, loading: true, success: false, error: false };
    case 'FETCH_LOCATIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        locations: action.payload,
      };
    case 'FETCH_LOCATIONS_FAILED':
      return { ...state, loading: false, success: false, error: true };
    default:
      return state;
  }
};

export { fetchLocations, createLocation, updateLocation, deleteLocation, createOffice } from './thunks';
export default locationsReducer;
