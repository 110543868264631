import { Reducer } from 'redux';

type UPDATE_FILTERS = { type: 'UPDATE_FILTERS'; payload: { filters: PlanningFilters } };
type CLEAR_FILTERS = { type: 'CLEAR_FILTERS' };
type UNSET_FILTER_KEY = { type: 'UNSET_FILTER_KEY'; payload: { key: string } };

export type PlanningFilters = {
  [key: string]: string | number | undefined;
};

export type PlanningFiltersStateAction = UPDATE_FILTERS | CLEAR_FILTERS | UNSET_FILTER_KEY;

export type PlanningFiltersState = {
  filters: PlanningFilters;
};

const initialState: PlanningFiltersState = {
  filters: {},
};

const planningFiltersReducer: Reducer<PlanningFiltersState, PlanningFiltersStateAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'UPDATE_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload.filters,
        },
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        filters: {},
      };
    case 'UNSET_FILTER_KEY':
      const filtersCopy = { ...state.filters };
      delete filtersCopy[action.payload.key];
      return {
        ...state,
        filters: { ...filtersCopy },
      };
    default:
      return state;
  }
};

export default planningFiltersReducer;
