import { ChevronDown, Close } from '@styled-icons/ionicons-outline';
import styled from 'styled-components';
import { ReactElement, useEffect, useState } from 'react';
import FilterPopOver from './FilterPopover';

const FilterToggle = styled.button`
  border: none;
  background: none;
  padding: 0;
  position: relative;
`;

const FilterToggleBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 1rem;
  border: 2px solid ${(props) => props.theme.colors.border};

  font-family: ${(props) => props.theme.fonts.body};
  font-weight: 500;
  border-radius: 0.75rem;
  cursor: pointer;
  height: 2.25rem;
  color: ${(props) => props.theme.colors.label};

  &.hasValue {
    background: ${(props) => props.theme.colors.label};
    color: ${(props) => props.theme.colors.onLabel};
    border-color: ${(props) => props.theme.colors.label};
  }

  svg {
    margin-left: 2rem;
    width: 1.5rem;
    height: 1.5rem;
    * {
      stroke: ${(props) => props.theme.colors.label};
    }
  }

  &.hasValue {
    svg {
      * {
        stroke: ${(props) => props.theme.colors.onLabel};
      }
    }
  }
`;

export type FilterSearchable = {
  children: Element | ReactElement | null;
  id: any;
  value: string;
};

export type Props = {
  filterName?: string;
  unit?: string;
  items?: FilterSearchable[] | undefined;
  handleAppliedFilter?: (id: string | number | null) => void;
  selected?: string | number;
};

const Filter = ({
  filterName = 'FilterName',
  unit = '',
  items = [],
  handleAppliedFilter = () => {},
  selected,
}: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | number | null>(null);

  // Popper for positioning
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);

  const handleSelectOption = (id: string | number) => {
    if (id) {
      setSelectedOption(id);
    }
    handleAppliedFilter(id ?? null);
    setIsActive(false);
  };

  useEffect(() => {
    if (selected) {
      setSelectedOption(selected);
    }
  }, [selected]);

  return (
    <FilterToggle>
      <div ref={(ref) => setReferenceElement(ref)}>
        <FilterToggleBody onClick={() => setIsActive(!isActive)} className={selectedOption ? 'hasValue' : ''}>
          {selectedOption && items !== undefined
            ? items.find((search) => search.id === selectedOption)?.value
            : filterName}{' '}
          {selectedOption == null ? (
            <ChevronDown />
          ) : (
            <Close
              onClick={(e) => {
                e.stopPropagation();
                setSelectedOption(null);
                handleAppliedFilter(null);
              }}
            />
          )}
        </FilterToggleBody>
      </div>

      <FilterPopOver
        isActive={isActive}
        setIsActive={setIsActive}
        unit={unit}
        items={items}
        handleSelectOption={handleSelectOption}
        referenceElement={referenceElement}
      />
    </FilterToggle>
  );
};

export default Filter;
