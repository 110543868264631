import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { RootState } from '../global';
import { lightTheme, darkTheme } from '../ui/themes';

type Props = {
  children: React.ReactNode;
};

export default function CustomThemeProvider({ children }: Props) {
  const [theme, setTheme] = useState(lightTheme);
  const { appearance } = useSelector((state: RootState) => state.preferences);

  useEffect(() => {
    const colorSchemePreference = window.matchMedia('(prefers-color-scheme: dark)');

    const setThemeBasedOnSystemPreference = () => {
      switch (appearance) {
        case 'dark':
          setTheme(darkTheme);
          break;
        case 'light':
          setTheme(lightTheme);
          break;
        case 'system':
          setTheme(colorSchemePreference.matches ? darkTheme : lightTheme);
          break;
      }
    };

    colorSchemePreference.addEventListener('change', setThemeBasedOnSystemPreference);
    setThemeBasedOnSystemPreference();
    return () => {
      colorSchemePreference.removeEventListener('change', setThemeBasedOnSystemPreference);
    };
  }, [appearance]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
