import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Planning, RootState } from '../global';
import moment from 'moment';
import { fetchReservations } from '../state/reservations';
import { DayPart } from '../enums';
import { useHistory } from 'react-router-dom';
import { SimpleLink } from '../ui/SimpleLink';

/**
 * This hook fetches all bookings (for all locations) for a user on
 * a given date. The date is derived from the Redux store. It
 * excludes all bookings made for the currently selected office.
 *
 * Also returns a method (getOccupiedMessage) that returns a string
 * that can be used to give feedback to the user as to why some
 * options may be unavailable.
 */

export interface Occupancy {
  scheduleClear: boolean;
  morning: Planning | null;
  midday: Planning | null;
}

export const useCalendarAvailability = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Date to check for.
  const [occupancy, setOccupancy] = useState<Occupancy>();

  // All active reservations by user.
  const { reservations, state } = useSelector((state: RootState) => state.reservations);
  const { user } = useSelector((state: RootState) => state.user);
  const {
    planningPopup: { date, officeUuid },
  } = useSelector((state: RootState) => state);

  // Fetch reservations if not available.
  useEffect(() => {
    if (user && ['IDLE'].includes(state)) {
      dispatch(fetchReservations({ userUuid: user.uuid }));
    }
  }, [user, reservations, dispatch, state]);

  // Check for all reservations by user for selectedDate.
  useEffect(() => {
    // Assert if selectedDate is valid
    if (!date.isValid()) {
      return;
    }

    // Find reservations with date.
    const formattedSelectedDate = date.format('DD-MM-Y');
    const reservationsOnDate = reservations.filter(
      (planningItem) =>
        formattedSelectedDate === moment(planningItem.date).format('DD-MM-Y') && planningItem.officeUuid !== officeUuid,
    );

    const morning = reservationsOnDate.filter((reservation) => reservation.dayPart === DayPart.Morning);
    const midday = reservationsOnDate.filter((reservation) => reservation.dayPart === DayPart.Midday);

    // Return an object with the occupancy for morning, midday and fullDay.
    setOccupancy({
      scheduleClear: morning.length === 0 && midday.length === 0,
      morning: morning.length > 0 ? (morning.shift() as Planning) : null,
      midday: midday.length > 0 ? (midday.shift() as Planning) : null,
    });
  }, [date, reservations, officeUuid]);

  const getOccupiedMessage = () => {
    if (occupancy?.scheduleClear ?? true) return null;

    const locations: string[] = [];
    const timeIndications: string[] = [];

    if (occupancy?.morning !== null) {
      locations.push(`${occupancy?.morning.locationName} in ${occupancy?.morning.officeName}`);
      timeIndications.push(`'s ochtends`);
    }
    if (occupancy?.midday !== null) {
      locations.push(`${occupancy?.midday.locationName} in ${occupancy?.midday.officeName}`);
      timeIndications.push(`'s middags`);
    }

    const link = (
      <SimpleLink
        tabIndex={0}
        onClick={() => {
          history.push('/reservations');
          dispatch({ type: 'CLOSE_PLANNING_POPUP' });
        }}
      >
        mijn reserveringen
      </SimpleLink>
    );

    return (
      <>
        {locations.length > 0
          ? `Op deze datum heb je ${timeIndications[0]} al een reservering bij ${locations[0]}.
                    ${locations.length > 1 ? `en ${timeIndications[1]} bij ${locations[1]}.` : ``}`
          : ``}{' '}
        <span>Ga naar {link} om je reserveringen te beheren.</span>
      </>
    );
  };

  return { occupancy, getOccupiedMessage };
};
