import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 64em) {
    padding: 1rem;
  }
`;
