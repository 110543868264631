import React, { useCallback, useEffect } from 'react';

export const useInfiniteScroll = (
  scrollRef: React.MutableRefObject<HTMLDivElement | null>,
  onIntersect: () => void,
) => {
  const observer = React.useRef<IntersectionObserver | null>(null);

  const scrollObserver = useCallback(
    (node) => {
      observer.current = new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0) {
            onIntersect();
          }
        });
      });
      observer.current.observe(node);
    },
    [onIntersect],
  );

  useEffect(() => {
    if (scrollRef.current) {
      scrollObserver(scrollRef.current);

      return () => {
        observer.current?.disconnect();
      };
    }
  }, [scrollObserver, scrollRef]);
};

export default useInfiniteScroll;
