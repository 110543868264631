import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Office, RootState } from '../../global';
import { Button, FormField, Form, Input, Select } from '../../ui';

type OfficeFormInputs = {
  name: string;
  seats: string;
  locationUuid: string;
};

type Props = {
  office?: Office;
  updateOffice?: (locationUuid: string, office: Pick<Office, 'name' | 'seats' | 'uuid' | 'isActive'>) => void;
  createOffice?: (locationUuid: string, office: Pick<Office, 'name' | 'seats'>) => void;
};

const OfficeForm: React.FC<Props> = ({ office, updateOffice, createOffice }) => {
  const locations = useSelector((state: RootState) => state.locations.locations);
  const { handleSubmit, errors, register } = useForm<OfficeFormInputs>({
    defaultValues: {
      locationUuid: office?.locationUuid || locations[0]?.uuid || '',
      name: office?.name || '',
      seats: office?.seats.toString() || '',
    },
  });

  const onSubmit = (data: OfficeFormInputs) => {
    if (office && updateOffice) {
      updateOffice(data.locationUuid, {
        name: data.name,
        seats: parseInt(data.seats),
        uuid: office.uuid,
        isActive: office.isActive,
      });
    } else if (createOffice) {
      createOffice(data.locationUuid, { name: data.name, seats: parseInt(data.seats) });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormField label="Locatie" errorMessage={errors.locationUuid?.message}>
        <Select name="locationUuid" ref={register({ required: true })}>
          {locations.map((location, index) => (
            <option key={index} value={location.uuid}>
              {location.name}
            </option>
          ))}
        </Select>
      </FormField>
      <FormField label="Naam Ruimte" errorMessage={errors.name?.message}>
        <Input type="name" name="name" ref={register({ required: true, minLength: 4 })} />
      </FormField>
      <FormField label="Aantal plekken" errorMessage={errors.seats?.message}>
        <Input type="seats" name="seats" ref={register({ required: true, maxLength: 3 })} />
      </FormField>
      <Button.Primary>Opslaan</Button.Primary>
    </Form>
  );
};

export default OfficeForm;
