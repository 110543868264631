import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { User, RootState } from '../../global';
import { UserStateAction } from '.';
import { AllUserStateAction } from './allUsersReducer';
import { toast } from 'react-toastify';

type AllUsersThunk<R = void> = ThunkAction<R, RootState, unknown, AllUserStateAction>;
type UserThunk<R = void> = ThunkAction<R, RootState, unknown, UserStateAction>;

type UserResponse = {
  uuid: string;
  name: string;
  maxCredits: number;
  credits: number;
  timesVisited: number;
  voorkeurslocatie: {
    uuid: string;
    naam: string;
  };
  roles: string[];
};

type UsersResponse = {
  uuid: string;
  name: string;
  maxCredits: number;
  credits: number;
  timesVisited: number;
  voorkeurslocatieNaam: string;
};

export const fetchUser = (): UserThunk<Promise<User | undefined>> => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_USER_REQUEST' });

  const response = await axios.get<UserResponse>(`/api/Users/me`).catch((e) => {
    dispatch({ type: 'FETCH_USER_FAILED' });
    return Promise.reject(e.response);
  });

  if (response) {
    const user: User = {
      credits: response.data.credits,
      maxCredits: response.data.maxCredits,
      name: response.data.name,
      preferredLocation: response.data.voorkeurslocatie
        ? {
            name: response.data.voorkeurslocatie.naam,
            uuid: response.data.voorkeurslocatie.uuid,
          }
        : null,
      timesVisited: response.data.timesVisited,
      uuid: response.data.uuid,
      roles: response.data.roles || [],
    };
    dispatch({ type: 'FETCH_USER_SUCCESS', payload: user });
    return Promise.resolve(user);
  }
};

export const createUser = (): UserThunk<Promise<void>> => async () => {
  await axios({
    method: 'POST',
    url: `/api/Users`,
    data: {},
  }).catch((e) => Promise.reject(e.response));

  return Promise.resolve();
};

export const updateUser =
  ({ preferredLocationUuid }: { preferredLocationUuid: string }): UserThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const uuid = getState().user.user?.uuid;

    if (!uuid) {
      return Promise.reject();
    }

    const response = await axios({
      method: 'PATCH',
      url: `/api/Users/${uuid}`,
      data: {
        voorkeurslocatieUuid: preferredLocationUuid,
      },
    }).catch(() => Promise.reject());

    if (response) {
      dispatch(fetchUser());
      Promise.resolve();
    }
  };

export const fetchAllUsers = (): AllUsersThunk => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_USERS_REQUEST' });

  const response = await axios.get<UsersResponse[]>(`/api/Users`).catch((e) => {
    dispatch({ type: 'FETCH_USERS_FAILED' });
    toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het ophalen van de users.');
  });

  if (response) {
    const users: User[] = response.data.map((response) => ({
      credits: response.credits,
      maxCredits: response.maxCredits,
      name: response.name,
      preferredLocation: {
        name: response.voorkeurslocatieNaam,
      },
      timesVisited: response.timesVisited,
      uuid: response.uuid,
      roles: [],
    }));

    dispatch({
      type: 'FETCH_USERS_SUCCESS',
      payload: users,
    });
  }
};
