import React from 'react';
import styled from 'styled-components';
import { Check } from '@styled-icons/material-outlined';
import { Button, Radio } from '../ui';

export type PricingPlanProps = {
  title: string;
  bullets?: string[];
  cost?: string;
  text?: string;
  isHighlighted?: boolean;
  isTryable?: boolean;
  onClick: () => void;
  isSelectable?: boolean;
  isSelected?: boolean;
  showNote?: boolean;
};

const StyledPricingPlan = styled.div<{ isHighlighted: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .plan-highlight {
    height: 4.5rem;
    color: #fff;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 1.5rem 1.5rem 0 0;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    margin-bottom: -2rem;
  }

  .plan-inner {
    margin-top: ${(props) => (props.isHighlighted ? '0' : '2.5rem')};
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: white;
    border-radius: 1.5rem;
    height: 100%;
    min-height: 20rem;
    box-shadow: 0 0.8px 2.1px rgba(0, 0, 0, 0.02), 0 2.7px 6.9px rgba(0, 0, 0, 0.03), 0 12px 31px rgba(0, 0, 0, 0.05);

    .plan-title {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 1.25rem;
      margin: 0;
    }

    .plan-bullets {
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        margin: 1rem 0rem;
        color: ${(props) => props.theme.colors.muted};
        font-size: 0.875rem;

        svg {
          color: ${(props) => props.theme.colors.black};
          height: 1.5rem;
          width: 1.5rem;
          margin-right: 1rem;
        }
      }
    }

    .plan-footer {
      margin-top: auto;
      display: flex;
      flex-direction: column;
    }

    .plan-cost {
      color: ${(props) => props.theme.colors.primary};
      font-family: 'Poppins', sans-serif;
      text-align: center;
      margin: 2rem 0rem;
      font-weight: 500;
      font-size: 1.125rem;
    }

    .plan-note {
      color: ${(props) => props.theme.colors.muted};
      font-size: 0.75rem;
      text-align: center;
      margin-top: -1.5rem;
      height: 0;
      margin-bottom: 2rem;
    }

    .plan-text {
      color: ${(props) => props.theme.colors.muted};
      font-size: 0.875rem;
      line-height: 1.5;
      margin: 1rem 0rem;
    }
  }
`;

function PricingPlan({
  isSelectable = false,
  isSelected = false,
  title,
  bullets,
  text,
  cost,
  isHighlighted = false,
  isTryable = true,
  showNote = false,
  onClick,
}: PricingPlanProps) {
  const props = isSelectable ? { onClick: onClick, isSelected: isSelected } : {};
  return (
    <StyledPricingPlan className="plan" isHighlighted={isHighlighted} {...props}>
      {isHighlighted && <div className="plan-highlight">Eerste maand gratis</div>}
      <div className="plan-inner">
        <h3 className="plan-title">
          {isSelectable ? (
            <Radio label={title} name="pricingplan" value={title} checked={isSelected} onChange={() => onClick()} />
          ) : (
            title
          )}
        </h3>
        {bullets && (
          <ul className="plan-bullets">
            {bullets.map((item, index) => (
              <li key={index}>
                <Check />
                {item}
              </li>
            ))}
          </ul>
        )}

        {text && <span className="plan-text">{text}</span>}

        <div className="plan-footer">
          {cost && <span className="plan-cost">{cost}</span>}
          {showNote && <span className="plan-note">Per gebruiker (excl. BTW)</span>}
          {!isSelectable && (
            <>
              {isTryable ? (
                <Button.Primary type="button" className="plan-button" onClick={onClick}>
                  Aan de slag
                </Button.Primary>
              ) : (
                <Button.Primary type="button" className="plan-button plan-button--tryable" onClick={onClick}>
                  Contact
                </Button.Primary>
              )}
            </>
          )}
        </div>
      </div>
    </StyledPricingPlan>
  );
}

export default PricingPlan;
