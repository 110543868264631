import styled from 'styled-components';

const IosDrawerContentBlock = styled.div`
  padding: 16px;

  p {
    font-size: ${(props) => props.theme.iosStyles.contentSize};
    line-height: 17px;
    color: ${(props) => props.theme.iosStyles.contentColor};
    margin: 0;
    padding: 0;
  }
`;

type Props = {
  children: string | JSX.Element;
};

export default function IosNativeDrawerContent({ children }: Props) {
  return (
    <IosDrawerContentBlock>
      <p>{children}</p>
    </IosDrawerContentBlock>
  );
}
