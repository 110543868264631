import { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../global';
import { fetchUser, UserStateAction } from '../state/users';

function UserProvider({ children }: PropsWithChildren<{}>) {
  const history = useHistory();
  const location = useLocation();
  const dispatch: ThunkDispatch<RootState, undefined, UserStateAction> = useDispatch();
  const { user, loading } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(fetchUser()).catch((e) => {
      if (e.status === 404) {
        return history.push(`/welcome${location.pathname !== '/' ? `?pathname=${location.pathname}` : ''}`);
      }
      if (e.status === 401) {
        return history.push('/login');
      }
    });
  }, [dispatch, history, location]);

  if (!user && loading) {
    return null;
  }

  if (user) {
    return <>{children}</>;
  }

  return null;
}

export default UserProvider;
