import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { Planning, Reservation, RootState } from '../../global';
import { ReservationsStateAction } from '.';
import { toast } from 'react-toastify';
import { DayPart } from '../../enums';

type PlanningsResponse = {
  uuid: string;
  date: string;
  dayPart: string;
  signedInUsers: {
    uuid: string;
    name: string;
  }[];
  locatie: {
    locatieUuid: string;
    locatieNaam: string;
    ruimteUuid: string;
    ruimteNaam: string;
  };
  isCompleted: boolean;
};

type ReservationsThunk<R = void> = ThunkAction<R, RootState, unknown, ReservationsStateAction>;

type FetchReservationsProps = {
  month?: number;
  year?: number;
  userUuid?: string;
  officeUuid?: string;
};

let cache: {
  [key: string]: {
    data: Planning[];
    timestamp: Date;
  };
} = {};

export const fetchReservations =
  (props: FetchReservationsProps = {}, useCache: boolean = true): ReservationsThunk =>
  async (dispatch, getState) => {
    const params = Object.entries(props).map(([key, value]) => typeof value !== 'undefined' && `${key}=${value}`);
    const url = `/api/Plannings?${params.join('&')}`;
    const key = btoa(url);

    if (cache[key] && useCache) {
      const data: Reservation[] = cache[key].data;
      return dispatch<ReservationsStateAction>({ type: 'FETCH_RESERVATIONS_SUCCESS', payload: data });
    }

    dispatch<ReservationsStateAction>({ type: 'FETCH_RESERVATIONS_REQUEST' });

    const response = await axios.get<PlanningsResponse[]>(url).catch((e) => {
      toast.error(e.response?.data || 'Er is iets fout gegaan tijdens het ophalen van de kantoren.');
      dispatch<ReservationsStateAction>({ type: 'FETCH_RESERVATIONS_FAILED' });
    });

    if (response) {
      const reservations: Reservation[] = response.data.map((planning) => ({
        date: planning.date,
        dayPart: DayPart[planning.dayPart as keyof typeof DayPart],
        signedInUsers: planning.signedInUsers,
        isCompleted: planning.isCompleted,
        locationUuid: planning.locatie.locatieUuid,
        locationName: planning.locatie.locatieNaam,
        officeUuid: planning.locatie.ruimteUuid,
        officeName: planning.locatie.ruimteNaam,
        uuid: planning.uuid,
      }));

      cache[key] = {
        data: reservations,
        timestamp: new Date(),
      };

      dispatch<ReservationsStateAction>({ type: 'FETCH_RESERVATIONS_SUCCESS', payload: reservations });
    }
  };
