import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../global';
import { Route } from 'react-router-dom';
import NoPermission from './Manager/NoPermission';

type Props = {
  path: string;
};

const ProtectedRoute: React.FC<Props> = ({ children, path, ...rest }) => {
  const { user } = useSelector((state: RootState) => state);
  const isAdmin = user.user?.roles.includes('admin');

  return <Route path={path} {...rest} render={() => (isAdmin ? children : <NoPermission />)} />;
};

export default ProtectedRoute;
