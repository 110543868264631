import { DefaultTheme } from 'styled-components';

const fonts = {
  heading: '"Poppins", sans-serif',
  body: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
};

export const lightTheme: DefaultTheme = {
  fonts,
  modifiers: {
    colorModifier: '',
  },
  colors: {
    black: '#000000',
    white: '#ffffff',

    primary: '#6c5dd3',
    onPrimary: '#ffffff',

    primaryDarker: '#352e8c',

    background: '#f7f7fd',

    cardBackground: '#ffffff',
    onCardBackground: '#11142d',
    onCardBackgroundEmphasized: '#6c5dd3',

    inputBackground: '#ffffff',
    inputBorder: '#808191',
    disabled: '#e4e4e4',
    disabledText: '#888996',
    border: '#e4e4e4',
    label: '#11142d',
    onLabel: '#ffffff',

    muted: '#808191',
    faded: '#b2b3bd',
    washed: '#e5e5e5',

    success: '#7fba7a',
    warning: '#ffce73',
    error: '#ff754c',

    timeLineColor: '#c1c3d6',
  },
  iosStyles: {
    cardBackground: '#ffffff',
    titleColor: '#000000',
    contentColor: '#000000',

    separatorColor: 'rgba(60, 60, 67, .29)',
    iconColor: 'rgba(60, 60, 67, .6)',
    iconBackground: 'rgba(118, 118, 128, .12)',

    handleBarColor: 'rgba(60, 60, 67, .3)',
    backdropBackground: 'rgba(0, 0, 0, .4)',

    titleSize: '20px',
    contentSize: '15px',
  },
};

export const darkTheme: DefaultTheme = {
  fonts,
  modifiers: {
    colorModifier: 'saturate(0.7)',
  },
  colors: {
    black: '#ffffff',
    white: '#000000',

    primary: '#6861cc',
    onPrimary: '#ffffff',

    primaryDarker: '#5c5492',

    background: '#1c1c24',

    cardBackground: '#2c2c36',
    onCardBackground: '#ffffff',
    onCardBackgroundEmphasized: '#8e86ff',

    inputBackground: '#2c2c36',
    inputBorder: '#707077',

    disabled: '#383840',
    disabledText: '#5f5f6c',

    border: '#333333',

    label: '#b9b9c7',
    onLabel: '#000000',

    muted: '#b9b9c7',
    faded: '#9394a4',
    washed: '#1b1b1b',

    success: '#7fba7a',
    warning: '#ffce73',
    error: '#ff754c',

    timeLineColor: '#696969',
  },
  iosStyles: {
    // cardBackground: '#1C1C1E',
    cardBackground: 'rgba(28, 28, 30, .9)',
    titleColor: '#FFFFFF',
    contentColor: '#FFFFFF',

    separatorColor: 'rgba(84, 84, 88, .65)',
    iconColor: 'rgba(235, 235, 245, .6)',
    iconBackground: 'rgba(118, 118, 128, .24)',

    handleBarColor: 'rgba(235, 235, 245, .5)',
    backdropBackground: 'rgba(0, 0, 0, .28)',

    titleSize: '20px',
    contentSize: '15px',
  },
};
