import React from 'react';
import styled, { StyledComponent } from 'styled-components';

interface IListComposition {
  Item: StyledComponent<'li', any, {}, never>;
}

const OrderdList = styled.ol``;

const UnorderdList = styled.ul``;

type UnorderdListProps = { type: 'unorderd' };
type OrderdListProps = { type: 'orderd' };

type Props = UnorderdListProps | OrderdListProps;

const List: React.FC<Props> & IListComposition = ({ type, children }) => {
  if (type === 'orderd') return <OrderdList>{children}</OrderdList>;
  return <UnorderdList>{children}</UnorderdList>;
};

List.Item = styled.li`
  padding: 0.25rem 0;
  height: 2rem;
  display: flex;
  align-items: center;
`;

export { List };
