import { Reducer } from 'redux';
import { User } from '../../global';

type FETCH_USERS_REQUEST = { type: 'FETCH_USERS_REQUEST' };
type FETCH_USERS_SUCCESS = { type: 'FETCH_USERS_SUCCESS'; payload: User[] };
type FETCH_USERS_FAILED = { type: 'FETCH_USERS_FAILED' };

export type AllUsersState = {
  users: User[] | null;
  loading: boolean;
  success: boolean;
  error: boolean;
};

export type AllUserStateAction = FETCH_USERS_REQUEST | FETCH_USERS_SUCCESS | FETCH_USERS_FAILED;

export const initialState: AllUsersState = {
  users: null,
  loading: true,
  success: false,
  error: false,
};

const allUsersReducer: Reducer<AllUsersState, AllUserStateAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_USERS_REQUEST':
      return { ...state, success: false, loading: true, error: false };
    case 'FETCH_USERS_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        users: action.payload,
      };
    case 'FETCH_USERS_FAILED':
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        users: null,
      };
    default:
      return state;
  }
};

export { fetchAllUsers } from './thunks';
export default allUsersReducer;
