import moment, { Moment } from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Planning, RootState, Location } from '../../global';
import PullToRefresh from './PullToRefresh';
import EmptyPlanning from '../../components/EmptyPlanning';
import { Container, Label, IconButton } from '../../ui';
import { Add } from '@styled-icons/material-outlined';
import isInStandaloneMode from '../../utils/isInStandaloneMode';
import useInfiniteScroll from '../../utils/useInfiniteScroll';
import { groupBy, throttle } from 'lodash';
import { PlanningPopupStateAction } from '../../state/planningPopup';
import { fetchPlanningBlock } from '../../state/plannings';
import { generateDaysInMonth } from '../../utils/calendar';
import PlanningList from './PlanningList';
import usePrevious from '../../utils/usePrevious';
import styled from 'styled-components';
import { setTitle, setActionButton, setScrollbarData } from '../../state/layout';
import getNextWorkableDay from '../../utils/getNextWorkableDay';
import PlanningFilters from './PlanningFilters';

const LoadingIndicator = styled(Label)`
  width: 100%;
  text-align: center;
  padding: 2rem;
`;

export function getPlanningLocation(locations: Location[], preferredLocationUuid: string | undefined) {
  if (locations.length === 1 || !preferredLocationUuid) {
    return locations[0];
  }
  return locations.find((l) => l.uuid === preferredLocationUuid);
}

const Plannings: React.FC = () => {
  const planning = useSelector((state: RootState) => state.planning);
  const user = useSelector((state: RootState) => state.user.user);
  const locations = useSelector((state: RootState) => state.locations.locations);
  const { untoggled } = useSelector((state: RootState) => state.offices);
  const { filters } = useSelector((state: RootState) => state.planningFilters);

  const location = getPlanningLocation(locations, user?.preferredLocation?.uuid);
  const offices = location ? location.offices.filter((o) => o.isActive) : [];

  const selectedOffices = offices.filter((o) => !untoggled.includes(o.uuid));

  const dispatch = useDispatch();

  const loaderRef = React.createRef<HTMLDivElement>();

  const [showMonths, setShowMonths] = React.useState<number>(12);
  const [count, setCount] = React.useState<number>(0);
  const prevCount = usePrevious(count);

  const months = Array.apply(null, Array(showMonths));

  const dates = React.useMemo(() => {
    const today = moment();
    return months
      .reduce<Moment[]>((a, b, index) => {
        const currentMonthAndYear = today.clone().add(index, 'month');
        return [...a, ...generateDaysInMonth(currentMonthAndYear.month() + 1, currentMonthAndYear.year())];
      }, [])
      .filter((x) => !x.isBefore(today, 'date'));
  }, [months]);

  const handleOnSelect = (date: Moment, officeUuid: string) => {
    dispatch<PlanningPopupStateAction>({
      type: 'OPEN_PLANNING_POPUP',
      payload: { date, officeUuid },
    });
  };

  React.useEffect(() => {
    dispatch(setTitle('Reserveringen'));
    dispatch(
      setActionButton(
        <IconButton.Minimal
          onClick={() =>
            selectedOffices.length > 0 &&
            dispatch<PlanningPopupStateAction>({
              type: 'OPEN_PLANNING_POPUP',
              payload: { date: getNextWorkableDay(), officeUuid: selectedOffices[0].uuid },
            })
          }
        >
          <Add />
          <span className='visually-hidden'>Reservering toevoegen</span>
        </IconButton.Minimal>,
      ),
    );
  }, [dispatch, selectedOffices]);

  const keys = React.useMemo(
    () =>
      months.reduce<string[]>((a, b, index) => {
        const currentMonthAndYear = moment().add(index, 'month');
        const keys = selectedOffices.map(
          (office) => `${office.uuid}_${currentMonthAndYear.year()}_${currentMonthAndYear.month() + 1}`,
        );
        return [...a, ...keys];
      }, []),
    [months, selectedOffices],
  );

  React.useEffect(() => {
    keys.forEach((key) => {
      const [officeUuid, year, month] = key.split('_');
      dispatch(
        fetchPlanningBlock({
          month: parseInt(month),
          year: parseInt(year),
          officeUuid,
        }),
      );
    });
  }, [keys, dispatch]);

  const plannings = keys.reduce<Planning[]>((a, b) => (b in planning ? [...a, ...planning[b].plannings] : a), []);
  const planningDates = groupBy(plannings, 'date');

  const onReachEnd = function () {
    if (Object.entries(filters).length === 0) {
      setShowMonths(showMonths + 1);
    } else {
      setShowMonths(12);
    }
  };

  React.useEffect(() => {
    dispatch(setScrollbarData(dates));

    return () => {
      dispatch(setScrollbarData(null));
    };
  }, [dates, dispatch]);

  React.useEffect(() => {
    const currentMonthAndYear = moment().add(showMonths - 1, 'month');
    selectedOffices.forEach((office) =>
      dispatch(
        fetchPlanningBlock(
          {
            month: currentMonthAndYear.month() + 1,
            year: currentMonthAndYear.year(),
            officeUuid: office.uuid,
          },
          prevCount === count,
        ),
      ),
    );
  }, [dispatch, selectedOffices, showMonths, count, prevCount, user]);

  const onRefresh = () => {
    if (showMonths === 1) setCount(count + 1);
    setShowMonths(1);
  };

  useInfiniteScroll(loaderRef, throttle(showMonths < 12 ? onReachEnd : () => {}, 2000));

  if (!location) return null;

  const inner = () => (
    <Container>
      <PlanningFilters />
      {selectedOffices.length > 0 ? (
        <PlanningList
          dates={dates}
          indexedPlannings={planningDates}
          offices={selectedOffices}
          onSelect={handleOnSelect}
        />
      ) : (
        <EmptyPlanning
          style={{ marginTop: offices.length > 0 ? '1.5rem' : 0 }}
          text='Er zijn geen reserveringen. Reserveer jouw werkplek door rechtsbovenin het plus icoon te selecteren of in de agenda te kijken.'
        />
      )}
      <LoadingIndicator
        ref={loaderRef}
        type='faded'
        size='small'
        style={{ display: selectedOffices.length === 0 ? 'none' : undefined }}
      >
        {showMonths === 12 && Object.entries(filters).length === 0
          ? 'Dit is het einde, kom later terug om hier te reserveren!'
          : ''}
        {Object.entries(filters).length === 0
          ? ''
          : 'Voor de volgende 12 maanden zijn er niet meer reserveringen gevonden.'}
      </LoadingIndicator>
    </Container>
  );

  return <>{isInStandaloneMode() ? <PullToRefresh onRefresh={onRefresh}>{inner()}</PullToRefresh> : inner()}</>;
};

export default Plannings;
