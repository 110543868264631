import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteReservation, fetchPlanningBlock } from '../state/plannings';
import { Location, Office, Planning, PlanningUser, RootState } from '../global';
import { Button, Drawer, Modal } from '../ui';
import CreateReservationForm from './CreateReservationForm/CreateReservationForm';
import ReservationOverview from './ReservationOverview';
import AttendeesList from './AttendeesList';
import useWindowSize from '../utils/useWindowSize';
import ChipsSelector from './ChipsSelector';
import styled from 'styled-components';
import ValueLabelWithRing from './ValueLabelWithRing';
import moment from 'moment';
import { DayPart } from '../enums';
import GuestTab from './GuestInput/GuestTab';
import { useCalendarAvailability } from '../utils/useCalendarAvailability';

export const TabsWrapper = styled.div`
  display: grid;
`;

export const Tab = styled.div<{ active: boolean; tabId: string }>`
  transition: 0.25s cubic-bezier(0.86, 0, 0.07, 1);
  opacity: ${(props) => (props.active ? 1 : 0)};
  transform: ${(props) =>
    props.active ? 'translate3d(0, 0, 0)' : `translate3d(${props.tabId === 'details' ? '20' : '-20'}px, 0, 0)`};
  grid-column: 1;
  grid-row: 1;
  align-self: stretch;
  pointer-events: ${(props) => (props.active ? 'initial' : 'none')};
  display: flex;
  flex-direction: column;
`;

const DatePopup: React.FC = () => {
  const dispatch = useDispatch();

  const { isMobileDevice } = useWindowSize();

  const { user, planningPopup, planning, locations } = useSelector((state: RootState) => state);

  const { date, officeUuid } = planningPopup;
  const { occupancy, getOccupiedMessage } = useCalendarAvailability();

  const office = locations.locations.reduce<Office | undefined>(
    (a, b) => (a ? a : b.offices.find((o) => o.uuid === officeUuid)),
    undefined,
  );

  const location = locations.locations.reduce<Location | undefined>(
    (a, b) => (a ? a : b.offices.findIndex((o) => o.uuid === officeUuid) !== -1 ? b : undefined),
    undefined,
  );

  const key = `${office?.uuid}_${date.year()}_${date.month() + 1}`;
  const allPlannings = planning[key]?.plannings || [];
  const isFetching = planning[key]?.state === 'FETCHING';
  const plannings = allPlannings.filter((p) => moment(p.date).isSame(date, 'date'));

  const hasMultipleLocations = locations.locations.length > 1;

  React.useEffect(() => {
    if (!planning[key] && officeUuid) {
      dispatch(
        fetchPlanningBlock({
          month: date.month() + 1,
          year: date.year(),
          officeUuid: officeUuid,
        }),
      );
    }
  }, [key, planning, officeUuid, date, dispatch]);

  const assertAvailability = (inputPlanning: Planning[]) => {
    const morningAll = inputPlanning.find((t) => t.dayPart === DayPart.Morning)?.signedInUsers || [];
    const middayAll = inputPlanning.find((t) => t.dayPart === DayPart.Midday)?.signedInUsers || [];

    const fullDay = morningAll.filter((user: PlanningUser) => middayAll.findIndex((u) => u.uuid === user.uuid) !== -1);
    const morning = morningAll.filter((user: PlanningUser) => fullDay.findIndex((u) => u.uuid === user.uuid) === -1);
    const midday = middayAll.filter((user: PlanningUser) => fullDay.findIndex((u) => u.uuid === user.uuid) === -1);

    return { morningAll, middayAll, fullDay, morning, midday };
  };

  const { fullDay, morning, midday } = assertAvailability(plannings);
  let dayCompleted: boolean;

  if (plannings.find((t) => t.dayPart === DayPart.Midday)?.isCompleted) {
    dayCompleted = true;
  } else dayCompleted = plannings.find((t) => t.dayPart === DayPart.Morning)?.isCompleted || false;

  const userDayPart = fullDay.find((u) => u.uuid === user.user?.uuid)
    ? DayPart.FullDay
    : morning.find((u) => u.uuid === user.user?.uuid)
    ? DayPart.Morning
    : midday.find((u) => u.uuid === user.user?.uuid)
    ? DayPart.Midday
    : undefined;

  const hasUser = typeof userDayPart !== 'undefined';

  const slots = (office?.seats || 0) * 2;
  const slotsFilled = plannings.reduce((a, b) => a + b.signedInUsers.length, 0);

  const [activeTab, setActiveTab] = React.useState<string>('reservation');

  const closePopup = () => {
    dispatch({ type: 'CLOSE_PLANNING_POPUP' });
    setTimeout(() => {
      setActiveTab('reservation');
    }, 200);
  };

  const handleDeleteReservation = (dayPart: DayPart | undefined) => {
    if (officeUuid && user.user && typeof dayPart !== 'undefined') {
      dispatch(deleteReservation({ date, officeUuid, dayPart: dayPart }));
    }
  };

  const tabs = [
    { label: 'Reservering', id: 'reservation' },
    { label: 'Gasten', id: 'reservation_guests' },
    { label: 'Aanwezig', id: 'details' },
  ];

  const Element = isMobileDevice ? Drawer : Modal;

  return (
    <Element
      isOpen={planningPopup.active}
      onCancel={closePopup}
      title={hasUser ? 'Reservering' : 'Nieuwe reservering'}
      renderHeader={() => (
        <div style={{ marginTop: '1rem' }}>
          <ChipsSelector selected={[activeTab]} chips={tabs} onSelect={setActiveTab} />
        </div>
      )}
    >
      <TabsWrapper>
        {office && !isFetching && location && (
          <>
            <Tab active={activeTab === 'reservation'} tabId="reservation">
              <>
                {hasUser && !dayCompleted ? (
                  <>
                    <ReservationOverview
                      location={location}
                      office={office}
                      slotsFilled={slotsFilled}
                      hasMultipleLocations={hasMultipleLocations}
                      slots={slots}
                      dayPart={userDayPart}
                    />
                    <Button.Destructive
                      onClick={() => handleDeleteReservation(userDayPart)}
                      style={{ width: '100%', marginTop: 'auto' }}
                    >
                      Verwijderen
                    </Button.Destructive>
                  </>
                ) : (
                  <CreateReservationForm
                    isDisabled={dayCompleted || date.isBefore(moment(), 'date')}
                    location={location}
                    office={office}
                    plannings={plannings}
                    unavailableDateParts={occupancy}
                    occupancyErrorMessage={getOccupiedMessage}
                    setActiveTab={setActiveTab}
                  />
                )}
              </>
            </Tab>
            <Tab active={activeTab === 'reservation_guests'} tabId="reservation_guests">
              <GuestTab
                active={activeTab === 'reservation_guests'}
                plannings={plannings}
                office={office}
                freeSpots={slots / 2 - slotsFilled / 2}
              />
            </Tab>
            <Tab active={activeTab === 'details'} tabId="details">
              <ValueLabelWithRing
                ringLabel={`${slotsFilled / 2} / ${slots / 2}`.replace('.', ',')}
                label={hasMultipleLocations ? `Ruimte + locatie` : `Ruimte`}
                value={hasMultipleLocations ? `${office.name} (${location.name})` : office.name}
                progress={(slotsFilled / slots) * 100 || 0}
              />
              <AttendeesList active={activeTab === 'details'} midday={midday} morning={morning} fullday={fullDay} />
            </Tab>
          </>
        )}
      </TabsWrapper>
    </Element>
  );
};

export default DatePopup;
