import React from 'react';
import styled from 'styled-components';
import { Button } from '../ui';
import { Edit, Delete } from '@styled-icons/material-outlined';

const Menu = styled.div`
  padding: 0.25rem 0;
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled(Button.Minimal)`
  border-radius: 0;
  text-align: left;
  height: 2.5rem;
  font-size: 0.875rem;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.colors.faded};

  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  }
`;

type Props = {
  edit: () => void;
  remove: () => void;
};

const EditRemoveMenu: React.FC<Props> = ({ edit, remove }) => {
  return (
    <Menu>
      <MenuItem onClick={() => edit()}>
        <Edit />
        Bewerken
      </MenuItem>
      <MenuItem onClick={() => remove()}>
        <Delete />
        Verwijderen
      </MenuItem>
    </Menu>
  );
};

export default EditRemoveMenu;
