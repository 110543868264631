import { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';

type ScrollViewProps = {
  children: ReactNode;
  maxHeight?: string;
  height?: string;
};

const ScrollableContainer = styled.div`
  position: relative;

  container: scrollview / inline-size;
  @container scrollview (max-width: 4500px) {
    > .scrollable {
      background: red;
    }
  }

  &:before,
  &:after {
    // overflow indicator (for bottom)
    content: '';
    position: absolute;
    background-image: linear-gradient(
      0deg,
      ${(props) => props.theme.colors.cardBackground}ff,
      ${(props) => props.theme.colors.cardBackground}00
    );
    inset: calc(100% - 50px) 0 0 0;
    pointer-events: none;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  &:before {
    // overflow indicator (for top)
    inset: 0 0 calc(100% - 50px) 0;
    background-image: linear-gradient(
      180deg,
      ${(props) => props.theme.colors.cardBackground}ff,
      ${(props) => props.theme.colors.cardBackground}00
    );
  }

  &:not([data-scroll-top='0']):before {
    opacity: 1;
  }

  &:not([data-scroll-bottom='0']):after {
    opacity: 1;
  }

  > .scrollable {
    overflow-y: auto;
  }
`;

const ScrollView = ({ children, maxHeight = '100%', height = '100%' }: ScrollViewProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Scrolloffset
  useEffect(() => {
    const onScroll = (e: any) => {
      parentRef.current?.setAttribute('data-scroll-top', e.target.scrollTop ?? 0);
      parentRef.current?.setAttribute(
        'data-scroll-bottom',
        (scrollContainerRef.current?.scrollHeight ?? 0) - (e.target.scrollTop + e.target.clientHeight) + '',
      );
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', onScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', onScroll);
      }
    };
  }, [scrollContainerRef]);

  return (
    <ScrollableContainer data-scroll-top={`0`} data-scroll-bottom={`100`} ref={parentRef}>
      <div
        className="scrollable"
        style={{
          maxHeight: maxHeight,
          height: height,
        }}
        ref={scrollContainerRef}
      >
        {children}
      </div>
    </ScrollableContainer>
  );
};

export default ScrollView;
