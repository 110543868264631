import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Location, Office, RootState, Planning, Guest } from '../../global';
import { makeReservation } from '../../state/plannings';
import { Form, FormField, Button, Radio, Select, Label } from '../../ui';
import { ProgressRing } from '../../ui';
import moment from 'moment';
import ReservationCreditInfo from '../ReservationCreditInfo';
import ReservationInfo from '../ReservationInfo';
import { PlanningPopupStateAction } from '../../state/planningPopup';
import styled from 'styled-components';
import { ThunkDispatch } from 'redux-thunk';
import { DayPart } from '../../enums';
import { Occupancy } from '../../utils/useCalendarAvailability';
import { DateInputField } from '../DateInputField/DateInputField';
import { SimpleLink } from '../../ui/SimpleLink';
import { lightTheme } from '../../ui/themes';

const RadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;

  & > * {
    margin: 0.25rem 0.5rem;
  }
`;

const Ring = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-self: flex-end;
  margin-top: 0.75rem;
`;

const FormFieldLocation = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 6rem;
`;

type ReservationFormData = {
  dayPart: DayPart;
  date: string;
  office: string;
};

type Props = {
  isDisabled?: boolean;
  office: Office;
  location?: Location;
  plannings: Planning[];
  unavailableDateParts?: Occupancy;
  occupancyErrorMessage: Function;
  setActiveTab: Function;
};

const CreateReservationForm: React.FC<Props> = ({
  isDisabled = false,
  office,
  location,
  plannings,
  unavailableDateParts,
  occupancyErrorMessage,
  setActiveTab,
}) => {
  const { date } = useSelector((state: RootState) => state.planningPopup);
  const { company } = useSelector((state: RootState) => state.companies);
  const { user } = useSelector((state: RootState) => state.user);
  const { locations } = useSelector((state: RootState) => state.locations);
  const dispatch: ThunkDispatch<RootState, undefined, PlanningPopupStateAction> = useDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { planningPopup } = useSelector((state: RootState) => state);
  const [isComplete, setIsComplete] = React.useState<boolean>(false);

  const [formDayPart, setFormDayPart] = React.useState<DayPart>();

  const handleDateChange = useCallback(
    (value: string) => {
      const date = moment(value);
      if (!date.isValid()) return;
      dispatch<PlanningPopupStateAction>({ type: 'SET_SELECTED_DATE', payload: { date } });
    },
    [dispatch],
  );

  const { handleSubmit, errors, control, watch, setValue } = useForm<ReservationFormData>({
    defaultValues: {
      dayPart: undefined,
      office: office.uuid,
    },
  });

  const { office: selectedOffice } = watch(['dayPart', 'office']);

  React.useEffect(() => {
    dispatch({ type: 'SET_SELECTED_OFFICE', payload: { officeUuid: selectedOffice } });
  }, [selectedOffice, dispatch]);

  React.useEffect(() => {
    setValue('office', office.uuid);
  }, [office, setValue]);

  React.useEffect(() => {
    setIsComplete(formDayPart !== undefined);
  }, [unavailableDateParts, formDayPart]);

  React.useEffect(() => {
    /**
     * Asserts the given dayParts (fullday, morning, midday) which
     * could already be booked.
     *
     * If there is an active booking, the default value of the
     * radiobuttons is set to the only remaining or most probable value.
     */
    if (unavailableDateParts) {
      if (unavailableDateParts.scheduleClear) {
        // If there are no reservations yet, select FullDay
        setFormDayPart(DayPart.FullDay);
      } else {
        if (unavailableDateParts.morning === null) {
          // If there is no reservation for 'Morning', select morning
          setFormDayPart(DayPart.Morning);
        } else if (unavailableDateParts.midday === null) {
          // If there is no reservation for 'Midday', select midday
          setFormDayPart(DayPart.Midday);
        } else {
          // Otherwise, disable all radiobuttons. The user is unable to create a reservation
          setFormDayPart(undefined);
        }
      }
    }
  }, [unavailableDateParts, setFormDayPart]);

  if (!user) return null;

  const slots = office.seats * (formDayPart === DayPart.FullDay ? 2 : 1);

  const slotsFilled = plannings.reduce((a, b) => {
    if (b.dayPart === formDayPart || formDayPart === DayPart.FullDay) {
      return a + b.signedInUsers.length;
    }
    return a;
  }, 0);

  const enoughLeftForFullDay = slots - slotsFilled < 2 && formDayPart === DayPart.FullDay;

  const cantMakeReservation = enoughLeftForFullDay || slots === slotsFilled;

  const hasMultipleLocations = locations.length > 1;

  const companyUsesCredits = user && company?.usesCredits;

  const hasEnoughCredits = companyUsesCredits ? user.credits > 0 : true;

  // Check if the number of guests exceeds the available space. Subtract one to account for the 'owner'

  const tooManyGuests =
    planningPopup.guests.filter((item) => item.name.length > 0).length > slots / 2 - slotsFilled / 2 - 1;

  // Check if there are any duplicate guests entered.
  const duplicateGuests = (function () {
    const values = planningPopup.guests.map((item) => item.email);
    return new Set(values).size !== values.length;
  })();

  const guestEmailInvalid = planningPopup.guests.some((item) => {
    if (item.email === '') return false;
    return !item.email.match(/.+@.+\..+/);
  });

  const onSubmit = (data: ReservationFormData) => {
    if (data.office && formDayPart !== undefined) {
      setIsLoading(true);
      dispatch(
        makeReservation({
          date: date,
          officeUuid: data.office,
          dayPart: formDayPart,
          guests: planningPopup.guests.filter((item) => item.name.length > 0 && item.email.length > 0),
        }),
      )
        .catch((e) => console.error(e))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} isLoading={isLoading} isDisabled={isDisabled}>
      <DateInputField
        message={errors.date?.message}
        label={'Datum'}
        dateChangeCallBack={handleDateChange}
        value={date}
      />
      <FormFieldLocation>
        <FormField label={hasMultipleLocations ? `Ruimte + Locatie` : `Ruimte`}>
          <Controller
            name="office"
            control={control}
            render={({ onChange, onBlur, name, value }) => (
              <Select onChange={onChange} onBlur={onBlur} value={value} name={name}>
                {locations.map((location) => {
                  if (hasMultipleLocations) {
                    return (
                      <optgroup label={location.name} key={location.uuid}>
                        {location.offices.map((office) => (
                          <option key={office.uuid} value={office.uuid}>
                            {office.name} ({location.name})
                          </option>
                        ))}
                      </optgroup>
                    );
                  }

                  return location.offices.map((office) => (
                    <option key={office.uuid} value={office.uuid}>
                      {office.name}
                    </option>
                  ));
                })}
              </Select>
            )}
          />
        </FormField>
        <Ring>
          <Label type="muted" size="small">
            {`${slotsFilled / 2} / ${slots / 2}`.replaceAll('.', ',')}
          </Label>
          <ProgressRing radius={18} stroke={4} progress={(slotsFilled / slots) * 100 || 0} />
        </Ring>
      </FormFieldLocation>

      <FormField label="Dagdeel" errorMessage={errors.dayPart?.message}>
        <RadioContainer>
          <Radio
            disabled={unavailableDateParts !== undefined && unavailableDateParts.morning !== null}
            onChange={() => {
              setFormDayPart(DayPart.Morning);
            }}
            name={'dayPart'}
            label={'Ochtend'}
            value={DayPart.Morning}
            checked={formDayPart === DayPart.Morning}
          />

          <Radio
            disabled={unavailableDateParts !== undefined && unavailableDateParts.midday !== null}
            onChange={() => {
              setFormDayPart(DayPart.Midday);
            }}
            name={'dayPart'}
            label={'Middag'}
            value={DayPart.Midday}
            checked={formDayPart === DayPart.Midday}
          />

          <Radio
            disabled={unavailableDateParts !== undefined && !unavailableDateParts.scheduleClear}
            onChange={() => {
              setFormDayPart(DayPart.FullDay);
            }}
            name={'dayPart'}
            label={'Hele dag'}
            value={DayPart.FullDay}
            checked={formDayPart === DayPart.FullDay}
          />
        </RadioContainer>
      </FormField>

      {cantMakeReservation ? (
        <div style={{ marginTop: '1.5rem' }}>
          <ReservationInfo
            text={
              enoughLeftForFullDay
                ? 'Deze reservering is helaas niet mogelijk omdat één van de dagdelen al vol zit.'
                : 'Deze reservering is helaas niet mogelijk omdat de ruimte al vol zit.'
            }
          />
        </div>
      ) : companyUsesCredits ? (
        <div style={{ marginTop: '1.5rem' }}>
          <ReservationCreditInfo cost={1} userCredits={user.credits} />
        </div>
      ) : null}

      {unavailableDateParts?.scheduleClear === false ? <ReservationInfo text={occupancyErrorMessage()} /> : null}

      <FormField label={`Gasten (${planningPopup.guests.filter((item) => item.name.length > 0).length})`}>
        {planningPopup.guests.filter((item) => item.name.length > 0).length === 0 ? (
          <ReservationInfo>
            Je hebt nog geen gasten toegevoegd.{' '}
            <SimpleLink
              tabIndex={0}
              onClick={() => {
                setActiveTab('reservation_guests');
              }}
            >
              Gasten toevoegen.
            </SimpleLink>
          </ReservationInfo>
        ) : (
          <>
            <p style={{ marginBottom: '1rem' }}>
              {(function () {
                const renderName = (item: Guest, index: number, guests: string | any[]) => {
                  return `${item.name}${index === guests.length - 2 ? ' en ' : index >= guests.length - 1 ? '' : ', '}`;
                };

                const guests = planningPopup.guests.filter((item) => item.name.length > 0);

                const list = guests.map((item, index) => {
                  const emailValid = item.email.match(/.+@.+\..+/);
                  if (index + 1 > slots / 2 - slotsFilled / 2 - 1 || !emailValid) {
                    return (
                      <span key={index} style={{ color: lightTheme.colors.error }}>
                        {renderName(item, index, guests)}
                      </span>
                    );
                  }
                  return <span key={index}>{renderName(item, index, guests)}</span>;
                });

                return list;
              })()}
            </p>
            {planningPopup.guests.filter((item) => item.name.length > 0).length > slots / 2 - slotsFilled / 2 - 1 ? (
              <ReservationInfo>
                Je hebt meer gasten ingevoerd dan dat er vrije plekken zijn!{' '}
                <SimpleLink
                  tabIndex={0}
                  onClick={() => {
                    setActiveTab('reservation_guests');
                  }}
                >
                  Gasten beheren.
                </SimpleLink>
              </ReservationInfo>
            ) : null}
            {guestEmailInvalid ? (
              <ReservationInfo>
                Er is bij een of meer gasten een ongeldig emailadres opgegeven!{' '}
                <SimpleLink
                  tabIndex={0}
                  onClick={() => {
                    setActiveTab('reservation_guests');
                  }}
                >
                  Gasten beheren.
                </SimpleLink>
              </ReservationInfo>
            ) : null}
          </>
        )}
      </FormField>

      <Button.Primary
        style={{ width: '100%', marginTop: '1.5rem' }}
        disabled={
          !isComplete ||
          cantMakeReservation ||
          isDisabled ||
          !hasEnoughCredits ||
          tooManyGuests ||
          duplicateGuests ||
          guestEmailInvalid
        }
        isLoading={isLoading}
        type="submit"
      >
        Reserveren
      </Button.Primary>
    </Form>
  );
};

export default CreateReservationForm;
