import React from 'react';
import { Seperator, IconButton, Heading } from '../ui';
import styled from 'styled-components';
import { Menu, Close, ArrowBack } from '@styled-icons/material-outlined';
import useWindowSize from '../utils/useWindowSize';
import Logo from './../components/Logo';
import { useSelector } from 'react-redux';
import { RootState } from '../global';

type Props = {
  isOpen: boolean;
  title?: string;
  onMenuToggle: () => void;
  actionButton?: () => React.ReactNode;
};

const Styled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 1rem;
  height: 6rem;
  @media screen and (max-width: 680px) {
    height: 4rem;
  }
  width: 100%;

  span {
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 500;
    margin-left: 1rem;
    color: ${(props) => props.theme.colors.onCardBackground};
  }
`;

const LogoWrapper = styled.div`
  margin-left: 1rem;

  a {
    margin-top: 0.25rem;
  }
`;

const NavigationBar: React.FC<Props> = ({ isOpen, onMenuToggle }) => {
  const { title, actionButton, backButton } = useSelector((state: RootState) => state.layout);

  const { isMobileDevice } = useWindowSize();

  return (
    <>
      <Styled>
        {!backButton && isMobileDevice && (
          <IconButton.Minimal className="burgerIcon" onClick={onMenuToggle}>
            {isOpen ? <Close /> : <Menu />}
            {isOpen ? (
              <span className="visually-hidden">Sluit menu</span>
            ) : (
              <span className="visually-hidden">Open menu</span>
            )}
          </IconButton.Minimal>
        )}

        {isOpen && isMobileDevice && (
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        )}
        {backButton && (
          <IconButton.Minimal onClick={() => backButton()}>
            <ArrowBack />
            <span className="visually-hidden">Vorige</span>
          </IconButton.Minimal>
        )}

        <Heading as="h2">{!isOpen && title && <span>{title}</span>}</Heading>
        <div style={{ marginLeft: 'auto' }}>{actionButton && actionButton}</div>
      </Styled>
      <Seperator margin="0" />
    </>
  );
};

export default NavigationBar;
