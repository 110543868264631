import styled from 'styled-components';

type Props = {
  icon: string | Element | React.ReactNode;
  text: string;
};

const IosRow = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
`;
const IosIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.iosStyles.iconBackground};
  margin-right: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6px;

  > svg {
    width: 100%;
    height: 100%;
  }
`;
const IosText = styled.div`
  flex: 1;
`;

export default function IosNativeDrawerStep({ icon, text }: Props) {
  return (
    <IosRow>
      <IosIcon>{icon}</IosIcon>
      <IosText>{text}</IosText>
    </IosRow>
  );
}
