import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
${reset}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#root {
  /* Makeup for scrollbar on the right side. https://stackoverflow.com/a/30293718 */
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  margin: 0;
  font-family: ${(props) => props.theme.fonts.body};
  box-sizing: border-box;
  max-width: 100vw;
  background-color: ${(props) => props.theme.colors.background};
  overflow-x: hidden;
  color: ${(props) => props.theme.colors.label};
}

html, body {
text-rendering: optimizeLegibility; 
-webkit-font-smoothing: subpixel-antialiased;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.Toastify__toast {
    min-height: 3rem;
    padding: 1rem;
    font: inherit;
}

.Toastify__toast-body {
  /* display: -webkit-box;
  -webkit-line-clamp:3;;
  -webkit-box-orient:vertical; 
  overflow:hidden; */
  margin-right: 0.5rem;
}

.Toastify__toast--success {
  background: ${(props) => props.theme.colors.success};
}

.Toastify__toast--error {
  background: ${(props) => props.theme.colors.error};
}

*,
*:after,
*:before {
  box-sizing: border-box;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}


/* Focusing the button with a keyboard will show a dashed black line. */
*:focus-visible {
  outline: 2px dashed black;
}

/* Focusing the button with a mouse, touch, will not show any outline */
*:focus:not(:focus-visible) {
  outline: none;
}


`;
