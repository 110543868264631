import styled from 'styled-components';

type LabelProps = {
  type?: 'default' | 'muted' | 'faded' | 'fat';
  size?: 'default' | 'small';
};
const Label = styled.div<LabelProps>`
  font-weight: ${(props) => (props.type === 'fat' ? '600' : '400')};
  color: ${(props) =>
    // @ts-ignore
    props.type && ['muted', 'faded'].includes(props.type) ? props.theme.colors[props.type] : props.theme.colors.label};
  font-size: ${(props) => (props.size === 'small' ? '0.8125rem' : '0.875rem')};
  line-height: 1.4;

  &.badge {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export { Label };
