import { Planning } from '../../global';

type PlanningsCache = {
  [key: string]: {
    key: string;
    timestamp: Date;
    data: Planning[];
  };
};

export const planningsCache: PlanningsCache = {};
