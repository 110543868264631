import styled, { css } from 'styled-components';
import { hexToRgb } from '../../utils/hexToRGB';

const Heading = styled.div<{ type?: 'lg' | 'md'; position?: 'sticky' | 'normal' }>`
  ${({ type = 'lg', theme, position }) => css`
    font-weight: ${type === 'lg' ? '500' : '600'};
    font-size: ${type === 'lg' ? '1.125rem' : '1rem'};
    color: ${(props) => props.theme.colors.onCardBackground};
    font-family: ${['md'].includes(type) ? theme.fonts.body : theme.fonts.heading};

    ${position === 'sticky' &&
    css`
      position: sticky;
      top: 1px;
      background-image: linear-gradient(
        0deg,
        rgba(${(props) => hexToRgb(props.theme.colors.background)}, 0),
        rgba(${(props) => hexToRgb(props.theme.colors.background)}, 1)
      );
      padding-block: 1rem;
      z-index: 10;
    `}
  `}
`;

export { Heading };
