import { Dictionary, uniqBy } from 'lodash';
import moment from 'moment';
import { Moment } from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ReservationCard from '../../components/ReservationCard/ReservationCard';
import { Planning, Office, PlanningUser, RootState } from '../../global';

const OfficesList = styled.div`
  display: grid;
  gap: 1.5rem;
  margin-top: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));
`;

type Props = {
  selectedDate: Moment;
  indexedPlannings: Dictionary<Planning[]>;
  offices: Office[];
  selectOffice: (officeUuid: string) => void;
};

const Offices: React.FC<Props> = ({ offices, selectedDate, indexedPlannings, selectOffice }) => {
  const { user } = useSelector((state: RootState) => state.user);

  if (!user) return null;

  return (
    <OfficesList>
      {offices.map((office) => {
        // Not good, working on a better solution.
        const key = Object.keys(indexedPlannings).find((p) => moment(p).isSame(selectedDate, 'date'));
        const dayParts = (key ? indexedPlannings[key] : []).filter((d) => d.officeUuid === office.uuid);

        const slotsFilled = dayParts.reduce<number>((a, b) => a + b.signedInUsers.length, 0);
        const users = uniqBy(
          dayParts.reduce<PlanningUser[]>((a, b) => [...a, ...b.signedInUsers], []),
          'uuid',
        );
        const hasCurrentUser = users.findIndex((u) => u.uuid === user.uuid) !== -1;
        const hasCurrentUserPerDayPart = dayParts.reduce<{ 0: boolean; 1: boolean }>(
          (a, b) => {
            if (b.signedInUsers.findIndex((u) => u.uuid === user.uuid) !== -1) {
              return { ...a, [b.dayPart]: true };
            }
            return a;
          },
          { 0: false, 1: false },
        );

        const planningTitle =
          hasCurrentUserPerDayPart[0] === true && hasCurrentUserPerDayPart[1] === true
            ? 'Hele dag'
            : hasCurrentUserPerDayPart[0] === true
            ? '08:00 - 13:00'
            : hasCurrentUserPerDayPart[1] === true
            ? '13:00 - 18:00'
            : undefined;

        const slots = office.seats * 2;

        return (
          <ReservationCard
            key={office.uuid}
            onClick={() => selectOffice(office.uuid)}
            slots={slots / 2}
            slotsFilled={slotsFilled / 2}
            title={office.name}
            users={users}
            highlighted={hasCurrentUser}
            planningTitle={planningTitle}
          />
        );
      })}
    </OfficesList>
  );
};
export default Offices;
