import { Dictionary } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Office, Planning, PlanningUser, RootState } from '../../global';
import { ElevatedCard, ProgressRing } from '../../ui';
import { generateRowsForMonthView } from '../../utils/calendar';
import { formatNumber } from '../../utils/numberFormatter';

const OuterWrapper = styled.div`
  width: clamp(300px, 100%, 1000px);
  margin: 0 auto;
`;

const MonthWrapper = styled(ElevatedCard)<{ renderWeekend: boolean }>`
  will-change: transform, opacity;
  grid-template-columns: repeat(${(props) => (props.renderWeekend ? 7 : 5)}, minmax(0, 1fr));
  grid-auto-rows: auto repeat(99, minmax(0, 1fr));
  background: ${(props) => props.theme.colors.border};
  padding: 0;
  overflow: hidden;
  margin-bottom: 2rem;

  @media screen and (max-width: 114em) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @media screen and (min-width: 1024px) {
    width: calc(100% - 3rem);
    margin-left: auto;
    margin-right: auto;
  }

  display: grid;
  gap: 1px;
  padding: 0 1px 1px 1px;
`;

const MonthCell = styled.div<{
  isDisabled: boolean;
  isHighlighted: boolean;
  isFull?: boolean;
  borderRadiusBottomLeft?: boolean;
  borderRadiusBottomRight?: boolean;
}>`
  background: ${(props) => props.theme.colors.cardBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  position: relative;
  padding: 0.25rem 1rem 0.75rem;
  cursor: pointer;

  @media screen and (max-width: 1280px) {
    padding: 0.25rem 0 0.75rem;
    div {
      font-size: 0.75rem;
    }
  }

  aspect-ratio: 5 / 3;
  max-width: 200px;

  ${(props) =>
    props.borderRadiusBottomLeft &&
    css`
      border-bottom-left-radius: 1.5rem;
      &:before {
        border-bottom-left-radius: 1.5rem;
      }
    `};
  ${(props) =>
    props.borderRadiusBottomRight &&
    css`
      border-bottom-right-radius: 1.5rem;
      &:before {
        border-bottom-right-radius: 1.5rem;
      }
    `};

  @media screen and (min-width: 1280px) {
    div {
      font-size: 0.9rem;
    }
  }

  ${(props) =>
    props.isDisabled &&
    css`
      & > *,
      & > *:before {
        opacity: 0.4;
      }
    `}

  ${(props) =>
    props.isFull &&
    !props.isHighlighted &&
    css`
      z-index: 0;
      opacity: 0.5;
      &:before {
        content: '';
        position: absolute;
        inset: -1px;
        box-shadow: inset 0 0 0 0.5px ${(props) => props.theme.colors.muted};
        background: ${(props) => props.theme.colors.faded + '20'};
        z-index: -1;
      }
    `}

  ${(props) =>
    props.isHighlighted &&
    css`
      z-index: 0;

      &:before {
        content: '';
        position: absolute;
        inset: -1px;
        box-shadow: inset 0 0 0 0.5px ${(props) => props.theme.colors.primary};
        background: ${(props) => props.theme.colors.primary + '20'};
        z-index: -1;
      }

      > div,
      > span {
        z-index: 1;
      }
    `}
`;

const MonthCellOccupationIndicator = styled.div`
  width: fit-content;
  height: 3rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  gap: 0.2rem;
  justify-content: space-around;
  align-items: center;
`;

const MonthCellDate = styled.span<{ isToday: boolean }>`
  display: flex;
  width: 2.25rem;
  height: 2.25rem;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 0.9125rem;

  @media screen and (max-width: 1280px) {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 0.8125rem;
  }
  ${(props) =>
    props.isToday &&
    css`
      color: #fff;
      background-color: ${(props) => props.theme.colors.primary};
      border-radius: 999rem;
      font-weight: 700;
    `}
`;

const MonthCellSmall = styled(MonthCell)`
  aspect-ratio: unset;
  padding: 1rem;
  > span {
    margin-bottom: 0;
  }
`;

type Props = {
  month: number;
  year: number;
  selectDate?: (date: Moment) => void;
  indexedPlannings: Dictionary<Planning[]>;
  offices: Office[];
};

const Month: React.FC<Props> = ({ month, year, selectDate, offices, indexedPlannings }) => {
  const { user } = useSelector((state: RootState) => state.user);

  if (!user) return null;

  const rows = generateRowsForMonthView(month, year, false);

  const hasOffices = offices.length > 0;

  const handleSelectDate = (date: Moment) => {
    if (selectDate && date.month() === month - 1 && hasOffices) {
      selectDate(date);
    }
  };

  const renderCell = (date: Moment, rowIndex: number) => {
    // Not good, working on a better solution.
    const key = Object.keys(indexedPlannings).find((p) => moment(p).isSame(date, 'date'));
    const dayParts = key ? indexedPlannings[key] : [];

    const slotsFilled = dayParts.reduce<number>((a, b) => a + b.signedInUsers.length, 0);
    const slots = offices.reduce<number>((a, b) => a + b.seats, 0) * 2;
    const users = dayParts.reduce<PlanningUser[]>((a, b) => [...a, ...b.signedInUsers], []);
    const hasCurrentUser = users.findIndex((u) => u.uuid === user.uuid) !== -1;

    const progress = slotsFilled / slots;

    return (
      <MonthCell
        key={date.format()}
        onClick={() => handleSelectDate(date)}
        isDisabled={date.isBefore(moment(), 'date')}
        isHighlighted={hasCurrentUser}
        isFull={progress >= 1}
        borderRadiusBottomLeft={rowIndex === rows.length - 1 && date.format('d') === '1'}
        borderRadiusBottomRight={
          rowIndex === rows.length - 1 && date.format('d') === rows[rowIndex].days.length.toString()
        }
      >
        {date.month() === month - 1 ? (
          <>
            <MonthCellDate isToday={date.isSame(moment(), 'date')}>{date.format('DD')}</MonthCellDate>
            <MonthCellOccupationIndicator>
              <ProgressRing radius={24} stroke={5} progress={(slotsFilled / slots) * 100 || 0} />
            </MonthCellOccupationIndicator>
            {hasOffices && date.month() === month - 1 && (
              <div>{`${formatNumber('nl-NL', slotsFilled / 2)} / ${formatNumber('nl-NL', slots / 2)}`}</div>
            )}
          </>
        ) : (
          <>
            <MonthCellDate isToday={false} />
            <MonthCellOccupationIndicator />
          </>
        )}
      </MonthCell>
    );
  };

  return (
    <OuterWrapper>
      <MonthWrapper renderWeekend={false}>
        {rows[0].days.map((day, index) => (
          <MonthCellSmall key={index} isDisabled={false} isHighlighted={false}>
            <MonthCellDate isToday={day.isSame(moment(), 'date')}>{day.format('dd')}</MonthCellDate>
          </MonthCellSmall>
        ))}
        {rows.map((row, index) => (
          <React.Fragment key={index}>{row.days.map((day, i) => renderCell(day, index))}</React.Fragment>
        ))}
      </MonthWrapper>
    </OuterWrapper>
  );
};

export default Month;
