import React from 'react';
import styled from 'styled-components';
import { Label, Button } from '../ui';

const Wrapper = styled.div`
  padding: 2rem;
  /* Border from https://kovart.github.io/dashed-border-generator/ */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%2311142D1F' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 16px; /* Important to do pixels here because of the dashed border exclusivly works with pixel values */

  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.label};

  & > * + * {
    margin-top: 1rem;
  }

  ${Label} {
    max-width: 70ch;
    text-align: center;
  }

  ${Button.Primary} {
    height: 2.5rem;
    border-radius: 0.75rem;
  }

  svg {
    opacity: 0.15;
    width: 3.5rem;
    height: auto;
  }
`;

type Props = {
  text: string;
  style?: React.CSSProperties;
  className?: string;
  action?: () => void;
  actionLabel?: string;
  icon?: React.ReactNode;
};

const EmptyState: React.FC<Props> = ({ text, style, className, action, actionLabel, icon }) => {
  return (
    <Wrapper style={style} className={className}>
      {icon && icon}
      <Label as="p" type="faded">
        {text}
      </Label>
      {action && actionLabel && <Button.Primary onClick={() => action()}>{actionLabel}</Button.Primary>}
    </Wrapper>
  );
};

export default EmptyState;
