import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../global';
import {
  Avatar as _Avatar,
  Heading,
  Label,
  Modal,
  Button,
  IconButton,
  Drawer,
  FormField,
  Select,
  Form,
  Container as _Container,
  ElevatedCard,
} from '../ui';
import { Edit, ExitToApp } from '@styled-icons/material-outlined';
import { updateUser } from '../state/users';
import useWindowSize from '../utils/useWindowSize';
import { setActionButton, setTitle } from '../state/layout';
import AppearanceSelector, { Appearance } from '../components/AppearanceSelector/AppearanceSelector';

const Avatar = styled(_Avatar)``;

const VersionNumber = styled(Label)`
  font-size: 0.75rem;
  opacity: 0.4;
  text-align: center;
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
`;

const Card = styled(ElevatedCard)`
  gap: 1rem;
  padding: 2rem;
`;

const SettingsHeader = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Heading} {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1.125rem;
    margin-top: 1rem;
  }

  ${Avatar} {
    margin-bottom: 1rem;
  }
`;

const SettingsBody = styled.div`
  display: grid;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const SettingsCredits = styled.div`
  padding: 1rem;
  text-align: center;

  ${Heading} {
    color: ${(props) => props.theme.colors.primary};
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
`;

const Container = styled(_Container)`
  min-height: calc(${window.innerHeight}px - 6rem);
  padding-bottom: 2rem;
`;

const Settings: React.FC = () => {
  const { user, locations } = useSelector((state: RootState) => ({
    ...state.user,
    ...state.locations,
  }));

  const { company } = useSelector((state: RootState) => state.companies);
  const { appearance } = useSelector((state: RootState) => state.preferences);
  const { isMobileDevice } = useWindowSize();

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const preferredLocation = locations.find((l) => l.uuid === user?.preferredLocation?.uuid);
  const [selectedLocationUuid, setSelectedLocation] = React.useState<string | undefined>(
    () => preferredLocation?.uuid || undefined,
  );

  const [selectedAppearance, setSelectedAppearance] = React.useState<Appearance>(() => appearance);

  // const selectedLocation = locations.find((l) => l.uuid === selectedLocationUuid);
  const hasMultipleLocations = locations.length > 1;

  React.useEffect(() => {
    dispatch(setTitle('Mijn instellingen'));
    dispatch(
      setActionButton(
        <IconButton.Minimal onClick={() => setIsOpen(true)}>
          <Edit />
          <span className="visually-hidden">Voorkeuren aanpassen</span>
        </IconButton.Minimal>,
      ),
    );
  }, [dispatch, setIsOpen, hasMultipleLocations]);

  if (!user) return null;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedLocationUuid && selectedLocationUuid !== preferredLocation?.uuid) {
      dispatch(updateUser({ preferredLocationUuid: selectedLocationUuid }));
    }

    if (selectedAppearance && selectedAppearance !== appearance) {
      dispatch({ type: 'SET_APPEARANCE_PREFERENCE', payload: selectedAppearance });
    }

    setIsOpen(false);
  };

  const Element = isMobileDevice ? Drawer : Modal;

  return (
    <>
      <Element isOpen={isOpen} onCancel={() => setIsOpen(false)} title="Mijn Instellingen">
        <Form onSubmit={handleSubmit}>
          {hasMultipleLocations ? (
            <FormField label="Jouw voorkeurslocatie">
              <Select onChange={(e) => setSelectedLocation(e.target.value)} value={selectedLocationUuid}>
                {locations.map((location) => (
                  <option key={location.uuid} value={location.uuid}>
                    {location.name}
                  </option>
                ))}
              </Select>
            </FormField>
          ) : null}
          <AppearanceSelector onUpdate={(a) => setSelectedAppearance(a)} value={selectedAppearance} />
          <Button.Primary
            disabled={selectedLocationUuid === preferredLocation?.uuid && selectedAppearance === appearance}
          >
            Opslaan
          </Button.Primary>
        </Form>
      </Element>
      <Container>
        <SettingsHeader>
          <Heading>{user.name}</Heading>
          <Avatar size="6rem" name={user.name} />
        </SettingsHeader>
        {company?.usesCredits && (
          <SettingsCredits>
            <Heading>{user.credits}</Heading>
            <Label type="muted" size="small">
              credit(s)
            </Label>
          </SettingsCredits>
        )}
        {hasMultipleLocations && (
          <SettingsBody>
            <Heading type="md">Jouw voorkeurslocatie</Heading>
            <Card>
              <Heading>{preferredLocation?.name}</Heading>
            </Card>
          </SettingsBody>
        )}
        <Button.Primary onClick={() => (window.location.href = '/logout')} style={{ marginTop: 'auto' }}>
          <ExitToApp style={{ marginRight: '0.5rem' }} />
          Uitloggen
        </Button.Primary>
      </Container>
      <VersionNumber type="muted">v. {process.env.REACT_APP_GIT_SHA}</VersionNumber>
    </>
  );
};

export default Settings;
