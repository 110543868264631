import moment, {Moment} from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PlanningUser, Reservation, RootState} from '../../global';
import PullToRefresh from '../Plannings/PullToRefresh';
import {Container, Heading, IconButton} from '../../ui';
import {Add} from '@styled-icons/material-outlined';
import isInStandaloneMode from '../../utils/isInStandaloneMode';
import { PlanningPopupStateAction } from '../../state/planningPopup';
import { fetchReservations } from '../../state/reservations';
import ReservationCard from '../../components/ReservationCard/ReservationCard';
import styled from 'styled-components';
import {Dictionary, groupBy, uniqBy} from 'lodash';
import EmptyPlanning from '../../components/EmptyPlanning';
import {setActionButton, setTitle} from '../../state/layout';
import getNextWorkableDay from '../../utils/getNextWorkableDay';
import YearNavigator from "./YearNavigator";

const ReservationCards = styled.ul`
  display: grid;
  gap: 3rem;

  list-style: none;

  h2 {
    margin-bottom: 1.5rem;

    span {
      margin-left: 1ch;
    }
  }
`;

const ReservationsWeek = styled.ul`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));
`;

const Reservations: React.FC = () => {
  const {reservations, state} = useSelector((state: RootState) => state.reservations);
  const {locations} = useSelector((state: RootState) => state.locations);
  const {user} = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [showPastReservations, setShowPastReservations] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const thisYear = moment().year();
  const [reservationsYear, setReservationsYear] = useState(thisYear);
  const location = locations.find((l) => l.uuid === user?.preferredLocation?.uuid) || locations[0];
  const hasMultipleLocations = locations.length > 1;
  const minReservationYear = Math.min(...reservations.map(reservation => moment(reservation.date).year()));
  const maxReservationYear = Math.max(...reservations.map(reservation => moment(reservation.date).year()));

  useEffect(() => {
    if (reservationsYear <= minReservationYear) {
      setDisablePrev(true)
    } else {
      setDisablePrev(false)
      setShowPastReservations(false)
    }

    if (reservationsYear >= thisYear) {
      setDisableNext(true)
    } else {
      setDisableNext(false)
      setShowPastReservations(true)
    }
  }, [reservationsYear, minReservationYear, maxReservationYear])

  React.useEffect(() => {
    if (user && ['IDLE'].includes(state)) {
      dispatch(fetchReservations({userUuid: user.uuid}));
    }
  }, [user, reservations, dispatch, state]);

  React.useEffect(() => {
    dispatch(setTitle('Mijn reserveringen'));
    dispatch(
      setActionButton(
        <IconButton.Minimal
          onClick={() =>
            location &&
            location.offices.length > 0 &&
            dispatch<PlanningPopupStateAction>({
              type: 'OPEN_PLANNING_POPUP',
              payload: {date: getNextWorkableDay(), officeUuid: location.offices[0].uuid},
            })
          }
        >
          <Add/>
          <span className="visually-hidden">Reservering toevoegen</span>
        </IconButton.Minimal>,
      ),
    );
  }, [dispatch, location]);

  const handleOnSelect = (date: Moment, officeUuid: string) => {
    dispatch<PlanningPopupStateAction>({
      type: 'OPEN_PLANNING_POPUP',
      payload: {date, officeUuid},
    });
  };

  const onRefresh = () => {
    if (user) {
      dispatch(fetchReservations({userUuid: user.uuid}, false));
    }
  };

  if (!user) return null;

  const getReservationsByYear = (reservations: Reservation[], includePast: boolean, year?: number): Dictionary<Reservation[]> => {
    const filteredReservations = reservations.reduce<Dictionary<Reservation[]>>((planningArray, planning) => {
      const date = moment(planning.date);
      const isPast = date.isBefore(moment(), 'day');
      const reservationYear = date.year();

      // Only includes past reservations by year
      if (year && includePast && isPast && reservationYear === year) {
        const weekNumber = date.format('YYYY_W');
        (planningArray[weekNumber] = planningArray[weekNumber] || []).push(planning);
      }

      // Only include Present and future reservations
      else if (!includePast && !isPast) {
        const weekNumber = date.format('YYYY_W');
        (planningArray[weekNumber] = planningArray[weekNumber] || []).push(planning);
      }

      return planningArray;
    }, {});

    // Sort By week from low to high
    const sortedReservations: Dictionary<Reservation[]> = {};
    Object.keys(filteredReservations).sort().forEach((key) => {
      sortedReservations[key] = filteredReservations[key];
    });

    return sortedReservations;
  }

  const nextYear = () => {
    setReservationsYear(reservationsYear + 1);
  }

  const prevYear = () => {
    setReservationsYear(reservationsYear - 1);
  }

  const reservationByPastOrPresent = (reservationsYear === thisYear ? getReservationsByYear(reservations, showPastReservations) : getReservationsByYear(reservations, showPastReservations, reservationsYear))
  const inner = () => (
    <Container>
      <Heading as="h1">Historische Reserveringen</Heading>
      <YearNavigator nextYear={nextYear}
                     prevYear={prevYear}
                     disablePrev={disablePrev}
                     disableNext={disableNext}
                     currentYear={reservationsYear >= thisYear ? "Toekomstig" : reservationsYear }/>
      {state !== 'SUCCESS' ? null : Object.entries(reservationByPastOrPresent).length > 0 ? (
        <ReservationCards>
          {Object.entries(reservationByPastOrPresent).map(([key, reservations]) => {
            const dates = groupBy(reservations, 'date');
            const date = moment(reservations[0].date);
            return (
              <li key={key} title={`Week ${date.format('W')}`}>
                <Heading as='h2' type='md' position='sticky'>
                  Week {date.format('W')}
                  {!date.isSame(moment(), 'year') && <span>{date.format('YYYY')}</span>}
                </Heading>
                <ReservationsWeek>
                  {Object.entries(dates)
                    .sort((a, b) => moment(a[0]).valueOf() - moment(b[0]).valueOf())
                    .map(([dateString, reservation]) => {
                      const date = moment(dateString);

                      return Object.entries(groupBy(reservation, 'locationUuid')).map(([locationUuid, plannings]) => {
                        const location = locations.find((l) => l.uuid === locationUuid);
                        if (!location) return null;

                        return Object.entries(groupBy(plannings, 'officeUuid')).map(([officeUuid, plannings]) => {
                          const office = location.offices.find((o) => o.uuid === officeUuid);

                          if (!office) return null;

                          const slotsFilled = plannings.reduce<number>((a, b) => a + b.signedInUsers.length, 0);
                          const users = uniqBy(
                            plannings.reduce<PlanningUser[]>((a, b) => [...a, ...b.signedInUsers], []),
                            'uuid',
                          );

                          const hasCurrentUserPerDayPart = plannings.reduce<{ 0: boolean; 1: boolean }>(
                            (a, b) => {
                              if (b.signedInUsers.findIndex((u) => u.uuid === user.uuid) !== -1) {
                                return {...a, [b.dayPart]: true};
                              }
                              return a;
                            },
                            {0: false, 1: false},
                          );

                          const planningTitle =
                            hasCurrentUserPerDayPart[0] === true && hasCurrentUserPerDayPart[1] === true
                              ? 'Hele dag'
                              : hasCurrentUserPerDayPart[0] === true
                                ? '08:00 - 13:00'
                                : hasCurrentUserPerDayPart[1] === true
                                  ? '13:00 - 18:00'
                                  : undefined;

                          const subtitle = hasMultipleLocations
                            ? `${office.name} (${locations.find((l) => l.uuid === office.locationUuid)?.name})`
                            : `${office.name}`;

                          return (
                            <ReservationCard
                              key={date.format() + office.uuid}
                              title={date.format('dd DD MMMM')}
                              slotsFilled={slotsFilled / 2}
                              slots={(office.seats * plannings.length) / 2}
                              users={users}
                              planningTitle={planningTitle}
                              subtitle={subtitle}
                              highlighted={true}
                              onClick={() => handleOnSelect(date, reservation[0].officeUuid)}
                              holidays={date.isDutchHoliday(null, false)}
                              asListItem={true}
                            />
                          );
                        });
                      });
                    })}
                </ReservationsWeek>
              </li>
            );
          })}
        </ReservationCards>
      ) : (
        <EmptyPlanning
          text="Je hebt geen reserveringen. Reserveer jouw werkplek door rechtsbovenin het plus icoon te selecteren, in reserveringen of in de agenda te kijken. "/>
      )}
    </Container>
  );

  return <>{isInStandaloneMode() ? <PullToRefresh onRefresh={onRefresh}>{inner()}</PullToRefresh> : inner()}</>;
};

export default Reservations;
