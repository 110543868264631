import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Close } from '@styled-icons/ionicons-outline';
import { IconButton } from '../Button/Button';
import { Heading } from '../Heading/Heading';
const FocusTrap = require('focus-trap-react');

export type Props = {
  isOpen: boolean;
  isCancellable?: boolean;
  onCancel?: () => void;
  className?: string;
  title?: string;
  renderHeader?: () => React.ReactNode;
};

const Styled = styled.div<Props>`
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  overflow-y: hidden;
  pointer-events: none;
  align-items: center;

  .underlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: 0.15s ease;
  }

  .inner {
    position: relative;
    max-width: calc(100% - 1rem);
    width: 40rem;
    background: ${(props) => props.theme.colors.cardBackground};
    padding: 2rem;
    border-radius: 0.2rem;
    transition: 0.2s cubic-bezier(0.45, 0, 0.21, 1);
    transform: scale(0.7) translateY(10%);
    opacity: 0;
    box-shadow: 0 3.4px 2.7px rgba(0, 0, 0, 0.012), 0 8.7px 6.9px rgba(0, 0, 0, 0.018),
      0 17.7px 14.2px rgba(0, 0, 0, 0.022), 0 36.5px 29.2px rgba(0, 0, 0, 0.028), 0 100px 80px rgba(0, 0, 0, 0.04);
  }

  &.enter-done {
    pointer-events: initial;

    .underlay {
      opacity: 1;
    }

    .inner {
      transform: scale(1) translateY(0%);
      opacity: 1;
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    ${Heading.Small} {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 1.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 1.3em;
    }
  }

  .subHeader {
    padding-bottom: 1rem;
  }
  @media screen and (max-width: 44rem) {
    .inner {
      /* transition: 0.2s cubic-bezier(0.45, 0, 0.21, 1);
      border-radius: 0;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem; */

      overflow-y: scroll;
      max-height: 80%;
      -webkit-overflow-scrolling: touch;
      /* transform: scale(1) translateY(100%); */
    }
  }
`;

const CloseButton = styled(IconButton.Minimal)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const portalRoot = document.getElementById('portal');

const Modal: React.FC<Props> = ({
  children,
  isOpen,
  className,
  isCancellable = true,
  onCancel = () => {},
  title,
  renderHeader,
}) => {
  const modalRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (!modalRef.current) return;

    if (isOpen) {
      disableBodyScroll(modalRef.current);
    } else {
      enableBodyScroll(modalRef.current);
    }
  }, [isOpen, modalRef]);

  if (!portalRoot) return null;

  return ReactDOM.createPortal(
    <CSSTransition addEndListener={() => {}} unmountOnExit in={isOpen} timeout={200}>
      <Styled isCancellable={isCancellable} ref={modalRef} isOpen={isOpen} className={className} tabIndex={-1}>
        <FocusTrap
          focusTrapOptions={{
            onDeactivate: onCancel,
          }}
        >
          <div>
            <div className="underlay" onClick={isCancellable ? onCancel : undefined} />
            <div className="inner">
              {title && (
                <div className="header">
                  <Heading>{title}</Heading>

                  <CloseButton onClick={onCancel}>
                    <Close />
                    <span className="visually-hidden">Sluiten</span>
                  </CloseButton>
                </div>
              )}
              {renderHeader && <div className="subHeader">{renderHeader()}</div>}
              {children}
            </div>
          </div>
        </FocusTrap>
      </Styled>
    </CSSTransition>,
    portalRoot,
  );
};

export { Modal };
