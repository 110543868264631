import React from 'react';
import styled from 'styled-components';
import { Label } from '../ui';

const Wrapper = styled.div`
  padding: 2rem;
  /* Border from https://kovart.github.io/dashed-border-generator/ */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%2311142D1F' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 16px; /* Important to do pixels here because of the dashed border exclusivly works with pixel values */

  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.label};

  ${Label} {
    max-width: 70ch;
    text-align: center;
  }

  svg {
    opacity: 0.15;
    width: 3.5rem;
    height: auto;
    margin-bottom: 1rem;
  }
`;

type Props = {
  text: string;
  style?: React.CSSProperties;
  className?: string;
};

const EmptyPlanning: React.FC<Props> = ({ text, style, className }) => {
  return (
    <Wrapper style={style} className={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V10H20V21ZM20 8H4V5H20V8Z"
          fill="currentColor"
        />
        <path
          d="M10.9318 16.7494H12.608V16.579C12.608 16.0143 12.8743 15.7302 13.3892 15.4284C14.0817 15.0236 14.554 14.5158 14.554 13.6528C14.554 12.3176 13.4283 11.65 11.9119 11.65C10.5305 11.65 9.38353 12.3034 9.36932 13.8659H11.1875C11.1875 13.4575 11.5142 13.1699 11.8977 13.1699C12.2528 13.1699 12.5369 13.4114 12.5369 13.7665C12.5369 14.1642 12.1925 14.4092 11.7415 14.704C11.2124 15.0484 10.9318 15.4106 10.9318 16.579V16.7494ZM11.7983 19.1358C12.3203 19.1358 12.7855 18.6954 12.7926 18.1415C12.7855 17.6017 12.3203 17.1614 11.7983 17.1614C11.2479 17.1614 10.7969 17.6017 10.804 18.1415C10.7969 18.6954 11.2479 19.1358 11.7983 19.1358Z"
          fill="currentColor"
        />
      </svg>
      <Label as="p" type="faded">
        {text}
      </Label>
    </Wrapper>
  );
};

export default EmptyPlanning;
