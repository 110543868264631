import { sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../global';
import { OfficesStateAction } from '../../state/offices';
import ChipsSelector from '../../components/ChipsSelector';
import { getPlanningLocation } from './index';

const OfficesFilter = () => {
  const dispatch = useDispatch();
  const { untoggled } = useSelector((state: RootState) => state.offices);

  const user = useSelector((state: RootState) => state.user.user);
  const locations = useSelector((state: RootState) => state.locations.locations);
  const location = getPlanningLocation(locations, user?.preferredLocation?.uuid);

  const handleOnOfficeClick = (officeUuid: string) => {
    dispatch<OfficesStateAction>({ type: 'TOGGLE_OFFICE', payload: officeUuid });
  };
  if (!location) return null;
  const offices = location.offices.filter((o) => o.isActive);
  return (
    <ChipsSelector
      onSelect={handleOnOfficeClick}
      selected={untoggled}
      invertSelected={true}
      showIcon={true}
      chips={sortBy(offices, 'name').map((o) => ({ label: o.name, id: o.uuid }))}
      renderReel={false}
    />
  );
};

export default OfficesFilter;
