import React from 'react';
import styled, { css } from 'styled-components';
import { Label } from '..';

const Form = styled.form<{ isLoading?: boolean; isDisabled?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 1rem;
  transition: 0.15s ease;

  ${(props) =>
    props.isLoading &&
    `
    opacity: 0.7;
    pointer-events: none;
  `}

  ${(props) =>
    props.isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}


  button[type='submit'] {
    margin-left: auto;
  }
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;

  ${Label.Default} {
    margin: 0 0 0.5rem 0;
  }
`;

const FieldLabel = styled(Label).attrs(() => ({
  type: 'faded',
  size: 'small',
}))<{ light: boolean }>`
  margin-bottom: 0.5rem;

  ${(props) =>
    props.light &&
    css`
      color: ${props.theme.colors.white};
    `}
`;

const FieldHint = styled(FieldLabel)`
  margin-top: -0.25rem;
`;

const FieldError = styled(Label).attrs(() => ({
  as: 'span',
  size: 'small',
}))`
  color: ${(props) => props.theme.colors.error};
`;

type Props = {
  label?: string | JSX.Element;
  errorMessage?: string;
  hint?: string;
  light?: boolean;
  style?: React.CSSProperties;
};

const FormField: React.FC<Props> = ({ label, children, errorMessage, hint, style, light = false }) => {
  return (
    <Field style={style}>
      {label && <FieldLabel light={light}>{label}</FieldLabel>}
      {hint && <FieldHint light={light}>{hint}</FieldHint>}
      {children}
      {errorMessage && <FieldError>{errorMessage}</FieldError>}
    </Field>
  );
};

export { Form, FormField };
