import { useEffect, useState } from 'react';
import useWindowSize from '../../utils/useWindowSize';
import { Button, Drawer, Modal } from '../../ui';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  allowShow: boolean;
  onUpdatePreference: (value: string) => void;
};

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export default function AndroidInstallProvider({ children, allowShow, onUpdatePreference }: Props) {
  const { isMobileDevice } = useWindowSize();
  const [isVisible, setIsVisible] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  let Element = isMobileDevice ? Drawer : Modal;

  useEffect(() => {
    // Initialize deferredPrompt for use later to show browser install prompt.
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can install the PWA
      setIsVisible(true);
    });
  }, []);

  useEffect(() => {
    window.addEventListener('appinstalled', () => {
      // Hide the app-provided install promotion
      hideInstallPromotion();
      // Clear the deferredPrompt so it can be garbage collected
      setDeferredPrompt(null);
      // Optionally, send analytics event to indicate successful install
    });
  }, []);

  const hideInstallPromotion = () => {
    // Hide modal or drawer
    setIsVisible(false);
  };

  const handleInstallPWA = async () => {
    // Hide the app provided install promotion
    hideInstallPromotion();
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    await deferredPrompt.userChoice;
    // Optionally, send analytics event with outcome of user choice
    // We've used the prompt, and can't use it again, throw it away
    setDeferredPrompt(null);
  };

  const handleHideForever = () => {
    hideInstallPromotion();
    onUpdatePreference('false');
  };

  if (!allowShow) return <>{children}</>;
  return (
    <>
      <Element isOpen={isVisible} onCancel={hideInstallPromotion} title="Wilt u onze app gebruiken?">
        <p>Met de app kun je eenvoudig vanaf jouw beginscherm reserveringen maken en inzien.</p>
        <ButtonRow>
          <Button.Primary onClick={handleInstallPWA}>Installeer de app</Button.Primary>
          <Button.Minimal onClick={handleHideForever}>Nee, bedankt</Button.Minimal>
        </ButtonRow>
      </Element>
      {children}
    </>
  );
}
