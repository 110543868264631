import React from 'react';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { findNearestNumberInArray, rubberBandIfOutOfBounds } from '../../utils/animation';

const Wrapper = styled(animated.div)`
  position: relative;
  z-index: 2;
`;

const Loader = styled(animated.div)<{ isLoading: boolean }>`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  &:after {
    content: '';
    width: 24px;
    height: 24px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-top: 2px solid rgba(0, 0, 0, 1);
    border-radius: 50%;
    opacity: 0.2;
    transition: opacity 0.3s;
  }

  ${(props) =>
    props.isLoading &&
    css`
      &:after {
        opacity: 0.5;
        animation: spin 1.5s cubic-bezier(0.46, 0.09, 0.54, 0.9) infinite;
      }
    `}
`;

const spring1 = {
  tension: 350,
  friction: 34,
};

type Props = {
  shouldPullToRefresh?: () => boolean;
  onRefresh: () => void;
  refreshDuration?: number;
};

enum RefreshStates {
  idle = 'idle',
  refreshing = 'refreshing',
}

const stops = [0, 60];

const PullToRefresh: React.FC<Props> = ({ children, onRefresh, refreshDuration = 1500 }) => {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  const [state, setState] = React.useState<RefreshStates>(RefreshStates.idle);

  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/inobounce/0.2.0/inobounce.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [{ y }, set] = useSpring<{ y: number }>(() => ({ y: 0 }));

  const bind = useDrag(
    ({ delta: [, dy], last, event }) => {
      if (!wrapperRef.current) return;
      if (state === RefreshStates.refreshing || window.scrollY > 0) return;

      if (last) {
        const point = findNearestNumberInArray(y.getValue(), stops);

        if (point === stops[1]) {
          setState(RefreshStates.refreshing);
          onRefresh();
          setTimeout(() => {
            set({ y: 0, immediate: false, config: spring1 });
            setState(RefreshStates.idle);
          }, refreshDuration);
        }

        set({ y: point, immediate: false, config: spring1 });

        return;
      }

      const newY = rubberBandIfOutOfBounds(stops[0], stops[1], y.getValue() + dy, 0.08);

      set({ y: newY, immediate: true });
    },
    {
      domTarget: wrapperRef.current,
      filterTaps: true,
    },
  );

  return (
    <>
      <Loader
        isLoading={state === RefreshStates.refreshing}
        style={{ transform: y.interpolate((v) => `translate3d(0, ${v - 60}px, 0)`) }}
      />
      <Wrapper
        {...bind()}
        style={{
          transform: y.interpolate((v) => `translate3d(0, ${v}px, 0)`),
        }}
        ref={wrapperRef}
        id="reservations"
      >
        {children}
      </Wrapper>
    </>
  );
};

export default PullToRefresh;
